export const validateTime = (time) => {
  const hours = time.parseable[0];
  const minutes = time.parseable[1];

  const hoursString = time.parseableString[0];
  const minutesString = time.parseableString[1];

  if (hours > 23) {
    return true;
  }

  if (hoursString.length <= 1) {
    return true;
  }

  if (minutes > 59) {
    return true;
  }

  if (minutesString.length <= 1) {
    return true;
  }
};
