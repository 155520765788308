import { ResponsiveBar } from '@nivo/bar';
import React from 'react';

export default function ({ data }: any) {
  return (
    <ResponsiveBar
      data={data}
      layout="horizontal"
      keys={['Custos']}
      indexBy="cost_center_number"
      margin={{ top: 50, right: 130, bottom: 105, left: 60 }}
      padding={0.3}
      colors="#FFA500"
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickValues: 3,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Custo',
        legendPosition: 'middle',
        legendOffset: 40,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 3,
        tickRotation: 0,
        legend: 'Centro de Custo',
        legendPosition: 'middle',
        legendOffset: -50,
        tickValues: 4,
      }}
      tooltip={({ id, value }) => (
        <strong>
          {id}: R$ {value.toFixed(2)}
        </strong>
      )}
      // @ts-ignore
      label={(d) => `R$ ${d.value.toFixed(2)}`}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor="white"
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  );
}
