import React, { useState, Fragment } from 'react';
import { Checkbox, Switch, message } from 'antd';
import { find, differenceBy } from 'lodash';
import { useMutation } from 'urql';
import gql from 'graphql-tag';
import styles from './CostCenterList.module.css';

const INSERT_CC_MUTATION = gql`
  mutation($cost_center_id: uuid!, $user_id: uuid!) {
    insert_user_cost_center(objects: { cost_center_id: $cost_center_id, user_id: $user_id }) {
      affected_rows
    }
  }
`;

const INSERT_ALL_CC_MUTATION = gql`
  mutation($objects: [user_cost_center_insert_input!]!) {
    insert_user_cost_center(objects: $objects) {
      affected_rows
    }
  }
`;

const DELETE_CC_MUTATION = gql`
  mutation($cost_center_id: uuid!, $user_id: uuid!) {
    delete_user_cost_center(where: { cost_center_id: { _eq: $cost_center_id }, user_id: { _eq: $user_id } }) {
      affected_rows
    }
  }
`;

const DELETE_ALL_CC_MUTATION = gql`
  mutation($user_id: uuid!) {
    delete_user_cost_center(where: { user_id: { _eq: $user_id } }) {
      affected_rows
    }
  }
`;

export default function CostCenterList({ userId, costCenters, selectedCostCenters }) {
  const [checkAll, setCheckAll] = useState(costCenters.length === selectedCostCenters.length);

  const [insert_result, add_cost_center] = useMutation(INSERT_CC_MUTATION);
  const [insert_all_result, add_all_cost_center] = useMutation(INSERT_ALL_CC_MUTATION);
  const [delete_result, delete_cost_center] = useMutation(DELETE_CC_MUTATION);
  const [delete_all_result, delete_all_cost_center] = useMutation(DELETE_ALL_CC_MUTATION);

  const insert_cost_center = (cost_center_id, user_id) => {
    add_cost_center({ cost_center_id, user_id })
      .then(() => message.success('Permissão de acesso a centros de custo atualizada'))
      .catch((e) => console.error(e));
  };

  const insert_all_cost_center = (objs) => {
    add_all_cost_center({ objects: objs })
      .then(() => message.success('Permissão de acesso a centros de custo atualizada'))
      .catch((e) => console.error(e));
  };

  const exclude_cost_center = (cost_center_id, user_id) => {
    delete_cost_center({ cost_center_id, user_id })
      .then(() => message.success('Permissão de acesso a centros de custo atualizada'))
      .catch((e) => console.error(e));
  };

  const exclude_all_cost_center = (user_id) => {
    delete_all_cost_center({ user_id })
      .then(() => message.success('Permissão de acesso a centros de custo atualizada'))
      .catch((e) => console.error(e));
  };

  const onCheckAllChange = (e) => {
    if (e) {
      const objs = differenceBy(
        costCenters.map((el) => ({ cost_center_id: el.id })),
        selectedCostCenters,
        'cost_center_id',
      ).map((el) => ({ cost_center_id: el.cost_center_id, user_id: userId }));
      insert_all_cost_center(objs);
      setCheckAll(e);
    } else {
      exclude_all_cost_center(userId);
      setCheckAll(e);
    }
  };

  if (insert_result.error || delete_result.error || insert_all_result.error || delete_all_result.error) {
    console.error(insert_result.error);
    return <div className={styles.listContainer}>Erro. Contate o suporte</div>;
  }

  return (
    <div className={styles.listContainer}>
      <h4 className={styles.listTitle}>Selecione os centros de custo que o usuário possui acesso</h4>
      <Switch onChange={onCheckAllChange} checked={checkAll}></Switch>
      <span className={styles.slideButton}>Acesso a todos centros de custo</span>

      <br />
      <br />

      {costCenters.map((c, idx) => {
        const checked = find(selectedCostCenters, (o) => c.id === o.cost_center_id);

        return (
          <Fragment key={idx}>
            <Checkbox
              onChange={(e) => {
                if (e.target.checked) {
                  insert_cost_center(c.id, userId);
                } else {
                  exclude_cost_center(c.id, userId);
                }
              }}
              disabled={!!checkAll}
              checked={checkAll ? true : !!checked}
            >{`${c.number} - ${c.description}`}</Checkbox>
            <br />
          </Fragment>
        );
      })}
    </div>
  );
}
