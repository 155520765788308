import React, { useState, useEffect, useMemo } from 'react';
import { Table } from 'antd';
import { FaExclamationTriangle, FaPlusCircle } from 'react-icons/fa';
import { differenceWith, isMatch } from 'lodash';

import styles from './SetWorkers.module.css';

export default function AddWorkers({ currentWorkers = [], importedWorkers = [], setAddWorkers }) {
  const comparableCurrentWorkers = useMemo(
    () =>
      currentWorkers.map((worker) => ({
        registration: worker?.registration,
        cost_center_number: worker?.cost_center?.number,
        name: worker?.name,
      })),
    [currentWorkers],
  );

  const filteredData = useMemo(() => differenceWith(importedWorkers, comparableCurrentWorkers, isMatch).map((e, i) => ({ ...e, number: i + 1 })), [
    importedWorkers,
    comparableCurrentWorkers,
  ]);

  useEffect(() => {
    setAddWorkers(filteredData);
  }, [setAddWorkers, filteredData]);

  const defaultKeys = filteredData.map((e, n) => n + 1);
  const [selectedRowKeys, setSelectedKeys] = useState(defaultKeys);

  const columns = [
    {
      title: 'Número',
      dataIndex: 'number',
      key: 'number',
      width: 100,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Matrícula',
      dataIndex: 'registration',
      key: 'registration',
      width: 120,
    },
    {
      title: 'Centro de Custo',
      dataIndex: 'cost_center_number',
      key: 'cost_center_number',
      render: (text, record) => `${record.cost_center_number} - ${record.cost_center_description}`,
    },
    {
      title: 'Endereço digitado (inicial)',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Endereço encontrado pelo sistema (final)',
      dataIndex: 'addressFound',
      key: 'addressFound',
      render: addressFound => addressFound?.description || '--',
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    let toAdd = [];
    selectedRowKeys.forEach((key) => {
      filteredData.forEach((worker) => {
        if (key === worker.number) {
          toAdd.push(worker);
        }
      });
    });
    setSelectedKeys(selectedRowKeys);
    setAddWorkers(toAdd);
  };

  const count = selectedRowKeys.length;
  const hasSelected = count > 0;

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <div className={styles.instructionContainer}>
        <p className={styles.instruction}>
          <FaExclamationTriangle className={styles.alertIcon} /> Atenção nessa etapa, revise os endereços que foram digitados e os endereços encontrados pelo sistema.
        </p>
        <p className={styles.instruction}>
          <FaPlusCircle className={styles.plusIcon} />
          Marque na tabela abaixo os colaboradores que você gostaria de <strong style={{ color: '#56A63A' }}>adicionar</strong> ao sistema
        </p>
      </div>
      <p className={styles.countItens}>{hasSelected ? `${count} colaborador(es) selecionado(s)` : ''}</p>
      <Table rowKey="number" pagination={{ pageSize: 200 }} rowSelection={rowSelection} columns={columns} dataSource={filteredData} scroll={{ y: 240 }} />
    </div>
  );
}
