import gql from 'graphql-tag';
import React from 'react';
import { useSubscription } from 'urql';


export const ReasonsContext = React.createContext();

const REASONS_SUBSCRIPTION = gql`
  subscription {
    reason(where: { deleted_at: { _is_null: true } }, order_by: { description: asc }) {
      id
      description
    }
  }
`;

const handleReasonsSub = (reasons = [], response) => {
  return response.reason;
};

const useReasons = () => {
  const [result] = useSubscription(
    {
      query: REASONS_SUBSCRIPTION,
    },
    handleReasonsSub,
  );

  return result.data || [];
};

export default function ReasonsProvider(props) {
  const reasons = useReasons();

  return (
    <ReasonsContext.Provider
      value={{
        reasons,
      }}
    >
      {props.children}
    </ReasonsContext.Provider>
  );
}
