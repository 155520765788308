import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { validateTime } from "../NewSolicitation/helpers/validate-date-time";

const SOLICITATIONS_QUERY = gql`
  query($startAt: date, $endAt: date, $time: String, $passenger: String, $userId: uuid) {
    solicitation(
      where: { state: { _eq: "pendent" }, time: { _eq: $time }, passenger_name: { _eq: $passenger }, deleted_at: { _is_null: true }, solicitant_id: { _eq: $userId }, trip_date: { _gte: $startAt, _lte: $endAt } }
      order_by: { trip_date: asc, time: asc }
    ) {
      id
      number
      passenger_name
      entity_name
      trip_date
      time
      phone
      state
      created_at
      observation
      recurrent_solicitation_id
      cost_center {
        id
        description
        number
      }
      destination
      destination_locality {
        id
        name
      }
      origin
      origin_locality {
        id
        name
      }
      passenger {
        name
        id
        registration
      }
      reason {
        id
        description
      }
      user {
        passenger {
          name
          registration
          id
        }
      }
    }
  }
`;

const handleSolicitations = (solicitation = [], response) => {
  return response.solicitation;
};

export default function (rangeDate, time, passenger, cooperative, user_id) {
  if(!time || validateTime({ time })) time = null

  const userId = !cooperative ? user_id : null;

  const [result, reexecuteQuery] = useQuery(
    {
      query: SOLICITATIONS_QUERY,
      variables: {
        startAt: rangeDate[0],
        endAt: rangeDate[1],
        time,
        passenger,
        userId,
      },
      requestPolicy: 'cache-and-network',
    },
    handleSolicitations,
  );

  const refresh = () => {
    // Refetch the query and skip the cache
    reexecuteQuery({ requestPolicy: 'network-only' });
  };

  return { ...result, refresh };
}
