import React, { useMemo } from 'react'
import { StyledBackdrop, StyledStrong, StyledText } from "./styles";
import moment from "moment";

export const DifferenceCostSolicitationsExporting = ({ isExporting, estimate }) => {
    const text = useMemo(() => estimate === 0 ? 'calculando…' : moment().add(estimate, 'milliseconds').format('HH:mm'), [estimate])

    return (
        <StyledBackdrop show={isExporting} className={'backdrop'}>
            <StyledStrong>Exportando diferença de custos das solicitações…</StyledStrong>
            <StyledText>Estimativa para fim da exportação do Excel: {text}</StyledText>
        </StyledBackdrop>
    )
}
