import { difference, indexOf, random } from 'lodash';
import { simpleClient } from '../../../../helpers/auth';

const QUERY_COOPERATIVES = `
  query MyQuery {
    cooperatives(where: {deleted_at: {_is_null: true}}) {
      id
    }
  }
`;

const QUERY_LAST_TRIPS = `
    query ($id: uuid, $limit: Int) {
        trip(where: {solicitations: {destination_locality_id: {_eq: $id}}}, limit: $limit, order_by: {created_at: desc}) {
            id
            cooperative_id 
        }
    }
  
`;

const fetchLastTripsOfLocality = async (id, cooperativesNumber) => {
  const variables = { id, limit: cooperativesNumber - 1 };
  const { trip } = await simpleClient.request(QUERY_LAST_TRIPS, variables);

  return trip;
};

const fetchCooperatives = async () => {
  const { cooperatives } = await simpleClient.request(QUERY_COOPERATIVES);

  return cooperatives.map(({ id }) => id);
};

export const distribute = async (trips, dispatchError) => {
  try {
    const tripsWithCooperative = [];
    const cooperatives = await fetchCooperatives();
    const cooperativesNumber = cooperatives.length;

    if (!cooperatives.length) {
      dispatchError('Erro ao distribuir viagem', 'Não foi possível retornar as cooperativas');
      throw new Error('Cooperativas não encontradas');
    }

    for (let t of trips) {
      const destination_locality_id = t.solicitations[0].destination_locality.id;

      // RETORNA ÚLTIMAS VIAGENS DESSE DESTINO COM BASE NO NÚMERO DE COOPERATIVAS EXISTENTES
      const lastTrips = await fetchLastTripsOfLocality(destination_locality_id, cooperativesNumber);

      // RETORNA ÚLTIMAS VIAGENS DESSE DESTINO QUE JÁ FORAM ALOCADAS A UMA COOPERATIVA
      const lastTripsInMemory = tripsWithCooperative.filter((trip) => {
        const destinations = trip.solicitations.map((s) => s.destination_locality.id);

        return indexOf(destinations, destination_locality_id) === -1 ? false : true;
      });

      // RETORNA AS ÚLTIMAS COOPERATIVAS DESSAS VIAGENS
      const lastCooperatives = [...lastTrips, ...lastTripsInMemory].map((t) => t.cooperative_id || t.cooperative?.id);

      // RETORNA AS COOPERATIVAS POSSÍVEIS QUE AINDA NÃO TIVERAM VIAGEM PARA O DESTINO SETADO
      const possibleCooperatives = difference(cooperatives, lastCooperatives);

      // RETORNA INDEX RANDOMICO DE ACORDO COM AS COOPERATIVAS POSSÍVEIS
      const randomicIndex = random(0, possibleCooperatives.length - 1);

      if (!possibleCooperatives.length) {
        const newRandomic = random(0, cooperativesNumber - 1);
        tripsWithCooperative.push({ ...t, cooperative_id: cooperatives[newRandomic] });
      } else {
        tripsWithCooperative.push({ ...t, cooperative_id: possibleCooperatives[randomicIndex] });
      }
    }

    console.log('distribute', tripsWithCooperative);

    return tripsWithCooperative;
  } catch (e) {
    return e;
  }
};
