import { useQuery } from 'urql';
import gql from 'graphql-tag';
import { UserContext } from '../../../contexts/UserContext';
import { useContext } from 'react';

const PASSENGERS_SUBSCRIPTION = gql`
  query($cc: [uuid!]!) {
    passenger(order_by: { name: asc }, where: { cost_center_id: { _in: $cc }, deleted_at: { _is_null: true } }) {
      id
      name
      registration
      cost_center_id
      address {
        id
        description
      }
      cost_center {
        id
        number
        description
      }
      locality {
        id
        name
      }
    }
  }
`;

const handlePassengersSub = (passenger = [], response) => {
  return response.passenger;
};

export default function useCostCentersPassengers() {
  const { user } = useContext(UserContext);

  const user_cost_center_list = user.user?.user_cost_centers.map(({ cost_center }) => cost_center.id) || [];
  const cost_center_list = [...user_cost_center_list, user?.cost_center_id].filter(Boolean);

  const [result] = useQuery(
    {
      query: PASSENGERS_SUBSCRIPTION,
      variables: { cc: cost_center_list },
      requestPolicy: 'cache-and-network',
    },
    handlePassengersSub,
  );

  return { passengers: result.data?.passenger ?? [] };
}
