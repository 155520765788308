import React from 'react';
import Card from './Card';
import styles from './CooperativeItem.module.css';

export default function CooperativeItem({ cooperative }) {
  return (
    <div className={styles.container}>
      <Card cooperative={cooperative} />
    </div>
  );
}
