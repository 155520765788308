import { Layout } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { isTokenExpired, refreshToken } from '../../helpers/auth';
import styles from './css/Template.module.css';
import Header from './Header';
import Sidebar from './Sidebar';

export default function Template(props) {
  const isLoggedOut = isTokenExpired();
  let history = useHistory();
  const pathname = history.location.pathname;
  const refreshTokenIntervalRef = useRef(null);
  const FIVE_MINUTES = 300000;

  useEffect(() => {
    const clearRefreshTokenInterval = () => {
      if (refreshTokenIntervalRef.current) {
        clearInterval(refreshTokenIntervalRef.current);

        refreshTokenIntervalRef.current = null;
      }
    };

    if (!isLoggedOut)
      refreshTokenIntervalRef.current = setInterval(async () => {
        await refreshToken()
      }, FIVE_MINUTES);

    return () => {
      clearRefreshTokenInterval();
    };
  }, [isLoggedOut]);

  useEffect(() => {
    if (isLoggedOut) {
      history.push('/login');
    }
  }, [isLoggedOut, history]);

  if (pathname === '/login') return props.children;

  if (isLoggedOut) return props.children;

  return (
    <Layout className={styles.container}>
      <Header />
      <Layout>
        <Sidebar />
        <Layout>
          <Layout.Content className={styles.content}>{props.children}</Layout.Content>
          <Layout.Footer style={{ textAlign: 'center' }}>
            <a href="https://infleet.com.br" target="_blank" rel="noopener noreferrer" className={styles.footer}>
              © 2020 Infleet | Tecnologia para frotas
            </a>
          </Layout.Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}
