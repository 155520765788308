import { Input } from 'antd';
import React, { useMemo } from 'react';
import styles from './ExplainWhyNoDefaultWay.module.css';
import { uniqueId } from "../../../Solicitations/Router/helpers/ids";

const { TextArea } = Input;

export const ExplainWhyNoDefaultWay = ({ name, defaultValue, setFieldValue }) => {
    const id = useMemo(() => uniqueId(), [])

    const onChange = ({ target: { value } }) => {
        setFieldValue(name, value?.trim() ?? '')
    }

    return (
        <div className={styles.field}>
            <label htmlFor={id} className={styles.label}>Explique a não seleção de origem e destino padrão ("Casa" e "Trabalho") <span aria-label={'Campo obrigatório'}>*</span></label>
            <TextArea className={styles.textarea}
                      id={id}
                      placeholder={'Coloque aqui a explicação da não seleção de origem e destino padrão ("Casa" e "Trabalho")'}
                      rows={3}
                      maxLength={1000}
                      required={true}
                      onChange={onChange}
                      defaultValue={defaultValue} />
        </div>
    );
}
