import React, { useState, useEffect, useMemo } from 'react';
import { Table } from 'antd';
import { FaInfoCircle } from 'react-icons/fa';
import { intersectionBy } from 'lodash';

import styles from './SetWorkers.module.css';

export default function UpdateAddresses({ currentWorkers = [], importedWorkers = [], setAddresses }) {
  const filteredData = useMemo(() => intersectionBy(importedWorkers, currentWorkers, 'registration').map((e, i) => ({ ...e, number: i + 1 })), [
    importedWorkers,
    currentWorkers,
  ]);

  useEffect(() => {
    setAddresses(filteredData);
  }, [setAddresses, filteredData]);

  const defaultKeys = filteredData.map((e, n) => n + 1);
  const [selectedRowKeys, setSelectedKeys] = useState(defaultKeys);

  const columns = [
    {
      title: 'Número',
      dataIndex: 'number',
      key: 'number',
      width: 100,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Matrícula',
      dataIndex: 'registration',
      key: 'registration',
    },
    {
      title: 'Endereço',
      dataIndex: 'address',
      key: 'address',
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    let toAdd = [];
    selectedRowKeys.forEach((key) => {
      filteredData.forEach((worker) => {
        if (key === worker.number) {
          toAdd.push(worker);
        }
      });
    });
    setSelectedKeys(selectedRowKeys);
    setAddresses(toAdd);
  };

  const count = selectedRowKeys.length;
  const hasSelected = count > 0;

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <div className={styles.instructionContainer}>
        <p className={styles.instruction}>
          <FaInfoCircle className={styles.infoIcon} />
          Marque na tabela abaixo os colaboradores que você gostaria de <strong style={{ color: '#909090' }}>atualizar</strong> ao sistema
        </p>
      </div>
      <p className={styles.countItens}>{hasSelected ? `${count} colaborador(es) selecionado(s)` : ''}</p>
      <Table rowKey="number" rowSelection={rowSelection} columns={columns} dataSource={filteredData} scroll={{ y: 240 }} />
    </div>
  );
}
