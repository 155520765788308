import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useSubscription } from 'urql';

export const CostCenterContext = React.createContext();

const COST_CENTERS_SUBSCRIPTION = gql`
  subscription($description: String) {
    cost_center(
      where: { _or: [{ description: { _ilike: $description } }, { number: { _ilike: $description } }], _and: { deleted_at: { _is_null: true } } }
      order_by: { number: asc }
    ) {
      id
      number
      description
    }
  }
`;

const handleCostCentersSub = (cost_center = [], response) => {
  return response.cost_center;
};

export const useCostCenters = () => {
  const [description] = useState(null);
  const [result] = useSubscription(
    {
      query: COST_CENTERS_SUBSCRIPTION,
      variables: { description: description },
    },
    handleCostCentersSub,
  );

  return result.data ?? [];
};

export default function CostCentersProvider(props) {
  const costCenters = useCostCenters();

  return (
    <CostCenterContext.Provider
      value={{
        costCenters,
      }}
    >
      {props.children}
    </CostCenterContext.Provider>
  );
}
