import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './EditableInput.module.css';

export default function EditableInput({ original, editIcon, value, handleUpdate, mask, isNumber }) {
  const [isEditing, setEditing] = useState(false);
  const [field, setField] = useState(value);

  useEffect(() => {
    setField(value);
  }, [value]);

  return (
    <div>
      {!isEditing ? (
        <div
          onClick={() => {
            setEditing(true);
          }}
          className={styles.container}
        >
          {original}
          {editIcon}
        </div>
      ) : (
        <div>
          {
            <div className={styles.editableContainer}>
              <Input
                type={isNumber ? 'number' : 'text'}
                value={field}
                onChange={(e) => {
                  const maskedValue = mask ? mask(e.target.value) : e.target.value;
                  setField(maskedValue);
                }}
              />
              <div style={{ marginTop: '0.5rem' }}>
                <button
                  onClick={() => {
                    setField(value);
                    setEditing(false);
                  }}
                  className={styles.editableCancel}
                >
                  Cancelar
                </button>
                <button
                  onClick={() => {
                    handleUpdate(field);
                    setEditing(false);
                  }}
                  className={styles.editableSave}
                >
                  Salvar
                </button>
              </div>
            </div>
          }
        </div>
      )}
    </div>
  );
}
