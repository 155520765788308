import { LockOutlined } from '@ant-design/icons';
import { Input, Modal, notification } from 'antd';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useMutation } from 'urql';
import * as Yup from 'yup';
import { simpleClient } from '../../helpers/auth';
import InputCNPJ from './InputCNPJ';
import styles from './NewCooperative.module.css';

const INSERT_USER_COOPERATIVE_MUTATION = gql`
  mutation($email: String!, $password: String!) {
    auth_register(email: $email, password: $password) {
      affected_rows
    }
  }
`;

const INSERT_COOPERATIVE_MUTATION = gql`
  mutation($objects: [cooperatives_insert_input!]!) {
    insert_cooperatives(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

const INSERT_EXTRA_COSTS_MUTATION = gql`
  mutation($objects: [cooperatives_extra_cost_insert_input!]!) {
    insert_cooperatives_extra_cost(objects: $objects) {
      affected_rows
    }
  }
`;

const QUERY_USER = `
  query($email: citext!) {
    user(where: { email: { _eq: $email } }) {
      id
    }
  }
`;

export default function NewCooperative({ visible, setVisible, extraCostsType }) {
  const [, insert_cooperative_mutation] = useMutation(INSERT_COOPERATIVE_MUTATION);
  const [, insert_cooperative_user_mutation] = useMutation(INSERT_USER_COOPERATIVE_MUTATION);
  const [, insert_extra_cost_mutation] = useMutation(INSERT_EXTRA_COSTS_MUTATION);

  const add_cooperative_user = (values) => {
    insert_cooperative_user_mutation({ email: values.email, password: values.password })
      .then(async (e) => {
        const userVariables = { email: values.email };
        const user = await simpleClient.request(QUERY_USER, userVariables);

        const user_id = user.user[0].id;

        const cooperative = { CNPJ: values.CNPJ, name: values.name, user_id };

        add_cooperative([cooperative]);
      })
      .catch((e) => console.log(e));
  };

  const add_cooperative = (objs) => {
    insert_cooperative_mutation({ objects: objs })
      .then((e) => {
        if (e.data) {
          notification['success']({
            message: 'Cooperativa adicionada',
            description: 'A nova cooperativa foi adicionada com sucesso',
          });

          const cooperative_id = e.data.insert_cooperatives.returning[0].id;

          const defaultExtraCosts = extraCostsType.map((c) => ({ cooperative_id, extra_cost_id: c.id }));

          insert_extra_cost_mutation({ objects: defaultExtraCosts })
            .then((e) => {})
            .catch((e) => console.error(e));
        } else {
          notification['error']({
            message: 'Erro ao criar cooperativa',
            description: 'A nova cooperativa não foi adicionada. Tente novamente ou contate o suporte',
          });
        }

        setVisible(false);
      })
      .catch((e) => console.error(e));
  };

  const onCancel = () => {
    setVisible(false);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    email: Yup.string().required('Campo obrigatório'),
    CNPJ: Yup.string().required('Campo obrigatório'),
    password: Yup.string().required('Campo obrigatório'),
  });

  const initialValues = {
    name: '',
    CNPJ: '',
    password: '',
  };

  return (
    <Modal title="Criar uma cooperativa" destroyOnClose={true} visible={visible} onCancel={onCancel} footer={null}>
      <div style={{ textAlign: 'center' }}>
        <h3 className={styles.question}>Você gostaria de criar uma cooperativa?</h3>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={(values, actions) => {
            add_cooperative_user(values);
            actions.setSubmitting(false);
          }}
        >
          {({ values, errors, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.containerForm}>
                <InputCNPJ />

                <div className={styles.inputContainer}>
                  <label className={styles.label}>Nome</label>
                  <Input className={styles.input} name="name" onChange={handleChange} />
                  {errors.name && <p className={styles.error}>Campo inválido</p>}
                </div>

                <div className={styles.inputContainer}>
                  <label className={styles.label}>Usuário</label>
                  <Input className={styles.input} name="email" onChange={handleChange} />
                  {errors.name && <p className={styles.error}>Campo inválido</p>}
                </div>

                <div className={styles.inputContainer}>
                  <label className={styles.label}>Senha</label>
                  <Input className={styles.input} name="password" onChange={handleChange} prefix={<LockOutlined />} />
                  {errors.name && <p className={styles.error}>Campo inválido</p>}
                </div>

                <div className={styles.buttonContainer}>
                  <button type="submit" className={styles.createButton}>
                    Sim, desejo criar
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
