import React, { useMemo } from 'react';
import { FaDollarSign } from 'react-icons/fa';
import styled from 'styled-components';
import { useDashboardContext } from '../../../contexts/DashboardContext';
import Card from '../Card';

const IconTitle = styled(FaDollarSign)`
  color: gray;
  margin-right: 0.1rem;
`;

interface Props {}

const TotalCostCard = (props: Props) => {
  const { formatTrips } = useDashboardContext();

  const totalCost = useMemo(() => {
    return formatTrips.reduce((sum: number, trip: any) => sum + trip.cost, 0);
  }, [formatTrips]);

  return <Card icon={<IconTitle />} title="Custo total" value={`R$ ${totalCost.toFixed(2)}`} />;
};

export default TotalCostCard;
