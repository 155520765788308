import React from 'react';
import { useFormikContext } from 'formik';
import { Input } from 'antd';
import styles from './InputCNPJ.module.css';

export const CNPJMask = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})(\d+?$)/, '$1');
};

export default function InputCNPJ() {
  const { values, setFieldValue, errors } = useFormikContext();

  const handleChange = (e) => {
    const value = CNPJMask(e.target.value);
    setFieldValue('CNPJ', value);
  };

  return (
    <div className={styles.inputContainer}>
      <label className={styles.label}>CNPJ</label>
      <Input className={styles.input} onChange={handleChange} value={values.CNPJ} />
      {errors.CNPJ && <p className={styles.error}>Campo inválido</p>}
    </div>
  );
}
