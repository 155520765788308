import { Col, message, Popconfirm, Row, Table, Tooltip } from 'antd';
import gql from 'graphql-tag';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FaArrowLeft, FaQuestionCircle, FaTimes, FaUpload } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'urql';
import { LocalitiesContext } from '../../contexts/LocalitiesContext';
import { RoutesContext } from '../../contexts/RoutesContext';
import AntecedenceTime from './AntecedenceTime';
import styles from './Configuration.module.css';
import ImportRoutes from './ImportRoutes';
import PassengerLimit from './PassengerLimit';
import ReasonList from './ReasonsList';
import TimeList from './TimesList';
import { UserContext } from "../../contexts/UserContext";

const DELETE_ROUTES_MUTATION = gql`
  mutation($id: uuid!) {
    delete_route(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const DELETE_LOCALITY_MUTATION = gql`
  mutation($id: uuid!) {
    update_locality(where: { id: { _eq: $id } }, _set: { deleted_at: "now()" }) {
      affected_rows
    }
    update_passenger(where: { locality_id: { _eq: $id } }, _set: { locality_id: null }) {
      affected_rows
    }
  }
`;

export default function Configuration() {
  const history = useHistory();
  const { routes } = useContext(RoutesContext);
  const { localities } = useContext(LocalitiesContext);

  const [routesModal, setRoutesModal] = useState(false);
  const [, delete_routes] = useMutation(DELETE_ROUTES_MUTATION);
  const [, delete_locality] = useMutation(DELETE_LOCALITY_MUTATION);
  const { user } = useContext(UserContext);
  const isAdmin = useMemo(() => user?.user?.user_permission?.is_admin, [user]);

  const remove_routes = id => {
    delete_routes({ id })
      .then(e => {
        message['success']('Rota removida com sucesso');
        console.log(e);
      })
      .catch(e => console.error(e));
  };

  const remove_locality = id => {
    delete_locality({ id })
      .then(e => {
        message['success']('Localidade removida com sucesso');
        console.log(e);
      })
      .catch(e => console.error(e));
  };

  const columnsRoutes = [
    {
      title: 'Número',
      dataIndex: 'number',
      sorter: {
        compare: (a, b) => Number(a.number) > Number(b.number),
      },
      width: '70px',
    },
    {
      title: 'Origem',
      dataIndex: 'origin',
      sorter: {
        compare: (a, b) => {
          if (a.origin > b.origin) {
            return 1;
          }

          if (a.origin < b.origin) {
            return -1;
          }
        },
      },
    },
    {
      title: 'Destino',
      dataIndex: 'destination',
      sorter: {
        compare: (a, b) => {
          if (a.destination > b.destination) {
            return 1;
          }

          if (a.destination < b.destination) {
            return -1;
          }
        },
      },
    },
    {
      title: 'Excluir',
      render: (text, record) => {
        return (
          <Popconfirm title="Você tem certeza que deseja excluir essa rota?" okText="Sim" cancelText="Não" onConfirm={() => remove_routes(record.id)}>
            <Tooltip placement="top" title="Excluir rota">
              <FaTimes style={{ cursor: 'pointer' }} className={styles.icon} />
            </Tooltip>
          </Popconfirm>
        );
      },
      width: '60px',
    },
  ];

  const columnsLocalities = [
    {
      title: 'Localidade',
      dataIndex: 'name',

      sorter: {
        compare: (a, b) => {
          if (a.destination > b.destination) {
            return 1;
          }

          if (a.destination < b.destination) {
            return -1;
          }
        },
      },
    },
    {
      title: 'Excluir',
      render: (text, record) => {
        return (
          <Popconfirm title="Você tem certeza que deseja excluir essa localidade?" okText="Sim" cancelText="Não" onConfirm={() => remove_locality(record.id)}>
            <Tooltip placement="top" title="Excluir localidade">
              <FaTimes style={{ cursor: 'pointer' }} className={styles.icon} />
            </Tooltip>
          </Popconfirm>
        );
      },
      width: '60px',
    },
  ];

  useEffect(() => {
    if(typeof isAdmin === 'boolean' && !isAdmin) history.goBack()
  }, [isAdmin, history])

  if(!isAdmin) return null

  return (
    <div>
      <div className={styles.pageHeadingContainer}>
        <h2 className={styles.pageHeading}>
          <FaArrowLeft onClick={() => history.goBack()} className={styles.arrowIcon} />
          <Tooltip
            title="Nesta aba, encontram-se as configurações gerais do sistema. Importe as Rotas homologadas, configure horários, motivos de viagem e defina os limites
para as solicitações"
          >
            <span>Configurações</span> <FaQuestionCircle className={styles.instruction} />
          </Tooltip>
        </h2>
      </div>
      <div>
        <Row gutter={16}>
          <Col span={24}>
            <div className={styles.container}>
              <div className={styles.tableTitleContainer}>
                <h3 className={styles.tableTitle}>
                  Rotas <span className={styles.tableCounter}>{routes.length} itens</span>
                </h3>
                <Tooltip title="Importar rotas">
                  <FaUpload className={styles.icon} onClick={() => setRoutesModal(true)} />
                </Tooltip>
              </div>
              <Table rowKey="id" columns={columnsRoutes} dataSource={routes} size="small" pagination={false} scroll={{ y: 380 }} />
            </div>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <PassengerLimit />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <AntecedenceTime />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <ReasonList />
              </Col>
              <Col span={12}>
                <TimeList />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <div className={styles.container}>
              <div className={styles.tableTitleContainer}>
                <h3 className={styles.tableTitle}>
                  Localidades <span className={styles.tableCounter}>{localities.length} itens</span>
                </h3>
              </div>
              <Table rowKey="id" columns={columnsLocalities} dataSource={localities} size="small" pagination={false} scroll={{ y: 380 }} />
            </div>
          </Col>
        </Row>
      </div>
      {routesModal && <ImportRoutes visible={routesModal} setVisible={setRoutesModal} routes={routes} />}{' '}
    </div>
  );
}
