import styled from 'styled-components'
import { Button } from "antd";

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: var(--default-space);
`

export const StyledText = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
`

export const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: var(--small-space);
    width: 100%;
`
