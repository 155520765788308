import { UploadOutlined } from '@ant-design/icons';
import { message, Modal, notification, Spin, Steps } from 'antd';
import gql from 'graphql-tag';
import { find } from 'lodash';
import React, { useContext, useState } from 'react';
import { useMutation } from 'urql';
import XLSX from 'xlsx';
import { LocalitiesContext } from '../../../contexts/LocalitiesContext';
import { getFinalAddress } from '../geoCode.js';
import AddWorkers from './AddWorkers';
import delay from './delay';
import styles from './ImportModal.module.css';

const Step = Steps.Step;

const INSERT_PASSENGERS_MUTATION = gql`
  mutation($objects: [passenger_insert_input!]!) {
    insert_passenger(
      objects: $objects
      on_conflict: { constraint: passenger_registration_key, update_columns: [name, cost_center_id, locality_id, deleted_at, is_solicitant, address_id] }
    ) {
      affected_rows
    }
  }
`;

export default function ImportUsersModal({ passengers, costCenters, visible, setVisible, setImporting }) {
  const [current, setCurrent] = useState(0);
  const [importedWorkers, setImportWorkers] = useState([]);
  const [addWorkers, setAddWorkers] = useState([]);
  const [imported, setImported] = useState(undefined);
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(false);

  const [, add_passengers] = useMutation(INSERT_PASSENGERS_MUTATION);

  const { localities } = useContext(LocalitiesContext);

  const insert_passengers = async (objs) => {
    add_passengers({ objects: objs })
      .then((e) => {
        console.log(e);
      })
      .catch((e) => console.error(e));
  };

  const onImportExcel = (event) => {
    const { files } = event.target;
    if (files.length === 1) {
      processFileContent(files[0]);
    }
  };

  const extractWorkers = async (item) => {
    const worker = {
      registration: item['B'].toString(),
      cost_center_number: item['C'].toString(),
      cost_center_description: item['D'],
      name: item['E'],
      address: `${item['F']} ${item['G']} ${item['H']}`,
    };

    worker.addressFound = await getFinalAddress(worker.address) || ''

    return worker;
  };

  const processFileContent = async (file) => {
    const fileReader = new FileReader();
    fileReader.onload = async (event) => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        const first_sheet_name = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[first_sheet_name];
        const data = XLSX.utils.sheet_to_json(worksheet, {
          header: 'A',
          raw: true,
          defval: null,
        });
        data.shift();

        const workersPromise = data.map(extractWorkers);
        const workers = await Promise.all(workersPromise);

        setFile(file.name);
        setImportWorkers(workers);
        message.success('Arquivo carregado com sucesso!');
      } catch (e) {
        console.log(e);
        message.error('Erro com o formato da planilha');
      }
    };
    fileReader.readAsBinaryString(file);
  };

  const next = () => {
    setCurrent((c) => c + 1);
  };

  const prev = () => {
    setCurrent((c) => c - 1);
  };

  const createPassengers = async () => {
    setLoading(true);
    try {
      for (let [i, worker] of addWorkers.entries()) {
        await delay(5);

        const address = worker.addressFound;
        let locality_id = find(localities, (o) => o.name.toLowerCase().trim() === address?.city?.toLowerCase())?.id;
        let cost_center_id = find(costCenters, (o) => o.number === worker.cost_center_number)?.id;

        if (!cost_center_id) {
          throw new Error(`O centro de custo do colaborador ${worker.name} não está devidamente criado`);
        }

        if (address) {
          const passenger = {
            name: worker.name,
            registration: worker.registration,
            cost_center_id,
            locality_id,
            address: { data: address },
          };
          await insert_passengers([passenger]);
        } else {
          const passenger = {
            name: worker.name,
            registration: worker.registration,
            cost_center_id,
            locality_id,
          };
          await insert_passengers([passenger]);
        }

        setImported(i + 1);
      }
    } catch (error) {
      notification['error']({
        message: 'Erro ao criar passageiro proveniente da planilha',
        description: error.message,
      });
    }
  };

  const saveImport = async () => {
    setImporting(true);
    await createPassengers();
    setImporting(false);
    setLoading(false);
    setVisible(false);
    notification['success']({
      message: 'Importação realizada com sucesso',
      description: 'Não esqueça de conferir os passageiros sem localidade definida!',
    });
  };

  const steps = [
    {
      title: 'Importar planilha',
      content: (
        <>
          <input id="file" name="file" onChange={onImportExcel} className={styles.fileUploader} type="file" accept=".xlsx, .xls" />
          <label htmlFor="file">
            <UploadOutlined className={styles.uploadIcon} />
            Escolha um arquivo
          </label>
          <p>{file}</p>
        </>
      ),
    },
    {
      title: 'Adicionar novos colaboradores',
      content: <AddWorkers currentWorkers={passengers} importedWorkers={importedWorkers} setAddWorkers={setAddWorkers} />,
    },
  ];

  return (
    <Modal
      destroyOnClose={true}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      footer={null}
      title="Importar colaboradores"
      width={1200}
    >
      <div className={styles.modalContainer}>
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title}></Step>
          ))}
        </Steps>
        <div className={styles.modalContent}>
          {loading ? (
            <div>
              <h1>Importando...</h1>
              <p>{imported ? `${imported}/${addWorkers.length}` : 'Pendente'}</p>
            </div>
          ) : (
            steps[current].content
          )}
        </div>
        <div className={styles.modalActions}>
          {current > 0 && (
            <button className={styles.prevButton} onClick={() => prev()}>
              Anterior
            </button>
          )}
          {current < steps.length - 1 && (
            <button className={styles.nextButton} onClick={() => next()}>
              Próximo
            </button>
          )}
          {current === steps.length - 1 && (
            <button onClick={saveImport} className={styles.nextButton}>
              {loading ? <Spin /> : 'Salvar'}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}
