import { uniqBy } from 'lodash';

export const hasDifferentOrigins = (trip) => {
  const solicitations = trip.solicitations.map((s) => ({ origin_locality_id: s.origin_locality.id }));

  const uniqueOriginSolicitations = uniqBy(solicitations, 'origin_locality_id');

  if (uniqueOriginSolicitations.length > 1) {
    return true;
  } else {
    return false;
  }
};
