import { ClockCircleOutlined } from '@ant-design/icons';
import { Dropdown, Input, Menu, Select } from 'antd';
import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { sanitizeTime } from './sanitizeTime';
import styles from './TimePicker.module.css';
import { useTimePicker } from './useTimePicker';

const { Option } = Select;

export default function TimePicker(props) {
  const { style } = props;

  const [time, setTime] = useState(props.value);

  const times = useTimePicker();
  const userContext = useContext(UserContext);

  const user_permission = userContext?.user?.user?.user_permission;

  const defaultTimes = times.map(({ hour }) => hour).map(sanitizeTime);

  useEffect(() => {
    setTime(props.value);
  }, [props.value]);

  const onChangeSelect = (time) => {
    const { name, setFieldValue } = props;

    setTime(time);
    setFieldValue(name, time);
  };

  const onChangeInput = (e) => {
    const value = e.target.value;
    const formatValue = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1:$2')
      .replace(/(:\d{2})\d+?$/, '$1');

    const { name, setFieldValue } = props;

    setTime(formatValue);
    setFieldValue(name, formatValue);
  };

  const onChoose = (time) => {
    const { name, setFieldValue } = props;

    setTime(time);
    setFieldValue(name, time);
  };

  const menu = (
    <Menu>
      {defaultTimes.map((t) => (
        <Menu.Item key={t.formatted} onClick={() => onChoose(t.formatted)}>
          {t.formatted}
        </Menu.Item>
      ))}
    </Menu>
  );

  if (user_permission?.free_time) {
    return (
      <>
        <label className={styles.label}>{props.label}</label>
        <div className={styles.inputContainer}>
          <Dropdown style={style} overlay={menu} placement="bottomRight" overlayClassName={styles.timeOverlay}>
            <Input placeholder={'Coloque o horário'} value={time} onChange={onChangeInput} prefix={<ClockCircleOutlined style={{ color: '#909090' }} />} />
          </Dropdown>
        </div>
      </>
    );
  }

  return (
    <>
      <label className={styles.label}>{props.label}</label>
      <div className={styles.inputContainer}>
        <Select style={style} placeholder="Selecione o horário" onChange={onChangeSelect}>
          {defaultTimes.map((t) => (
            <Option key={t.formatted} value={t.formatted}>
              {t.formatted}
            </Option>
          ))}
        </Select>
      </div>
    </>
  );
}
