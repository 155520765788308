export const routerize = (solicitations) => {
  console.log(solicitations);
  const response = fetch(process.env.REACT_APP_ALGORITHM_URL, {
    method: 'POST',
    body: JSON.stringify(solicitations),
    cache: 'no-cache',
    headers: new Headers({
      'content-type': 'application/json',
    }),
  })
    .then(function (response) {
      console.log(response)
      return response.json();
    })
    .then(function (data) {
      return data.trip;
    })
    .catch((e) => console.error(e));

  return response;
};

export const metersToKilometers = meters => meters / 1000;
