import { pipe, mergeMap, fromPromise, fromValue, map } from 'wonka';

export default function fetchOptionsExchange(fn) {
  return ({ forward }) => (ops$) => {
    return pipe(
      ops$,
      mergeMap((operation) => {
        const result = fn(operation.context.fetchOptions);
        return pipe(
          (typeof result.then === 'function' ? fromPromise(result) : fromValue(result)),
          map((fetchOptions) => ({
            ...operation,
            context: { ...operation.context, fetchOptions },
          })),
        );
      }),
      forward,
    );
  };
}