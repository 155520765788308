export const getDistanceBetweenDestinations = async (trip) => {
  const solicitations = trip.solicitations;

  const origins = solicitations.map((s) => s.origin.latLng);

  const destinations = solicitations.map((s) => s.destination.latLng);

  const bodyOrigin = {
    origins,
    profile: 'carFast',
    regionDefinition: {
      type: 'world',
    },
    matrixAttributes: ['distances'],
  };

  const bodyDestination = {
    origins: destinations,
    profile: 'carFast',
    regionDefinition: {
      type: 'world',
    },
    matrixAttributes: ['distances'],
  };

  const responseOrigin = await fetch(`${process.env.REACT_APP_DISTANCE_URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyOrigin),
  });

  const responseDestination = await fetch(`${process.env.REACT_APP_DISTANCE_URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDestination),
  });

  const dataOrigin = await responseOrigin.json();
  const dataDestination = await responseDestination.json();
  const distancesOrigin = dataOrigin.matrix.distances;
  const distancesDestination = dataDestination.matrix.distances;
  const matrixOrigins = separateInMatrixRoutes(distancesOrigin, origins.length)
  const matrixDestinations = separateInMatrixRoutes(distancesDestination, destinations.length)

  return [selectShortestKmRoute(matrixOrigins), selectShortestKmRoute(matrixDestinations)];
};

const separateInMatrixRoutes = (distances, size) => {
  const matrix = [];

  for (let index = 0; index < distances.length; index += size)
    matrix.push(distances.slice(index, index + size));

  return matrix;
}

export const selectShortestKmRoute = matrix =>
  Math.min(...matrix.flatMap(route => route.reduce((accumulator, current) => accumulator + current, 0)))

const getDistances = async (origins) => {
  const url = process.env.REACT_APP_DISTANCE_URL

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ origins, profile: 'carFast', regionDefinition: { type: 'world' }, matrixAttributes: ['distances'] }),
  });

  const data = await response.json();
  const { distances } = data.matrix;

  return separateInMatrixRoutes(distances, origins.length)
}

export const getAllOriginsAndDestinationsDistances = async (trip) => {
  const { solicitations } = trip;
  const origins = solicitations.map(({ origin }) => origin.latLng);
  const destinations = solicitations.map(({ destination }) => destination.latLng);
  const originsDistances = await getDistances(origins);
  const destinationsDistances = await getDistances(destinations);

  return { originsDistances, destinationsDistances }
}
