import React, { useContext } from 'react';
import styles from './Locality.module.css';
import { useFormikContext } from 'formik';
import { LocalitiesContext } from '../../../../contexts/LocalitiesContext';

export default function LocalityPopover({ nameLocality }) {
  const { localities } = useContext(LocalitiesContext);

  const { setFieldValue } = useFormikContext();

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Escolha uma localidade</h3>
      {localities.map((l) => (
        <p
          key={l.id}
          className={styles.item}
          onClick={() => {
            setFieldValue(nameLocality, l.id);
          }}
        >
          {l.name}
        </p>
      ))}
    </div>
  );
}
