import { UploadOutlined } from '@ant-design/icons';
import { message, Modal, notification } from 'antd';
import gql from 'graphql-tag';
import { flatten, uniq } from 'lodash';
import React, { useContext, useState } from 'react';
import { useMutation } from 'urql';
import XLSX from 'xlsx';
import { CooperativesContext } from '../../contexts/CooperativesContext';
import styles from './Configuration.module.css';

const INSERT_ROUTES_MUTATION = gql`
  mutation($objects: [route_insert_input!]!) {
    insert_route(objects: $objects, on_conflict: { constraint: route_number_key, update_columns: [origin, destination, deleted_at] }) {
      affected_rows
    }
  }
`;

const INSERT_LOCALITY_MUTATION = gql`
  mutation($objects: [locality_insert_input!]!) {
    insert_locality(objects: $objects, on_conflict: { constraint: locality_name_key, update_columns: [name, deleted_at] }) {
      affected_rows
    }
  }
`;

export default function ImportRoutesModal({ routes, visible, setVisible }) {
  const { cooperatives } = useContext(CooperativesContext);
  const [importedRoutes, setImportRoutes] = useState([]);
  const [file, setFile] = useState('');

  const [, add_routes] = useMutation(INSERT_ROUTES_MUTATION);
  const [, add_localities] = useMutation(INSERT_LOCALITY_MUTATION);

  const insert_routes = (objs) => {
    cooperatives.forEach((cooperative, idx, arr) => {
      const toImport = objs.map((r) => ({
        ...r,
        cooperatives_route_costs: {
          data: [{ cooperative_id: cooperative.id }],
          on_conflict: { constraint: 'cooperatives_route_cost_cooperative_id_route_id_key', update_columns: ['route_id'] },
        },
      }));

      add_routes({ objects: toImport })
        .then((e) => {
          console.log(e)
          if (e.data && idx === arr.length - 1) {
            notification['success']({
              message: 'Rotas importadas com sucesso',
              description: 'Confira o cadastro de cooperativas para inserir seus custos específicos de rota',
            });
          } else if (idx === arr.length - 1) {
            notification['error']({
              message: 'Erro ao importar rotas',
              description: 'Confira se existe rotas duplicadas',
            });
          }
        })
        .catch((e) => console.error(e));
    });
  };

  const insert_localities = (objs) => {
    add_localities({ objects: objs })
      .then((e) => console.log(e))
      .catch((e) => console.error(e));
  };

  const extractRoutes = async (item) => {
    return {
      number: item['A'].toString().trim(),
      origin: item['B'].toString().trim(),
      destination: item['C'].toString().trim(),
    };
  };

  const processFileContent = async (file) => {
    const fileReader = new FileReader();
    fileReader.onload = async (event) => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        const first_sheet_name = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[first_sheet_name];
        const data = XLSX.utils.sheet_to_json(worksheet, {
          header: 'A',
          raw: true,
          defval: null,
        });

        if (data[0]['A'] !== 'NÚMERO') throw new Error();

        data.shift();

        const routesPromise = data.map(extractRoutes);
        let routes = await Promise.all(routesPromise);

        setFile(file.name);
        setImportRoutes(routes);
        message.success('Arquivo carregado com sucesso!');
      } catch (e) {
        message.error('Erro com o formato da planilha');
      }
    };
    fileReader.readAsBinaryString(file);
  };

  const onImportExcel = (event) => {
    const { files } = event.target;
    if (files.length === 1) {
      processFileContent(files[0]);
    }
  };

  const saveImport = () => {
    insert_routes(importedRoutes);

    const localities = flatten(importedRoutes.map((r) => [r.origin, r.destination]));
    const uniqueLocalities = uniq(localities).map((name) => ({ name }));

    insert_localities(uniqueLocalities);

    setVisible(false);
  };

  return (
    <Modal
      destroyOnClose={true}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      footer={null}
      title="Importar rotas"
      width={800}
    >
      <div className={styles.modalContainer}>
        <div className={styles.modalContent}>
          <h3 className={styles.modalInstruction}>
            Faça o upload da planilha de <strong>Rotas</strong> mais atualizada
          </h3>
          <input id="file" name="file" onChange={onImportExcel} className={styles.fileUploader} type="file" accept=".xlsx, .xls" />
          <label htmlFor="file">
            <UploadOutlined className={styles.uploadIcon} />
            Escolha um arquivo
          </label>
          <p>{file}</p>
        </div>
        <div className={styles.modalActions}>
          <button disabled={!file} onClick={saveImport} className={styles.saveButton}>
            Salvar importação
          </button>
        </div>
      </div>
    </Modal>
  );
}
