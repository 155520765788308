import { Modal } from 'antd';
import React from 'react';
import { FaFileExcel } from "react-icons/fa";
import ReactExport from "react-export-excel";
import { StyledButton, StyledContent, StyledText } from "./styles";

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

export const DifferenceCostSolicitationsDownloadModal = ({ isOpened, close, fileName, solicitations }) => (
    <Modal title={'Baixar diferença de custos de solicitações'}
           width={400}
           footer={null}
           destroyOnClose={true}
           visible={isOpened}
           onCancel={close}>
        <StyledContent>
            <StyledText>Foi preparado o Excel com as diferenças de custos das solicitações com a base atual, os números foram arredondados, faça o download para obter o arquivo.</StyledText>

            <ExcelFile fileExtension={'xlsx'}
                       filename={fileName}
                       element={<StyledButton htmlType={'button'} type={'primary'}><FaFileExcel aria-hidden={true}/> Baixar o Excel</StyledButton>}>
                <ExcelSheet data={solicitations} name={'Diferença de custos'}>
                    <ExcelColumn label={'Número da solicitação'} value={'solicitationNumber'} />
                    <ExcelColumn label={'Solicitante'} value={'solicitant'} />
                    <ExcelColumn label={'Passageiro ou material'} value={'entityName'} />
                    <ExcelColumn label={'Centro de custo'} value={'costCenter'} />
                    <ExcelColumn label={'Número da viagem'} value={'tripNumber'} />
                    <ExcelColumn label={'Data e hora da viagem'} value={'tripDateTime'} />
                    <ExcelColumn label={'Origem'} value={'origin'} />
                    <ExcelColumn label={'Localidade de origem'} value={'originLocality'} />
                    <ExcelColumn label={'Destino'} value={'destination'} />
                    <ExcelColumn label={'Localidade de destino'} value={'destinationLocality'} />
                    <ExcelColumn label={'Cooperativa'} value={'cooperative'} />
                    <ExcelColumn label={'Custo base (R$)'} value={'baseCost'} />
                    <ExcelColumn label={'Custo base atual (R$)'} value={'currentBaseCost'} />
                    <ExcelColumn label={'Extremo (R$)'} value={'extremeCost'} />
                    <ExcelColumn label={'Extremo atual (R$)'} value={'currentExtremeCost'} />
                    <ExcelColumn label={'Via (R$)'} value={'viaCost'} />
                    <ExcelColumn label={'Via atual (R$)'} value={'currentViaCost'} />
                    <ExcelColumn label={'Estacionamento (R$)'} value={'parkingCost'} />
                    <ExcelColumn label={'Hora à disposição (R$)'} value={'waitCost'} />
                    <ExcelColumn label={'Total (R$)'} value={'totalCost'} />
                    <ExcelColumn label={'Total atual (R$)'} value={'currentTotalCost'} />
                    <ExcelColumn label={'Diferença entre total e total atual (R$)'} value={'differenceTotalCost'} />
                    <ExcelColumn label={'Status'} value={'status'} />
                </ExcelSheet>
            </ExcelFile>
        </StyledContent>
    </Modal>
)
