import React, { useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import styled from 'styled-components';
import ModalFilters from './ModalFilters';

const TextContainer = styled.div`
  margin-left: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Text = styled.p`
  font-size: 0.75rem;
  color: #007cc2;
`;

const Icon = styled(FaFilter)`
  font-size: 0.75rem;
  color: #007cc2;
  margin-right: 0.5rem;
`;

interface Props {}

const ApplyFilters = (props: Props) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <TextContainer onClick={() => setVisible(true)}>
        <Icon />
        <Text>Aplicar filtros</Text>
      </TextContainer>
      <ModalFilters visible={visible} setVisible={setVisible} />
    </>
  );
};

export default ApplyFilters;
