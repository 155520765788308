import React, { createContext, useContext, useState } from 'react';

export const SolicitationsContext = createContext({});

export const SolicitationsProvider = ({ children }) => {
  const [editSolicitation, setEditSolicitation] = useState(null);
  const [showEditSolicitationModal, setShowEditSolicitationModal] = useState(false);

  return (
    <SolicitationsContext.Provider
      value={{
        editSolicitation,
        setEditSolicitation,
        showEditSolicitationModal,
        setShowEditSolicitationModal,
      }}
    >
      {children}
    </SolicitationsContext.Provider>
  );
}

export const useSolicitationsContext = () => useContext(SolicitationsContext)
