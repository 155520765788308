import gql from 'graphql-tag';
import { useSubscription } from 'urql';

const EXTRA_COST_SUBSCRIPTION = gql`
  subscription {
    extra_cost_type(order_by: { name: asc }) {
      id
      name
    }
  }
`;

const handleExtraCostsSub = (cooperative = [], response) => {
  return response.extra_cost_type;
};

export const useExtraCostsType = () => {
  const [result] = useSubscription(
    {
      query: EXTRA_COST_SUBSCRIPTION,
    },
    handleExtraCostsSub,
  );

  return result.data || [];
};
