import styled from "styled-components";
import { DatePicker, Table } from "antd";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--default-space);
`

export const StyledNumberColumn = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--small-space);
`

export const StyledFilters = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: var(--default-space);
`

export const StyledActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: var(--default-space);
`

export const StyledFieldControl = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || 'fit-content'};
  
  > div{
    margin: 0;
    width: 100%;
  }
`

export const StyledRangePicker = styled(DatePicker.RangePicker)`
    width: fit-content;
`

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  column-gap: var(--small-space);
  color: var(--light-blue-color);
  font-size: 14px;
  cursor: pointer;
  margin: 0;
  background-color: transparent;
  border: none;
  transition: opacity var(--transition-time);
  padding: var(--small-space) var(--half-space);
  width: fit-content;
  
  &:hover, &:focus {
    opacity: .9;
  }

  &:active {
    opacity: .8;
  }
`

export const StyledTable = styled(Table)`
  ul{
    margin-bottom: 0 !important;
  }
`
