import { Checkbox, Col, notification, Row } from 'antd';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfigurationContext } from '../../../../contexts/ConfigurationContext';
import { UserContext } from '../../../../contexts/UserContext';
import { validateBadSolicitation } from '../../helpers/validate-bad-solicitation';
import { validateAntecedence, validateReturn, validateTime } from '../../helpers/validate-date-time';
import { validateLastSolicitations } from '../../helpers/validate-last-solicitations';
import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete';
import CostCenter from '../CostCenter/CostCenter';
import DatePicker from '../DatePicker/DatePicker';
import Observation from '../Observation/Observation';
import Phone from '../Phone/Phone';
import Reason from '../Reason/Reason';
import RecurrentSolicitation from '../RecurrentSolicitation/RecurrentSolicitation';
import TimePicker from '../TimePicker/TimePicker';
import styles from './SolicitationForm.module.css';
import { ExplainWhyNoDefaultWay } from "../ExplainWhyNoDefaultWay/ExplainWhyNoDefaultWay";
import { CONTINENTAL_ADDRESS } from "../../constants";

export default function SolicitationForm({ selectedPassenger, lastSolicitations, setVisible, withReturn, setWithReturn, isMaterial }) {
  const { configuration } = useContext(ConfigurationContext);
  const { user } = useContext(UserContext);
  const is_admin = user?.user?.user_permission?.is_admin;
  const history = useHistory();
  const formik = useFormikContext();
  const { values } = formik;

  const showExplainWhyNoDefaultWay = useMemo(() => {
    const passengerAddress = selectedPassenger?.address?.description
    const { origin, destination } = values

    return !!passengerAddress &&
           !!CONTINENTAL_ADDRESS &&
           !!origin &&
           !!destination &&
           (
               (passengerAddress !== origin && passengerAddress !== destination) ||
               (CONTINENTAL_ADDRESS !== origin && CONTINENTAL_ADDRESS !== destination)
           );
  }, [values, selectedPassenger])

  const isInvalidExplainWhyNoDefaultWay = useMemo(() => showExplainWhyNoDefaultWay && !values.explain_why_no_default_way, [showExplainWhyNoDefaultWay, values])

  const antecedenceConfig = useMemo(() => {
    return configuration.filter((config) => config.name === 'antecedence_time');
  }, [configuration])[0]?.value;

  const checkFulfillment = () => {
    const values = formik.values;

    let {
      passenger_name,
      entity_name: entityName,
      cost_center_id,
      type,
      origin,
      destination,
      trip_date,
      time,
      reason_id,
      origin_locality_id,
      destination_locality_id,
      phone,
      observation,
    } = values;

    entityName = entityName?.trim()
    observation = observation?.trim()

    return (
      (!!passenger_name || !!entityName) &&
      !!cost_center_id &&
      !!type &&
      !!origin &&
      !!destination &&
      !!trip_date &&
      !!time &&
      !!reason_id &&
      !!origin_locality_id &&
      !!destination_locality_id &&
      !!phone &&
      !isInvalidExplainWhyNoDefaultWay &&
      (isMaterial ? !!observation : true)
    );
  };

  const toggleReturn = (e) => {
    setWithReturn(e.target.checked);
    formik.setFieldValue('return_date', null)
    formik.setFieldValue('return_time', null)
  };

  const errorNotification = (error) => {
    notification['error']({
      message: 'Erro ao realizar a solicitação',
      description: error,
    });

    return;
  };

  const commitSolicitation = async () => {
    const values = formik.values;

    if(isInvalidExplainWhyNoDefaultWay){
      errorNotification('É necessário colocar a explicação do porquê origem e destino padrão não foi selecionada ("Casa" e "Trabalho")');
      return;
    }

    if (values.origin === values.destination) {
      errorNotification('Solicitação com origem e destino iguais');
      return;
    }

    if (await validateBadSolicitation(values)) {
      errorNotification('Solicitação com origem e destino não permitidas');
      return;
    }

    if (validateLastSolicitations(values, lastSolicitations)) {
      errorNotification('Solicitação na mesma data e horário já existente');
      return;
    }

    if (validateTime(values)) {
      errorNotification('Horário inválido');
      return;
    }

    if(withReturn && (!values.return_date || validateTime({ time: values.return_time ?? '' }))){
      errorNotification('Data e hora válidas são necessárias para o retorno');

      return;
    }

    if (validateReturn(values) && withReturn) {
      errorNotification('Horário da solicitação de retorno inválido');
      return;
    }

    // if (validatePast(values)) {
    //   errorNotification('Data não deve ser no passado');
    //   return;
    // }

    if (validateAntecedence(values, antecedenceConfig, is_admin)) {
      errorNotification(`Horário deve ter ao menos ${antecedenceConfig}h de antecedência`);
      return;
    }

    setVisible(true);
  };

  const buttonDisable = checkFulfillment();

  useEffect(() => {
    formik.setFieldValue('explain_why_no_default_way', '')
  }, [showExplainWhyNoDefaultWay])

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.headingTitle}>Formulário de solicitação</h2>
      <p className={styles.subHeading}>As solicitações devem ser realizadas de acordo com o horário de antecedência</p>
      <Row gutter={16}>
        <Col span={8}>
          <AddressAutocomplete
            name="origin"
            nameLocality="origin_locality_id"
            initialLocalityId={formik.initialValues.origin_locality_id}
            initialValue={formik.initialValues.origin}
            label="Origem"
            placeholder="Endereço de origem"
            address={selectedPassenger?.address}
            passengerLocality={selectedPassenger?.locality}
            alertMessage={'Veja a lista toda de localidades e selecione a mais próxima de sua origem'}
          />
        </Col>
        <Col span={8}>
          <AddressAutocomplete
            name="destination"
            nameLocality="destination_locality_id"
            initialLocalityId={formik.initialValues.destination_locality_id}
            initialValue={formik.initialValues.destination}
            label="Destino"
            placeholder="Endereço de destino"
            address={selectedPassenger?.address}
            passengerLocality={selectedPassenger?.locality}
            alertMessage={'Veja a lista toda de localidades e selecione a mais próxima de seu destino'}
          />
        </Col>
        <Col span={4}>
          <DatePicker name="trip_date" label="Data da viagem" placeholder="Selecione uma data" setFieldValue={formik.setFieldValue} />
          <Checkbox checked={withReturn} onChange={toggleReturn} className={styles.returnCheckbox}>
            Viagem com retorno
          </Checkbox>
          {withReturn && (
            <DatePicker
              name="return_date"
              label="Data de retorno"
              placeholder="Selecione uma data"
              value={formik.values.return_date ? moment(formik.values.return_date) : null}
              setFieldValue={formik.setFieldValue}
            />
          )}
        </Col>
        <Col span={4}>
          <TimePicker style={{ width: '100%' }} name="time" label="Horário *" setFieldValue={formik.setFieldValue} value={formik.values.time} />
          {withReturn && (
            <div className={styles.hourReturnContainer}>
              <TimePicker
                style={{ width: '100%' }}
                name="return_time"
                label="Horário de retorno *"
                setFieldValue={formik.setFieldValue}
                value={formik.values.returnTime}
              />
            </div>
          )}
        </Col>
      </Row>
      {
        showExplainWhyNoDefaultWay ?
        <Row gutter={16}>
          <Col span={24}>
            <ExplainWhyNoDefaultWay name="explain_why_no_default_way" setFieldValue={formik.setFieldValue} defaultValue={formik.initialValues.explain_why_no_default_way} />
          </Col>
        </Row> :
        null
      }
      <br />
      <br />
      <Row gutter={16}>
        <Col span={8}>
          <Reason name="reason_id" label="Motivo" setFieldValue={formik.setFieldValue} value={formik.values.reason_id} />
        </Col>
        <Col span={8}>
          <CostCenter name="cost_center_id" label="Centro de custo" setFieldValue={formik.setFieldValue} />
        </Col>
        <Col span={4}>
          <Phone name="phone" label="Telefone de contato *" setFieldValue={formik.setFieldValue} value={formik.values.phone} />
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col span={24}>
          <Observation name="observation"
                       label={`Observações${isMaterial ? ': coloque peso, medidas, quantidade e mais características do material *' : ''}`}
                       placeholder={isMaterial ? 'Preencha com peso, medidas, quantidade e mais características do material para maior detalhamento' : ''}
                       setFieldValue={formik.setFieldValue}
                       value={formik.values.observation}
                       required={isMaterial} />
        </Col>
      </Row>
      <RecurrentSolicitation isMaterial={isMaterial} />
      <br />
      <br />
      <Row type="flex" justify="end">
        <button onClick={() => history.push('/')} className={styles.cancelButton}>
          Cancelar
        </button>
        <button disabled={!buttonDisable} onClick={() => commitSolicitation()} className={styles.soliciteButton}>
          Solicitar
        </button>
      </Row>
    </div>
  );
}
