import { SearchOutlined } from '@ant-design/icons';
import { Input, Radio, Select } from 'antd';
import { useFormikContext } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { RadioContext } from '../../NewSolicitation';
import styles from './PassengerInput.module.css';

const { Option } = Select;

export default function PassengerInput(props) {
  const { passengers = [], selectedPassenger, selectPassenger, setRadio, setHasEntityName, isMaterial, setIsMaterial, lastCreatedSolicitationId, setWithReturn } = props;
  const { user } = useContext(UserContext);
  const radio = useContext(RadioContext);
  const formik = useFormikContext();
  const { values } = formik
  const [inputValue, setInputValue] = useState(null);
  const allow_visitant_permission = user?.user?.user_permission?.allow_visitant;

  const label = useMemo(() => {
    if(isMaterial) return 'Material'

    return 'Passageiro'
  }, [isMaterial])

  const placeholder = useMemo(() => {
    if(isMaterial) return 'Digite o nome do material'

    return 'Digite o nome do passageiro'
  }, [isMaterial])

  const onRadio = (e) => {
    setInputValue(null)
    formik.resetForm();
    formik.setFieldValue('type', e.target.value);
    setWithReturn(false)
    selectPassenger(null);
    setRadio(e.target.value);
  };

  const onSelect = (value, option) => {
    const { key } = option;
    const { passenger } = option.props;

    selectPassenger(passenger);
    formik.setFieldValue('passenger_name', passenger.name);
    formik.setFieldValue('cost_center_id', passenger.cost_center_id);
    formik.setFieldValue('passenger_id', key);
  };

  const onChange = (e) => {
    const value = e?.target?.value ?? '';

    setInputValue(value)

    if(isMaterial) formik.setFieldValue('entity_name', value);
    else{
      selectPassenger({ name: value });
      formik.setFieldValue('passenger_name', value);
    }

    formik.setFieldValue('cost_center_id', user.cost_center_id);
    formik.setFieldValue('passenger_id', null);
  };

  const filteredPassengers = passengers?.filter((p) => p.registration !== user.registration);

  useEffect(() => {
    setHasEntityName(!!values.entity_name)
  }, [values])

  useEffect(() => {
    setIsMaterial(radio === 'material')
  }, [radio])

  useEffect(() => {
    if(lastCreatedSolicitationId) setInputValue(null)
  }, [lastCreatedSolicitationId])

  return (
    <div className={styles.inputContainer}>
      {
        radio === 'worker' ?
        (
          <Select
              style={{ width: '40%' }}
              size="large"
              placeholder="Digite o nome do passageiro"
              value={selectedPassenger?.name}
              suffixIcon={<SearchOutlined type="search" />}
              showSearch
              onChange={onSelect}
          >
            {filteredPassengers.map((p) => (
                <Option key={p.id} value={`${p.name} ${p.registration}`} passenger={p}>
                  {p.name} - <span style={{ fontWeight: 'bold' }}>Matrícula:</span> {p.registration}
                </Option>
            ))}
          </Select>
        ) :
        <Input style={{ width: '40%' }} size="large" value={inputValue} aria-label={label} placeholder={placeholder} onChange={onChange} required={true} />
      }

      <Radio.Group className={styles.radio} value={radio} onChange={onRadio} size="large">
        <Radio.Button value="worker">Colaborador</Radio.Button>
        {allow_visitant_permission && <Radio.Button value="visitant">Visitante</Radio.Button>}
        <Radio.Button value="material">Material</Radio.Button>
      </Radio.Group>
    </div>
  );
}
