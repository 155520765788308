import { LoadingOutlined } from '@ant-design/icons';
import { Col, Modal, Row, Tooltip } from 'antd';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import {
  FaArrowRight,
  FaArrowsAltH,
  FaBox,
  FaMapMarker,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaRegCalendarAlt,
  FaUser
} from 'react-icons/fa';
import { ReasonsContext } from '../../../../contexts/ReasonsContext';
import styles from './ConfirmationModal.module.css';

export default function ConfirmationModal({ visible, setVisible, withReturn, values, handleSubmit, loadingSubmit }) {
  const trip_date = moment(values.trip_date).format('DD/MM/YY');
  const return_date = moment(values.return_date).format('DD/MM/YY');
  const { reasons } = useContext(ReasonsContext);

  const reason = reasons.find((r) => values.reason_id === r.id);
  const entityName = useMemo(() => values.entity_name, [values])
  const passengerName = useMemo(() => values.passenger_name, [values])

  return (
    <Modal
      visible={visible}
      destroyOnClose={true}
      title="Confirme a solicitação"
      okText={loadingSubmit ? <LoadingOutlined style={{ fontSize: 20, color: 'white' }} spin /> : 'Confirmar'}
      cancelText="Cancelar"
      onCancel={() => setVisible(false)}
      onOk={handleSubmit}
    >
      <p className={styles.instruction}>Confira as informações da sua solicitação abaixo</p>
      {!!passengerName && <p className={styles.title}>
        <FaUser className={styles.mediumIcon}/>
        {passengerName}
      </p>}
      {!!entityName && <p className={styles.title}>
        <FaBox className={styles.mediumIcon}/>
        {entityName}
      </p>}
      <p className={styles.phone}>
        <FaPhoneAlt className={styles.mediumIcon}/>
        {values.phone}
      </p>
      <p>
        <FaRegCalendarAlt className={styles.mediumIcon} />
        {withReturn ? `${trip_date} às ${values.time} com retorno ${return_date} às ${values.return_time}` : `${trip_date} às ${values.time}`}
      </p>
      <br />
      <Row gutter={32} type="flex" justify="start" align="middle">
        <Col>
          <label className={styles.label}>
            <FaMapMarkerAlt className={styles.smallIcon} />
            Origem
          </label>
          <Tooltip title={values.origin}>
            <p className={styles.address}>{values.origin}</p>
          </Tooltip>
        </Col>
        <Col>{withReturn ? <FaArrowsAltH className={styles.arrowIcon} /> : <FaArrowRight className={styles.arrowIcon} />}</Col>
        <Col>
          <label className={styles.label}>
            <FaMapMarker className={styles.smallIcon} />
            Destino
          </label>
          <Tooltip title={values.destination}>
            <p className={styles.address}>{values.destination}</p>
          </Tooltip>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <label className={styles.label}>Motivo</label>
          <p>{reason?.description}</p>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <label className={styles.label}>Observações</label>
          <p>{values.observation || 'Sem observações'}</p>
        </Col>
      </Row>
    </Modal>
  );
}
