import { Button, Input, Modal, notification } from 'antd';
import gql from 'graphql-tag';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'urql';
import { DEFAULT_MAXLENGTH, REQUIRED_FIELD_MESSAGE } from "../../../constants";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import { Formik } from 'formik';
import styles from './CostCentersSaveModal.module.css';
import { onlyNumbers } from "../../../helpers/validations";

const NUMBER_MAXLENGTH = 10

const INSERT_COST_CENTERS_MUTATION = gql`
  mutation($objects: [cost_center_insert_input!]!) {
    insert_cost_center(objects: $objects, on_conflict: { constraint: cost_center_number_key, update_columns: [description, deleted_at] }) {
      affected_rows
    }
  }
`;

const UPDATE_COST_CENTER_MUTATION = gql`
  mutation($id: uuid!, $object: cost_center_set_input!) {
    update_cost_center(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
    }
  }
`;

const Content = ({ isOpened, data, close }) => {
  const [, insertCostCenters] = useMutation(INSERT_COST_CENTERS_MUTATION);
  const [, updateCostCenter] = useMutation(UPDATE_COST_CENTER_MUTATION);
  const { values: { number, name }, setValues, setFieldValue, isValid, dirty, ...formik } = useFormikContext()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const id = useMemo(() => data?.id, [data])
  const isCreate = useMemo(() => typeof id !== 'string', [id])
  const isInvalid = useMemo(() => isSubmitting || !isValid || !dirty, [isSubmitting, isValid, dirty])
  const prefixText = useMemo(() => isCreate ? 'Criar' : 'Atualizar', [isCreate])

  const onSubmit = async event => {
    event.preventDefault()

    if(isInvalid) return

    setIsSubmitting(true)

    const text = prefixText.toLowerCase()

    try{
      const costCenter = { number, description: name }
      const { data } = await (isCreate ? insertCostCenters({ objects: [costCenter] }) : updateCostCenter({ id, object: costCenter }))
      const affectedRows = data[isCreate ? 'insert_cost_center' : 'update_cost_center'].affected_rows

      if(typeof affectedRows !== 'number') throw new Error('Request error')

      close()
      notification.success({ message: `Sucesso ao ${text} centro de custo.` });
    } catch(error){
      console.error(error)
      notification.error({ message: `Ocorreu um problema ao ${text} centro de custo, reveja o formulário e tente novamente, por favor.` });
    } finally{
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if(isCreate) return
    if(id) setValues({ number: data?.number, name: data?.name })
  }, [isCreate, id, data])

  useEffect(() => {
    if(!isOpened) formik.resetForm()
  }, [isOpened])

  return (
      <Modal
          title={`${isCreate ? 'Criar' : 'Editar'} centro de custo`}
          width={800}
          footer={null}
          destroyOnClose={true}
          visible={isOpened}
          onCancel={close}
      >
        <form onSubmit={onSubmit} className={'form'}>
          <div className={'fields-container'}>
            <div className={'field'}>
              <label htmlFor={'fieldNumber'}>Número do centro de custo:</label>

              <Input
                  id={'fieldNumber'}
                  type={'number'}
                  placeholder={'Coloque o número do centro de custo'}
                  size={'large'}
                  name={'number'}
                  max={9999999999}
                  value={number}
                  maxLength={NUMBER_MAXLENGTH}
                  required={true}
                  onChange={({ currentTarget: { value } }) => setFieldValue('number', onlyNumbers(value))}
              />

              <span className={'hint'}>{REQUIRED_FIELD_MESSAGE}</span>
            </div>

            <div className={'field'}>
              <label htmlFor={'fieldName'}>Nome do centro de custo:</label>

              <Input
                  id={'fieldName'}
                  type={'text'}
                  placeholder={'Coloque o nome do centro de custo'}
                  size={'large'}
                  name={'name'}
                  value={name}
                  maxLength={DEFAULT_MAXLENGTH}
                  required={true}
                  onChange={({ currentTarget: { value } }) => setFieldValue('name', value)}
              />

              <span className={'hint'}>{REQUIRED_FIELD_MESSAGE}</span>
            </div>
          </div>

          <div className={styles.actions}>
            <Button htmlType={'button'} onClick={close}>Fechar</Button>
            <Button htmlType={'submit'} type={'primary'} loading={isSubmitting} disabled={isInvalid}>{prefixText} centro de custo</Button>
          </div>
        </form>
      </Modal>
  )
}

export const CostCentersSaveModal = props => {
  const schema = Yup.object().shape({
    number: Yup.string().max(NUMBER_MAXLENGTH).required(),
    name: Yup.string().max(DEFAULT_MAXLENGTH).required(),
  })

  return (
      <Formik initialValues={{ number: '', name: '' }}
              validationSchema={schema}
              onSubmit={null}>
        <Content {...props} />
      </Formik>
  );
}
