import { isIllegalSolicitation } from './isIllegalSolicitation';

export const hasIllegalSolicitations = (solicitations) => {
  const illegalSolicitations = solicitations.filter((solicitation) => {
    return isIllegalSolicitation(solicitation);
  });

  if (illegalSolicitations.length !== 0) {
    return true;
  } else {
    return false;
  }
};
