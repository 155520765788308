import gql from 'graphql-tag';
import { useSubscription } from 'urql';

const COOPERATIVE_SUBSCRIPTION = gql`
  subscription($id: uuid!) {
    cooperatives_by_pk(id: $id) {
      CNPJ
      id
      name
    }
  }
`;

const handleCooperativeSub = (cooperative = [], response) => {
  return response.cooperatives_by_pk;
};

export const useCooperative = (id) => {
  const [result] = useSubscription(
    {
      query: COOPERATIVE_SUBSCRIPTION,
      variables: { id },
    },
    handleCooperativeSub,
  );

  return result.data || [];
};
