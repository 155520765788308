import { uniqBy } from 'lodash';

export const hasDifferentDestinations = (trip) => {
  const solicitations = trip.solicitations.map((s) => ({ destination_locality_id: s.destination_locality.id }));
  const uniqueDestinationSolicitations = uniqBy(solicitations, 'destination_locality_id');

  if (uniqueDestinationSolicitations.length > 1) {
    return true;
  } else {
    return false;
  }
};
