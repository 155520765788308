import gql from 'graphql-tag';
import { useSubscription } from 'urql';

const EXTRA_COST_SUBSCRIPTION = gql`
  subscription($id: uuid!) {
    cooperatives_extra_cost(where: { cooperative_id: { _eq: $id } }, order_by: { extra_cost_type: { name: asc } }) {
      extra_cost_type {
        id
        name
        description
        type
      }
      id
      value
    }
  }
`;

const handleExtraCostsSub = (cooperative = [], response) => {
  return response.cooperatives_extra_cost;
};

export const useExtraCosts = (id) => {
  const [result] = useSubscription(
    {
      query: EXTRA_COST_SUBSCRIPTION,
      variables: { id },
    },
    handleExtraCostsSub,
  );

  return result.data || [];
};
