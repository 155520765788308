import { groupBy, orderBy } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useDashboardContext } from '../../contexts/DashboardContext';
import { Subtitle } from '../Subtitle/Subtitle';
import { Title } from '../Title/Title';
import CostCenterRankingCanvas from './CostCenterRankingCanvas';

const Container = styled.div`
  padding: 1rem;
  border-radius: 8px;
  height: 57vh;
  margin-right: 0.5rem;
`;

interface Props {}

const CostCenterRanking = (props: Props) => {
  const { formatTrips } = useDashboardContext();

  const groupedCostCenters = groupBy(formatTrips, 'cost_center_number');

  const data = Object.entries(groupedCostCenters).map((group) => ({
    cost_center_number: group[0],
    Custos: group[1].map((datum: any) => datum.cost).reduce((sum: number, cost: number) => sum + cost, 0),
  }));

  // get top 5 cost centers placing firstly the biggest one
  const orderedData = orderBy(data, ['Custos'], ['desc']).slice(0, 5).reverse();

  return (
    <Container>
      <Title>Custo x Centro de Custo</Title>
      <Subtitle>Confira o top 5 de Centros de Custo em gastos para o período</Subtitle>
      <CostCenterRankingCanvas data={orderedData} />
    </Container>
  );
};

export default CostCenterRanking;
