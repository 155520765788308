import React from 'react';
import styles from './LocalitiesPopover.module.css';

export default function LocalitiesPopover({ localities, setLocality }) {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Escolha uma localidade</h3>
      {localities.map((l) => (
        <p key={l.id} className={styles.item} onClick={() => setLocality(l)}>
          {l.name}
        </p>
      ))}
    </div>
  );
}
