import { Avatar } from 'antd';
import React, { useContext, useMemo, useState } from 'react';
import { FaClock, FaPen } from 'react-icons/fa';
import { ConfigurationContext } from '../../contexts/ConfigurationContext';
import AntecedenceTimeModal from './AntecedenceTimeModal';
import styles from './Configuration.module.css';

export default function PassengerLimit() {
  const { configuration } = useContext(ConfigurationContext);
  const [antecedenceModal, setAntecedenceModal] = useState(false);

  const antecedenceConfig = useMemo(() => {
    return configuration.filter((config) => config.name === 'antecedence_time')[0];
  }, [configuration]);

  return (
    <div className={styles.containerItem}>
      <div className={styles.content}>
        <div className={styles.description}>
          <div className={styles.avatar}>
            <Avatar style={{ backgroundColor: '#FFA500' }} size="large" icon={<FaClock className={styles.dollar} />} />
          </div>
          <div>
            <h4 className={styles.infoPrimary}>Tempo de antecedência</h4>
            <p className={styles.infoSecondary}>Limite máximo para agendamento de viagens</p>
          </div>
        </div>
        <div className={styles.value}>
          <p className={styles.parameter}>{antecedenceConfig?.value ? `${antecedenceConfig?.value}` : '--:--'}</p>
          <FaPen className={styles.pen} onClick={() => setAntecedenceModal(true)} />
        </div>
      </div>
      {antecedenceModal && <AntecedenceTimeModal visible={antecedenceModal} setVisible={setAntecedenceModal} antecedenceConfig={antecedenceConfig} />}
    </div>
  );
}
