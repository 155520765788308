import gql from 'graphql-tag';
import { useSubscription } from 'urql';

const COOPERATIVES_SUBSCRIPTION = gql`
  subscription {
    cooperatives(order_by: { name: asc }, where: { deleted_at: { _is_null: true } }) {
      id
      name
      CNPJ
      proportion
      user {
        id
        email
      }
    }
  }
`;

const handleCooperativesSub = (cooperative = [], response) => {
  return response.cooperatives;
};

export const useCooperatives = () => {
  const [result] = useSubscription(
    {
      query: COOPERATIVES_SUBSCRIPTION,
    },
    handleCooperativesSub,
  );

  console.log(result)

  return result.data || [];
};
