import { useSubscription } from 'urql';
import gql from 'graphql-tag';
import { ICostCenter } from '../../../../types/types';

const PASSENGERS_SUBSCRIPTION = gql`
  subscription($cost_center_list: [uuid!]!) {
    passenger(order_by: { name: asc }, where: { cost_center_id: { _in: $cost_center_list } }) {
      id
      name
      registration
      cost_center_id
      address {
        id
        description
      }
      cost_center {
        id
        number
        description
      }
      locality {
        id
        name
      }
    }
  }
`;

const handlePassengersSub = (passenger = [], response: any) => {
  return response.passenger;
};

export const usePassengers = (cost_center_list: ICostCenter[]) => {
  const [result] = useSubscription(
    {
      query: PASSENGERS_SUBSCRIPTION,
      variables: { cost_center_list: cost_center_list.map((costCenter) => costCenter?.id) },
    },
    handlePassengersSub,
  );

  if (!result.data) return [];

  return result.data;
};
