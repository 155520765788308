import { Checkbox, DatePicker, Modal, notification, Row, Select } from 'antd';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaClock } from 'react-icons/fa';
import styled from 'styled-components';
import { useRecurrenceContext } from '../../contexts/RecurrenceContext';
import generateDailyWorkDays from '../../helpers/generate-daily-workdays';
import generateDates from '../../helpers/generate-dates';
import generateMonthlyDates from '../../helpers/generate-monthly-dates';

const { Option } = Select;

const Text = styled.p`
  margin-top: 1rem;
  color: #4c95c0;
  cursor: pointer;
`;

const Clock = styled(FaClock)`
  margin-bottom: -0.1rem;
`;

const ClearRecurrence = styled.span`
  color: red;
  font-size: 0.75rem;
  font-weight: 400;
  margin-left: 0.25rem;
  cursor: pointer;
`;

const Headline = styled.p`
  margin-bottom: 1rem;
`;

const CustomSelect = styled(Select)`
  margin-top: 0.5rem;
  display: block;
  width: 50%;
`;

const CustomDatePicker = styled(DatePicker)`
  margin-top: 0.5rem;
  display: block;
  width: 50%;
`;

const ContainerField = styled.div`
  margin-bottom: 1rem;
`;

function RecurrentSolicitation({ isMaterial }) {
  const { recurrenceConfig, setRecurrenceConfig } = useRecurrenceContext();
  const { values } = useFormikContext();
  const startDate = values.trip_date;

  const [visible, setVisible] = useState(false);
  const [endDate, setEndDate] = useState(moment());
  const [recurrenceType, setRecurrenceType] = useState('daily');
  const [weekDays, setWeekDays] = useState({
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
  });
  const [isWorkDay, setIsWorkDay] = useState(false);

  const handleWeekDays = (checked, isoWeekDay) => {
    setWeekDays({ ...weekDays, [isoWeekDay]: checked });
  };

  const handleRecurrence = () => {
    if (recurrenceType === 'daily' && isWorkDay) {
      const dates = generateDailyWorkDays(startDate, endDate).filter((_, index) => index !== 0);
      setRecurrenceConfig({ recurrenceType, dates });
      return;
    }

    if (recurrenceType === 'daily') {
      const dates = generateDates(startDate, endDate, weekDays);
      console.log(dates);

      setRecurrenceConfig({ recurrenceType, dates });
      return;
    }

    if (recurrenceType === 'weekly') {
      const dates = generateDates(startDate, endDate, weekDays);

      setRecurrenceConfig({ recurrenceType, dates });
      return;
    }

    if (recurrenceType === 'monthly') {
      const dates = generateMonthlyDates(startDate, endDate).filter((_, index) => index !== 0);

      setRecurrenceConfig({ recurrenceType, dates });

      return;
    }
  };

  const disabledDate = (current) => {
    return (current && current < moment().endOf('day')) || current > moment().endOf('day').add(6, 'month');
  };

  const resetForm = () => {
    setRecurrenceConfig(null);
    setWeekDays({
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: true,
      7: true,
    });
    setRecurrenceType('daily');
    setVisible(false);
  };

  useEffect(() => {
    resetForm()
  }, [isMaterial])

  if(isMaterial) return null

  return (
    <div>
      <Row>
        {!recurrenceConfig ? (
          <Text
            onClick={() => {
              if (values.trip_date) {
                setVisible(true);
              } else {
                notification['error']({
                  message: 'Erro ao definir recorrência',
                  description: 'Escolha a data da viagem primeiro',
                });
              }
            }}
          >
            <Clock /> Customizar recorrência
          </Text>
        ) : (
          <Text>
            <Clock /> Recorrência configurada{' '}
            <ClearRecurrence
              onClick={() => {
                resetForm();
              }}
            >
              Limpar
            </ClearRecurrence>
          </Text>
        )}
      </Row>
      <Modal
        visible={visible}
        title="Recorrência de solicitação"
        onCancel={() => {
          resetForm();
        }}
        onOk={() => {
          handleRecurrence();
          setVisible(false);
        }}
      >
        <Headline>
          A partir da data e horário da presente solicitação ({moment(values.trip_date).format('DD/MM/YY')}), serão válidas as configurações abaixo:
        </Headline>
        <ContainerField>
          <label>Repete-se:</label>
          <CustomSelect
            defaultValue={recurrenceType}
            onChange={(value) => {
              setRecurrenceType(value);

              if (value === 'daily') {
                setWeekDays({
                  1: true,
                  2: true,
                  3: true,
                  4: true,
                  5: true,
                  6: true,
                  7: true,
                });
              }
            }}
          >
            <Option value="daily">Diariamente</Option>
            <Option value="weekly">Semanalmente</Option>
            <Option value="monthly">Mensalmente</Option>
          </CustomSelect>
        </ContainerField>
        {recurrenceType === 'daily' && (
          <ContainerField>
            <Checkbox onChange={(e) => setIsWorkDay(e.target.checked)} checked={isWorkDay}>
              Apenas dias úteis
            </Checkbox>
          </ContainerField>
        )}
        {recurrenceType === 'weekly' && (
          <ContainerField>
            <Checkbox onChange={(e) => handleWeekDays(e.target.checked, 1)} checked={weekDays[1]}>
              Seg
            </Checkbox>
            <Checkbox onChange={(e) => handleWeekDays(e.target.checked, 2)} checked={weekDays[2]}>
              Ter
            </Checkbox>
            <Checkbox onChange={(e) => handleWeekDays(e.target.checked, 3)} checked={weekDays[3]}>
              Qua
            </Checkbox>
            <Checkbox onChange={(e) => handleWeekDays(e.target.checked, 4)} checked={weekDays[4]}>
              Qui
            </Checkbox>
            <Checkbox onChange={(e) => handleWeekDays(e.target.checked, 5)} checked={weekDays[5]}>
              Sex
            </Checkbox>
            <Checkbox onChange={(e) => handleWeekDays(e.target.checked, 6)} checked={weekDays[6]}>
              Sáb
            </Checkbox>
            <Checkbox onChange={(e) => handleWeekDays(e.target.checked, 7)} checked={weekDays[7]}>
              Dom
            </Checkbox>
          </ContainerField>
        )}
        <ContainerField>
          <label>Até:</label>
          <CustomDatePicker
            disabledDate={disabledDate}
            format="DD[/]MM[/]YY"
            allowClear={false}
            value={endDate}
            onChange={(date) => {
              setEndDate(date.startOf('day'));
            }}
          />
        </ContainerField>
      </Modal>
    </div>
  );
}

export default RecurrentSolicitation;
