import { useQuery } from 'urql';
import gql from 'graphql-tag';

const PASSENGERS_SUBSCRIPTION = gql`
  query($cc: [uuid!]!) {
    passenger(order_by: { name: asc }, where: { cost_center_id: { _in: $cc }, deleted_at: { _is_null: true } }) {
      id
      name
      registration
      cost_center_id
      address {
        id
        description
      }
      cost_center {
        id
        number
        description
      }
      locality {
        id
        name
      }
    }
  }
`;

const handlePassengersSub = (passenger = [], response) => {
  return response.passenger;
};

export const usePassengers = (cc = []) => {
  const [result] = useQuery(
    {
      query: PASSENGERS_SUBSCRIPTION,
      variables: { cc: cc },
      requestPolicy: 'cache-and-network',
    },
    handlePassengersSub,
  );

  return { passengers: result.data?.passenger ?? [] };
};
