import { ExclamationCircleFilled } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { FaArrowRight, FaCalendarAlt, FaPhoneAlt } from 'react-icons/fa';
import styled from 'styled-components';
import { isIllegalSolicitation } from '../../pages/Historic/helpers/isIllegalSolicitation';
import styles from './SolicitationItem.module.css';

const Label = styled.p`
  margin-bottom: 0.5rem;
  color: gray;
`;

const Observation = styled.p`
  color: black;
`;

const Phone = styled.span`
  color: gray;
  font-size: 12px;
  font-weight: 400;
  margin-top: 0.25rem;
`;

const Exclamation = styled(ExclamationCircleFilled)`
  color: red;
`;

export default function SolicitationItem({ solicitation }) {
  const formatNumber = (number) => {
    const string = number.toString();
    if (string.length > 3) {
      const substring = string.slice(string.length - 3);

      return '...' + substring;
    } else {
      return number;
    }
  };

  return (
    <div className={styles.solicitation}>
      <Row gutter={32} key={solicitation.id} type="flex" justify="start" align="middle">
        <Col span={1}>
          <div className={styles.avatar}>
            <p className={styles.avatarNumber}>
              <Tooltip title={`Número: ${solicitation.number}`}>{formatNumber(solicitation.number)}</Tooltip>
            </p>
          </div>
        </Col>
        <Col span={10}>
          <p className={styles.title}>
            {solicitation.passenger_name || solicitation.entity_name}
            <br />
            <Phone>
              <FaPhoneAlt style={{ marginRight: '0.25rem' }} />
              {solicitation.phone || 'Contato não fornecido'}
            </Phone>
          </p>
          <Row gutter={16} type="flex" justify="start" align="middle">
            <Col>
              <Tooltip title={solicitation.origin.address}>
                <p className={styles.address}>
                  {isIllegalSolicitation(solicitation) && <Exclamation />} {solicitation.origin.address}
                </p>
              </Tooltip>
            </Col>
            <Col>
              <FaArrowRight className={styles.arrowIcon} />
            </Col>
            <Col>
              <Tooltip title={solicitation.destination.address}>
                <p className={styles.address}>{solicitation.destination.address}</p>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col span={4}>
          <p className={styles.date}>
            <FaCalendarAlt className={styles.mediumIcon} />
            {moment(solicitation.created_at).format('DD/MM/YY[ às ] HH:mm')}
          </p>
          <p>
            Solicitante: <span style={{ fontWeight: 'bold' }}>{solicitation.user.passenger.name}</span>
          </p>
        </Col>
        <Col span={9}>
          <Label>Observações</Label>
          <Observation>{solicitation.observation}</Observation>
        </Col>
      </Row>
    </div>
  );
}
