import React from 'react';
import { Input } from 'antd';
import styles from './Phone.module.css';
import { FaPhoneAlt } from 'react-icons/fa';

export default function Phone(props) {
  const onChange = (e) => {
    const value = e.target.value;
    const { name, setFieldValue } = props;

    console.log(value);

    const maskedValue = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
      .replace(/(-\d{4})\d+?$/, '$1');

    setFieldValue(name, maskedValue);
  };

  return (
    <>
      <label className={styles.label}>{props.label}</label>
      <div className={styles.inputContainer}>
        <Input prefix={<FaPhoneAlt style={{ color: '#AAA' }} />} type="phone" onChange={onChange} defaultValue={props.value} value={props.value} />
      </div>
    </>
  );
}
