import { BankFilled, EnvironmentFilled, EnvironmentOutlined, HomeFilled } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React from 'react';
import { FaIndustry } from 'react-icons/fa';
import { CONTINENTAL_ADDRESS } from '../../constants';
import styles from './LastSolicitations.module.css';

export default function LastSolicitations({ solicitations, passenger, loading, setLoading }) {
  const formik = useFormikContext();

  const icon = (address) => {
    if (passenger.address.description === address) {
      return <HomeFilled className={styles.avatarIcon} />;
    }

    if (CONTINENTAL_ADDRESS === address) {
      return <FaIndustry className={styles.avatarIcon} />;
    }

    return <BankFilled className={styles.avatarIcon} />;
  };

  const fetchForm = (s) => {
    setLoading(true);

    const { passenger_id, passenger_name, origin, destination, type, cost_center_id, origin_locality_id, destination_locality_id, phone } = s;

    const solicitation = {
      passenger_id,
      passenger_name,
      origin: origin.address,
      destination: destination.address,
      type,
      cost_center_id,
      observation: null,
      origin_locality_id,
      destination_locality_id,
      phone,
    };

    formik.setValues(solicitation)

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  return solicitations.length ? (
    <div className={styles.lastSolicitations}>
      <h2 className={styles.headingTitle}>Últimas solicitações</h2>
      <Row gutter={32}>
        {solicitations.map((s) => (
          <Col key={s.id} span={8} onClick={() => fetchForm(s)}>
            <div className={styles.cardContainer}>
              <p className={styles.date}>{moment(s.trip_date).format('DD/MM/YY')}</p>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <div className={styles.avatar}>{icon(s.destination.address)}</div>
                </Col>
                <Col span={20}>
                  <Tooltip title={s.origin.address}>
                    <p className={styles.address}>
                      <EnvironmentOutlined className={styles.pinIcon} />
                      {s.origin.address}
                    </p>
                  </Tooltip>
                  <div className={styles.littleCircle}></div>
                  <div className={styles.littleCircle}></div>
                  <Tooltip title={s.destination.address}>
                    <p className={styles.address}>
                      <EnvironmentFilled className={styles.pinIcon} />
                      {s.destination.address}
                    </p>
                  </Tooltip>
                </Col>
              </Row>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  ) : (
    ''
  );
}
