import moment from "moment";
import getRecurrenceLabel from "./get-recurrence-label";

export const transformDataInRecurrentSolicitationsToExcel = data => {
    const recurrentSolicitations = data?.recurrent_solicitation ?? []

    return recurrentSolicitations.map(recurrentSolicitation => {
        const dates = recurrentSolicitation.recurrence_config?.dates ?? []

        return {
            passengerName: recurrentSolicitation.passenger?.name,
            createdDate: moment(recurrentSolicitation.created_at).format('DD/MM/YY'),
            untilDate: moment(dates[dates.length - 1]).format('DD/MM/YY'),
            quantity: dates.length,
            type: getRecurrenceLabel(recurrentSolicitation.type),
        }
    })
}
