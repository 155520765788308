import { Col, Input, message, Modal, notification, Row } from 'antd';
import gql from 'graphql-tag';
import { uniq } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'urql';
import { EXTREMO_COST, TIME_AVAILABLE_COST, VIA_COST } from '../../constants';
import { useExtraCosts } from '../Cooperatives/EditCooperative/useExtraCosts';
import { removeHigherCost } from '../Solicitations/Monetizer/helpers/removeHigherCost';
import EditCosts from './EditCosts';
import styles from './EditTripModal.module.css';
import Recipe from './Recipe';
import { useCosts } from './useCosts';
import { useSolicitationCosts } from './useSolicitationCosts';

const { TextArea } = Input;

const Disable = styled.div`
  background-color: rgba(360, 360, 360, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
`;

const TRIP_MUTATION = gql`
  mutation($id: uuid!, $obj: trip_set_input!) {
    update_trip(where: { id: { _eq: $id } }, _set: $obj) {
      affected_rows
      returning {
        id
        number
        state
        trip_date
        time
        approved_at
        charged_at
        observation
        costs {
          id
          value
          provenience
          amount
          extra_cost_type {
            id
            description
            name
            type
          }
        }
        cooperative {
          id
          name
        }
      }
    }
  }
`;

const DISAPPROVE_MUTATION = gql`
  mutation($id: [uuid!]) {
    update_trip(where: { id: { _in: $id } }, _set: { approved_at: null }) {
      affected_rows
      returning {
        id
        number
        state
        trip_date
        time
        approved_at
        charged_at
        observation
        costs {
          id
          value
          provenience
          amount
          extra_cost_type {
            id
            description
            name
            type
          }
        }
        cooperative {
          id
          name
        }
      }
    }
  }
`;

const COST_INSERT = gql`
  mutation($obj: [cost_insert_input!]!) {
    insert_cost(objects: $obj, on_conflict: { constraint: cost_pkey, update_columns: [value, amount] }) {
      affected_rows
      returning {
        id
        value
        provenience
        amount
        extra_cost_type {
          id
          description
          name
          type
        }
      }
    }
  }
`;

const COST_DELETE = gql`
  mutation($id: [uuid!]) {
    delete_cost(where: { id: { _in: $id } }) {
      affected_rows
    }
  }
`;

export default function EditTripModal({ trip, visible, setVisible }) {
  const [observation, setObservation] = useState(trip?.observation);
  const [, update_trip] = useMutation(TRIP_MUTATION);
  const [, insert_cost] = useMutation(COST_INSERT);
  const [, delete_cost] = useMutation(COST_DELETE);
  const [, disapprove_trip] = useMutation(DISAPPROVE_MUTATION);

  const costs = useCosts(trip.id) || [];
  const extraCostTypes = useExtraCosts(trip.cooperative.id);

  const solicitationCosts = useSolicitationCosts(trip);

  const tripOriginLocalities = uniq(trip.solicitations.map((solicitation) => solicitation.origin_locality_id));

  const tripDestinationLocalities = uniq(trip.solicitations.map((solicitation) => solicitation.destination_locality_id));

  const disapprove_mutation = () => {
    disapprove_trip({ id: [trip.id] })
      .then((e) => console.log(e))
      .catch((e) => console.error(e));
  };

  const mutate_trip = (id, obj) => {
    update_trip({ id, obj: obj })
      .then((e) => {
        console.log(e);
        setVisible(false);
        if (e.data) {
          notification['success']({
            message: 'Viagem editada',
            description: 'A viagem foi editada com sucesso',
          });
          disapprove_mutation();
        }
      })
      .catch((e) => console.log(e));
  };

  const extrem_cost = (value, type, remove = false, newValue = 0) => {
    if(remove || !value){
      const costId = costs.find((c) => c.extra_cost_type?.name === type).id;
      delete_cost({ id: [costId] }).then((e) => {
        if (e.data) {
          message.success('Custo removido com sucesso');
        } else {
          message.error('Custo não removido');
        }
      });
    }
    
    if (value) {
      
      const extraCost = extraCostTypes.find((c) => c.extra_cost_type?.name === type) || 0;

      var costValue = 0

      if(remove){
        costValue = Math.round(newValue * extraCost.value) / 100;
      } else {
        costValue = Math.round(baseCost?.value * extraCost.value) / 100;
      }
      

      console.log(costValue);

      const cost = { value: costValue, trip_id: trip.id, extra_cost_type_id: extraCost.extra_cost_type.id };
      add_cost([cost]);
    } 
  };

  const via_cost = (value) => {
    if (value) {
      const extraCost = extraCostTypes.find((c) => c.extra_cost_type?.name === VIA_COST);

      const viaCosts = removeHigherCost(solicitationCosts).map((cost) => ({
        value: Math.round(cost * extraCost?.value) / 100,
        trip_id: trip.id,
        extra_cost_type_id: extraCost.extra_cost_type.id,
      }));

      add_cost(viaCosts);
    } else {
      const costIds = costs.filter((c) => c.extra_cost_type?.name === VIA_COST).map(({ id }) => id);
      console.log(costIds);
      delete_cost({ id: costIds }).then((e) => {
        console.log(e);
        if (e.data) {
          message.success('Custo removido com sucesso');
        } else {
          message.error('Custo não removido');
        }
      });
    }
  };

  const parking_cost = (value) => {
    const existingCost = costs.find((c) => c.provenience === 'PARKING');

    existingCost && delete existingCost['extra_cost_type'];

    const cost = existingCost ? { ...existingCost, value } : { value, trip_id: trip.id, provenience: 'PARKING' };

    add_cost(cost);
  };

  const hour_cost = (value) => {
    const amount = +value;
    const extraCost = extraCostTypes.find(cost => cost?.extra_cost_type?.name === TIME_AVAILABLE_COST);
    const extraCostValue = extraCost?.value ?? 0;
    const currentCost = costs.find(cost => cost?.extra_cost_type?.name === TIME_AVAILABLE_COST);
    const nextValue = extraCostValue * amount;
    let nextCost;

    if (currentCost) {
      const { id, trip_id, extra_cost_type_id } = currentCost;

      nextCost = { id, value: nextValue, trip_id, extra_cost_type_id, amount };
    } else nextCost = { value: nextValue, trip_id: trip.id, extra_cost_type_id: extraCost.extra_cost_type.id, amount };

    add_cost(nextCost);
  };

  const add_cost = (cost) => {
    insert_cost({ obj: cost }).then((e) => {
      if (e.data) {
        message.success('Custo adicionado com sucesso');
        disapprove_mutation();
      } else {
        message.error('Custo não adicionado');
      }
    });
  };

  const getCost = (type) => {
    if (!costs.length) return;

    let cost;

    if (type === 'PARKING') {
      cost = costs.find((c) => c.provenience === type);
      return cost?.value || 0;
    }

    if (type === TIME_AVAILABLE_COST) {
      const currentHourValue = extraCostTypes.find(cost => cost?.extra_cost_type?.name === type)?.value ?? 1;
      const tripHourData = costs.find(cost => cost?.extra_cost_type?.name === type);
      const tripHourValue = tripHourData?.value ?? 0;
      let amount = tripHourData?.amount ?? 0;

      if(!amount && tripHourValue) amount = +(tripHourValue / currentHourValue).toFixed(2)

      return { hourCost: tripHourValue, amount };
    }

    cost = costs.find((c) => c.extra_cost_type?.name === type);
    return cost?.value || 0;
  };

  const getViaCost = () => {
    if (!costs.length) return;

    const viaCosts = costs.filter((c) => c.extra_cost_type?.name === 'VIA').reduce((a, b) => a + b.value, 0) || 0;

    return viaCosts;
  };

  const viaDisable = () => {
    return tripDestinationLocalities.length < 2 && tripOriginLocalities.length < 2;
  };

  const extremDisable = () => {
    return tripDestinationLocalities.length > 1 || tripOriginLocalities.length > 1;
  };

  const isChecked = (costs = [], type) => {
    const check = costs.findIndex((c) => c.extra_cost_type?.name === type);
    if (check === -1) return false;
    return true;
  };

  const baseCost = costs.find((c) => c.extra_cost_type === null && c.provenience === null) || 0;
  const extremCost = getCost(EXTREMO_COST);
  const viaCost = getViaCost();
  const { hourCost, amount } = getCost(TIME_AVAILABLE_COST) || { hourCost: 0, amount: 0 };
  const parkingCost = getCost('PARKING');

  return (
    <Modal
      title="Editar uma viagem"
      destroyOnClose={true}
      width={700}
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => mutate_trip(trip.id, { observation })}
    >
      <div>
        <p className={styles.instruction}>Caso esta viagem tenha um custo diferente do calculado, insira os novos custos nos campos abaixo</p>
        <h2 className={styles.costTitle}>Descrição de custos</h2>
        <Row>
          <Col span={12}>
            <EditCosts
              trip={trip}
              extremDisable={extremDisable}
              viaDisable={viaDisable}
              isChecked={isChecked}
              extrem_cost={extrem_cost}
              via_cost={via_cost}
              hour_cost={hour_cost}
              parking_cost={parking_cost}
              disapprove_mutation={disapprove_mutation}
              baseCost={baseCost}
              parkingCost={parkingCost}
              amount={amount}
              costs={costs}
            />
          </Col>
          <Col span={12}>
            <Recipe baseCost={baseCost} extremCost={extremCost} viaCost={viaCost} hourCost={hourCost} parkingCost={parkingCost} />
          </Col>
        </Row>
      </div>
      <label className={styles.label}>Observações</label>
      <div className={styles.textAreaContainer}>
        <TextArea rows={3} placeholder="Insira alguma observação relevante" onChange={(e) => setObservation(e.target.value)} defaultValue={observation} />
      </div>
      {trip.charged_at && (
        <Disable
          title="Essa viagem já foi faturada"
          onClick={() => {
            setVisible(false);
          }}
        />
      )}
    </Modal>
  );
}
