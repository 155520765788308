export const generateSolicitationInfosModel = solicitation => {
    const entityName = solicitation?.entity_name
    const hasEntityName = !!entityName
    const registration = solicitation?.passenger?.registration || 'Visitante'

    return {
        passenger_name: hasEntityName ? '' : solicitation?.passenger_name,
        passenger_registration: hasEntityName ? '' : registration,
        entity_name: entityName,
    }
}
