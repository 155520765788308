import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, Table, Tooltip } from 'antd';
import gql from 'graphql-tag';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useMutation } from 'urql';
import { useCostCenters } from '../ControlPanel/useCostCenters';
import styles from './CostCenters.module.css';
import ImportCostCentersModal from './ImportCostCenters';
import { CostCentersSaveModal } from "./CostCentersSaveModal/CostCentersSaveModal";
import { UserContext } from "../../contexts/UserContext";
import { useHistory } from "react-router-dom";

const DELETE_COST_CENTERS_MUTATION = gql`
  mutation($id: uuid!) {
    update_cost_center(where: { id: { _eq: $id } }, _set: { deleted_at: "now()" }) {
      affected_rows
    }
  }
`;

export default function CostCenters() {
  const [modalCostCenter, setModalCostCenter] = useState(false);
  const [, delete_cost_centers] = useMutation(DELETE_COST_CENTERS_MUTATION);
  const [costCentersSaveData, setCostCentersSaveData] = useState(null);
  const [isOpenedCostCentersSaveModal, setIsOpenedCostCentersSaveModal] = useState(false);
  const history = useHistory();
  const { user } = useContext(UserContext);
  const hasCruds = useMemo(() => user?.user?.user_permission?.cruds, [user])

  const openCostCentersSaveModal = data => {
    setCostCentersSaveData(data || null);
    setIsOpenedCostCentersSaveModal(true)
  }

  const closeCostCentersSaveModal = () => {
    setIsOpenedCostCentersSaveModal(false)
    setCostCentersSaveData(null);
  }

  const remove_cost_centers = (id) => {
    delete_cost_centers({ id })
      .then((e) => console.log(e))
      .catch((e) => console.error(e));
  };

  const columns = [
    {
      title: 'N°',
      dataIndex: 'item_number',
      key: 'item_number',
    },
    {
      title: 'Número',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      key: 'id',
      render: (text, { id, number, description }) => {
        const editText = 'Editar centro de custo'

        return (
          <div className={styles.tableActions}>
            <Tooltip placement={'top'} title={editText}>
              <Button type={'text'}
                      className={'action-button'}
                      aria-label={editText}
                      onClick={() => openCostCentersSaveModal({ id, number, name: description })}>
                <FaEdit aria-hidden={true} />
              </Button>
            </Tooltip>

            <Popconfirm
              title="Você tem certeza que deseja deletar este centro de custo?"
              onConfirm={() => {
                remove_cost_centers(id);
              }}
              okText="Sim"
              cancelText="Não"
            >
              <Tooltip placement="top" title="Excluir">
                <FaTrash style={{ cursor: 'pointer' }} className={styles.permissionIcon} />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const { costCenters, setCostCenterDesc } = useCostCenters();

  const formattedCostCenters = costCenters.map((c, i) => ({ ...c, item_number: i + 1 }));

  useEffect(() => {
    if(typeof hasCruds !== 'boolean' || hasCruds) return

    history.goBack()
  }, [hasCruds, history])

  if(!hasCruds) return null

  return (
    <div>
      <div className={styles.pageHeadingContainer}>
        <h2 className={styles.pageHeading}>
          Centros de Custo <span className={styles.pageHeadingCounter}>{costCenters.length} itens</span>
        </h2>
        <div className={styles.actions}>
          <button onClick={() => openCostCentersSaveModal()} className={styles.primaryButton}>
            Criar centro de custo
          </button>

          <button onClick={() => setModalCostCenter(true)} className={styles.primaryButton}>
            Importar
          </button>
        </div>
      </div>
      <div className={styles.inputContainer}>
        <Input
          onChange={(e) => setCostCenterDesc(`%${e.target.value}%`)}
          size="large"
          placeholder="Pesquisar por um centro de custo"
          prefix={<SearchOutlined type="search" />}
        />
      </div>
      <Table rowKey="id" loading={false} columns={columns} dataSource={formattedCostCenters} />
      <ImportCostCentersModal visible={modalCostCenter} setVisible={setModalCostCenter} />

      <CostCentersSaveModal isOpened={isOpenedCostCentersSaveModal} data={costCentersSaveData} close={closeCostCentersSaveModal} />
    </div>
  );
}
