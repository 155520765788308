import { Icon, Input, message, Modal } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'urql';
import styles from './ChangePasswordModal.module.css';

const CHANGE_PASSWORD_MUTATION = gql`
  mutation($new_password: String!, $user_id: ID!) {
    auth_change_password(new_password: $new_password, user_id: $user_id) {
      affected_rows
    }
  }
`;

export default function ChangePasswordModal({ cooperative, visible, setVisible }) {
  const [password, setPassword] = useState('');

  const [, change_password] = useMutation(CHANGE_PASSWORD_MUTATION);

  const changePassword = () => {
    change_password({ new_password: password, user_id: cooperative.user.id })
      .then((e) => {
        console.log(e);
        if (e.error) {
          message.error('Erro ao alterar a senha');
        } else {
          message.success('Senha alterada com sucesso');
          setVisible(false);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <Modal title="Alterar senha de uma" destroyOnClose={true} visible={visible} onCancel={() => setVisible(false)} onOk={changePassword}>
      <div style={{ textAlign: 'center' }}>
        <p className={styles.instruction}>Insira a nova senha desta cooperativa no campo abaixo</p>
        <label style={{ color: '#909090' }}>Nome da Cooperativa</label>
        <h2 className={styles.passengerName}>{cooperative.name || '--'}</h2>
        <h5 className={styles.registration}>
          <strong>Usuário: </strong>
          {cooperative.user.email || '--'}
        </h5>
        <Input
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Insira a nova senha da cooperativa"
          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
          size="large"
          style={{ marginBottom: '1rem', width: '80%' }}
        />
      </div>
    </Modal>
  );
}
