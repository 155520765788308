import { useState, useEffect } from 'react';
import { getValuesOfSolicitations } from '../Solicitations/Monetizer/helpers/getValuesOfSolicitations';

export const useSolicitationCosts = (trip) => {
  const [costs, setCosts] = useState([]);

  useEffect(() => {
    const getCosts = async () => {
      const solicitationCosts = await getValuesOfSolicitations(trip);

      setCosts(solicitationCosts);
    };

    getCosts();
  }, [trip]);

  return costs;
};
