import moment from 'moment';

export default function (startAt, endAt, weekDays) {
  console.log(startAt, endAt, weekDays);
  const end = moment(endAt);
  const start = moment(startAt).add(1, 'day');
  const endIndex = end.diff(moment(startAt), 'days') + 1;

  const dates = [];
  for (let i = 0; i < endIndex; i++) {
    const startLoop = moment(start);
    let recurrentDay = startLoop.add(i, 'day');

    const isoWeekDay = recurrentDay.isoWeekday();

    if (weekDays[isoWeekDay]) {
      dates.push(recurrentDay);
    }
  }

  console.log(dates);

  return dates;
}
