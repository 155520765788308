import React, { useContext } from 'react';

const RecurrenceContext = React.createContext();

export default function RecurrenceProvider(props) {
  const { recurrenceConfig, setRecurrenceConfig } = props;
  const value = {
    recurrenceConfig,
    setRecurrenceConfig
  };
  return <RecurrenceContext.Provider value={value}>{props.children}</RecurrenceContext.Provider>;
}

export function useRecurrenceContext() {
  const context = useContext(RecurrenceContext);

  if (typeof context === 'undefined') {
    throw new Error(`useRecurrence must be used within a DashboardContext`);
  }

  return context;
}
