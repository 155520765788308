import { Input, Modal, notification } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'urql';
import styles from './ConfigurationModal.module.css';

const UPDATE_MUTATION = gql`
  mutation($id: uuid!, $obj: reason_set_input) {
    update_reason(where: { id: { _eq: $id } }, _set: $obj) {
      affected_rows
    }
  }
`;

const INSERT_MUTATION = gql`
  mutation($description: String) {
    insert_reason(objects: { description: $description }) {
      affected_rows
    }
  }
`;

export default function ReasonsListModal({ reason, visible, setVisible }) {
  const [value, setValue] = useState(reason?.description);

  const [, update_reason] = useMutation(UPDATE_MUTATION);
  const [, insert_reason] = useMutation(INSERT_MUTATION);

  const execute_mutation = () => {
    if (reason) {
      update_reason({ id: reason.id, obj: { description: value } }).then((e) => {
        if (e.data) {
          notification['success']({
            message: 'Motivo atualizado com sucesso',
            description: `A partir de agora todas as solicitações com este motivo foram alteradas`,
          });
          setVisible(false);
        } else {
          notification['error']({
            message: 'Falha ao alterar o motivo',
            description: 'Contate o suporte.',
          });
          setVisible(false);
        }
      });
    } else {
      insert_reason({ description: value }).then((e) => {
        if (e.data) {
          notification['success']({
            message: 'Motivo criado com sucesso',
            description: `A partir de agora esse motivo pode ser utilizado nas solicitações`,
          });
          setVisible(false);
        } else {
          notification['error']({
            message: 'Falha ao adicionar o motivo',
            description: 'Contate o suporte.',
          });
          setVisible(false);
        }
      });
    }
  };

  return (
    <Modal destroyOnClose={true} title="Motivo" visible={visible} onCancel={() => setVisible(false)} onOk={execute_mutation}>
      <div style={{ textAlign: 'center' }}>
        <p className={styles.instruction}>Insira um nome para este motivo</p>
        <p className={styles.costType}>{reason?.description ?? 'Novo motivo'}</p>

        <Input onChange={(e) => setValue(e.target.value)} value={value} placeholder="Ex.: ATRASO" size="large" style={{ margin: '0.5rem 0', width: '50%' }} />
      </div>
    </Modal>
  );
}
