import { notification } from 'antd';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import React, { useContext, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'urql';
import { UserContext } from '../../contexts/UserContext';
import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal';
import LastSolicitations from './components/LastSolicitations/LastSolicitations.jsx';
import LoadingFormProvider from './components/LoadingForm/LoadingForm';
import PassengerInput from './components/PassengerInput/PassengerInput';
import SolicitationForm from './components/SolicitationForm/SolicitationForm';
import RecurrenceProvider from './contexts/RecurrenceContext';
import { getAddress } from './helpers/get-address';
import { usePassengers } from './hooks/usePassengers';
import { useSolicitations } from './hooks/useSolicitations';
import { useUserCostCenters } from './hooks/useUserCostCenters';
import styles from './NewSolicitation.module.css';
import generateRecurrentSolicitations from './helpers/generate-recurrent-solicitations';

export const RadioContext = React.createContext();
export const SelectedPassengerContext = React.createContext();
export const LastSolicitationsContext = React.createContext();

const INSERT_SOLICITATION_MUTATION = gql`
  mutation($objects: [solicitation_insert_input!]!) {
    insert_solicitation(objects: $objects) {
      affected_rows
      returning{
        id
      }
    }
  }
`;

const INSERT_RECURRENT_MUTATION = gql`
  mutation($object: recurrent_solicitation_insert_input!) {
    insert_recurrent_solicitation_one(object: $object) {
      id
    }
  }
`;

export default function NewSolicitation() {
  const [selectedPassenger, setSelectedPassenger] = useState(null);
  const [radio, setRadio] = useState('worker');
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [withReturn, setWithReturn] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [hasEntityName, setHasEntityName] = useState(false);
  const [isMaterial, setIsMaterial] = useState(false);
  const [lastCreatedSolicitationId, setLastCreatedSolicitationId] = useState(null);
  const [recurrenceConfig, setRecurrenceConfig] = useState();

  const { user } = useContext(UserContext);

  const history = useHistory();
  const costCenters = useUserCostCenters();
  const { passengers } = usePassengers([...costCenters, user.cost_center_id].filter(Boolean));
  const lastSolicitations = useSolicitations(selectedPassenger?.id);

  const [, insert_solicitation] = useMutation(INSERT_SOLICITATION_MUTATION);
  const [, insert_recurrent_solicitation] = useMutation(INSERT_RECURRENT_MUTATION);

  const add_solicitation = async solicitations => {
    insert_solicitation({ objects: solicitations }).then(e => {
      const { data } = e

      if (data) {
        console.log(e);
        notification['success']({
          message: 'Solicitação enviada',
          description: 'Sua solicitação foi enviada e será analisada pelo Departamento de Transportes',
        });
        setSelectedPassenger(null);
        setLastCreatedSolicitationId(data.insert_solicitation.returning[0].id);
      } else {
        console.log(e);
        notification['error']({
          message: 'Falha ao enviar solicitação',
          description: e.message || 'Verifique se essa viagem não está duplicada e se todos os campos obrigatórios foram preenchidos corretamente',
        });
      }
    });
  };

  const add_recurrent_solicitation = async solicitation => {
    insert_recurrent_solicitation({ object: solicitation })
      .then(e => {
        if (e.data) {
          console.log(e);
          notification['success']({
            message: 'Recorrência criada com sucesso',
            description: 'Confira a recorrência criada no módulo de solicitações recorrentes',
          });
          setSelectedPassenger(null);
        } else {
          notification['error']({
            message: 'Falha ao criar recorrência',
            description: 'Sua recorrência não foi enviada ao Departamento de Transportes. Recarregue a página e tente novamente ou contate o suporte',
          });
        }
      })
      .catch(e => console.error(e));
  };

  const submitForm = async values => {
    const observation = values?.observation?.trim();
    const entity_name = values?.entity_name?.trim();

    if(isMaterial && (!entity_name || !observation)){
      notification['error']({
        message: 'Ocorreu um erro ao criar solicitação',
        description: 'Você deve preencher todos os campos obrigatórios do formulário',
      })

      return
    }

    setLoadingSubmit(true);
    const newSolicitations = [];

    const origin = await getAddress(values.origin, selectedPassenger);
    const destination = await getAddress(values.destination, selectedPassenger);

    const solicitation = { ...values, observation, entity_name, origin, destination, trip_date: values.trip_date };
    delete solicitation['return_time'];
    delete solicitation['return_date'];
    newSolicitations.push(solicitation);

    // CASO O USUÁRIO MARQUE A OPÇÃO DE SOLICITAÇÃO COM RETORNO INVERTE-SE A ORIGEM E DESTINO APLICANDO O NOVO HORÁRIO E DATA
    const returnSolicitation = {
      ...values,
      observation,
      entity_name,
      origin: destination,
      destination: origin,
      origin_locality_id: values.destination_locality_id,
      destination_locality_id: values.origin_locality_id,
      trip_date: values.return_date,
      time: values.return_time,
    };
    delete returnSolicitation['return_time'];
    delete returnSolicitation['return_date'];

    if (withReturn) {
      newSolicitations.push(returnSolicitation);
    }

    if (recurrenceConfig) {
      const recurrentSolicitations = generateRecurrentSolicitations(recurrenceConfig, solicitation);
      const recurrentReturnSolicitations = generateRecurrentSolicitations(recurrenceConfig, returnSolicitation);
      await add_recurrent_solicitation(recurrentSolicitations);
      if (withReturn) {
        await add_recurrent_solicitation(recurrentReturnSolicitations);
      }
    }

    await add_solicitation(newSolicitations);
  };

  return (
    <div>
      <Formik
        initialValues={{
          passenger_id: selectedPassenger?.id,
          passenger_name: selectedPassenger?.name ?? '',
          entity_name: null,
          origin: null,
          origin_locality_id: null,
          destination: null,
          destination_locality_id: null,
          trip_date: null,
          time: null,
          return_time: null,
          return_date: null,
          reason_id: null,
          type: 'worker',
          cost_center_id: selectedPassenger?.cost_center_id,
          observation: null,
          phone: null,
          explain_why_no_default_way: '',
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await submitForm(values);
          setSubmitting(false);
          resetForm();
          setWithReturn(false);
          setConfirmModal(false);
          setLoadingSubmit(false);
        }}
      >
        {({ values, handleSubmit }) => (
          <RadioContext.Provider value={radio}>
            <SelectedPassengerContext.Provider value={selectedPassenger}>
              <LastSolicitationsContext.Provider value={lastSolicitations}>
                <div className={styles.pageHeadingContainer}>
                  <h2 className={styles.pageHeading}>
                    <FaArrowLeft onClick={() => history.goBack()} className={styles.arrowIcon} />
                    Nova solicitação
                  </h2>
                </div>
                <div className={styles.titleContainer}>
                  <h2 className={styles.question}>Para quem ou o que você gostaria de solicitar o transporte?</h2>
                  <PassengerInput
                    passengers={passengers}
                    costCenters={costCenters}
                    selectedPassenger={selectedPassenger}
                    selectPassenger={setSelectedPassenger}
                    setRadio={setRadio}
                    setHasEntityName={setHasEntityName}
                    isMaterial={isMaterial}
                    setIsMaterial={setIsMaterial}
                    lastCreatedSolicitationId={lastCreatedSolicitationId}
                    setWithReturn={setWithReturn}
                  />
                </div>
                {selectedPassenger && (
                  <LastSolicitations solicitations={lastSolicitations} passenger={selectedPassenger} loading={loading} setLoading={setLoading} />
                )}
                <LoadingFormProvider loading={loading} setLoading={setLoading}>
                  <RecurrenceProvider recurrenceConfig={recurrenceConfig} setRecurrenceConfig={setRecurrenceConfig}>
                    {(selectedPassenger || hasEntityName) && (
                      <SolicitationForm
                        setVisible={setConfirmModal}
                        selectedPassenger={selectedPassenger}
                        lastSolicitations={lastSolicitations}
                        withReturn={withReturn}
                        setWithReturn={setWithReturn}
                        isMaterial={isMaterial}
                      />
                    )}
                    <ConfirmationModal
                      passenger={selectedPassenger}
                      loadingSubmit={loadingSubmit}
                      withReturn={withReturn}
                      visible={confirmModal}
                      setVisible={setConfirmModal}
                      values={values}
                      handleSubmit={handleSubmit}
                    />
                  </RecurrenceProvider>
                </LoadingFormProvider>
              </LastSolicitationsContext.Provider>
            </SelectedPassengerContext.Provider>
          </RadioContext.Provider>
        )}
      </Formik>
    </div>
  );
}
