import { flatten } from 'lodash';
import moment from 'moment';
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import { ICooperative, ICostCenter, ITrip } from '../../../types/types';
import { useTrips } from '../useTrips';
import { generateSolicitationInfosModel } from "../../../helpers/generate-models";

interface DashboardContextValue {
  startDate?: Date;
  endDate?: Date;
  trips: ITrip[];
  formatTrips: ITrip[];
  selectedCooperative: ICooperative | null;
  selectedCostCenters: ICostCenter[];
  costCentersList: ICostCenter[];
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  setSelectedCooperative: React.Dispatch<React.SetStateAction<ICooperative | null>>;
  setSelectedCostCenters: React.Dispatch<React.SetStateAction<ICostCenter[]>>;
}

interface DashboardProviderProps {
  children: ReactNode;
}

const DashboardContext = createContext<DashboardContextValue>({} as any);

export default function DashboardProvider(props: DashboardProviderProps) {
  const { user } = useContext(UserContext);

  const user_cost_center_list = useMemo(() => user?.user?.user_cost_centers.map(({ cost_center }: any) => cost_center) ?? [], [user]);

  const costCentersList = useMemo(() => [...user_cost_center_list, user?.cost_center].filter(Boolean), [user_cost_center_list, user]);

  const [startDate, setStartDate] = useState<Date>(
    moment()
      .subtract(1, 'month')
      .startOf('month')
      .toDate(),
  );
  const [endDate, setEndDate] = useState<Date>(
    moment()
      .endOf('day')
      .toDate(),
  );
  const [selectedCooperative, setSelectedCooperative] = useState<ICooperative | null>(null);
  const [selectedCostCenters, setSelectedCostCenters] = useState<ICostCenter[]>([]);

  useEffect(() => {
    setSelectedCostCenters(costCentersList);
  }, [user, costCentersList]);

  const { data, fetching, error } = useTrips(startDate, endDate, selectedCostCenters);

  const sanitizedTrips = data.map((trip: ITrip) => {
    const solicitations = trip.solicitations;
    const duplicatedTrips = solicitations.map((solicitation: any) => ({
      ...trip,
      costs: trip.costs.reduce((a: number, b: any) => a + b.value, 0),
      solicitation,
    }));

    return duplicatedTrips;
  });

  const flatTrips = flatten(sanitizedTrips) as ITrip[];

  const formatTrips = flatTrips.map(trip => ({
    cooperative: trip.cooperative.name,
    cost_center_number: trip.solicitation.cost_center.number,
    cost_center_description: trip.solicitation.cost_center.description,
    cost_center_id: trip.solicitation.cost_center.id,
    cost: trip.costs / trip.solicitations.length,
    reason: trip.solicitation.reason.description,
    trip_date: moment(trip.trip_date).format('DD/MM/YY'),
    original_trip_date: trip.trip_date,
    ...generateSolicitationInfosModel(trip.solicitation),
  }));

  const value = {
    startDate,
    endDate,
    fetching,
    error,
    trips: data,
    formatTrips,
    selectedCooperative,
    selectedCostCenters,
    costCentersList,
    setStartDate,
    setEndDate,
    setSelectedCooperative,
    setSelectedCostCenters,
  } as any;

  return <DashboardContext.Provider value={value}>{props.children}</DashboardContext.Provider>;
}

export function useDashboardContext() {
  const context = useContext(DashboardContext);

  if (typeof context === 'undefined') {
    throw new Error(`useDashboardContext must be used within a DashboardContext`);
  }

  return context;
}
