import { Tabs } from 'antd';
import React, { useContext } from 'react';
import CooperativesProvider from '../../contexts/CooperativesContext';
import { UserContext } from '../../contexts/UserContext';
import PendentSolicitations from './PendentSolicitations/PendentSolicitations';
import TripsContextProvider from './Router/TripsContextProvider';
import styles from './Solicitations.module.css';
import Trips from './Trips/Trips';
import useTrips from './useTrips';
import { SolicitationsProvider } from "../../providers/SolicitationsProvider";
import { EditSolicitationModal } from "./PendentSolicitations/EditSolicitationModal";
import { Formik } from "formik";
import * as Yup from "yup";
import { CancelledSolicitations } from "./CancelledSolicitations/CancelledSolicitations";

const { TabPane } = Tabs;

export default function Solicitations() {
  const { user } = useContext(UserContext);
  const pendentSolicitationsInitialValues = { time: '', passenger: null }
  const pendentSolicitationsValidationSchema = Yup.object().shape({ time: Yup.string(), passenger: Yup.string() })
  const cooperative = user?.user?.user_permission?.cooperative;
  const user_id = user?.user_id;

  const { error, data, fetching, refresh } = useTrips(cooperative, user_id);

  return (
    <CooperativesProvider>
      <TripsContextProvider>
        <SolicitationsProvider>
          <div className={styles.pageHeadingContainer}>
            <h2 className={styles.pageHeading}>Solicitações</h2>
          </div>
          <div className={styles.contentContainer}>
            <Tabs
              defaultActiveKey="1"
              size="large"
              onTabClick={() => {
                refresh();
              }}
            >
              <TabPane tab="Solicitações em andamento" key="1">
                <Formik initialValues={pendentSolicitationsInitialValues}
                        validationSchema={pendentSolicitationsValidationSchema}
                        onSubmit={null}>
                  <PendentSolicitations />
                </Formik>
              </TabPane>
              <TabPane tab="Viagens enviadas" key="2">
                {error && <p>Erro ao listar viagens enviadas</p>}
                <Trips trips={data} fetching={fetching} refresh={refresh} />
              </TabPane>
              <TabPane tab={'Solicitações canceladas'} key={'3'}>
                <CancelledSolicitations />
              </TabPane>
            </Tabs>
          </div>
          <EditSolicitationModal />
        </SolicitationsProvider>
      </TripsContextProvider>
    </CooperativesProvider>
  );
}
