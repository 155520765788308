import { useQuery } from 'urql';
import gql from 'graphql-tag';

const TIME_QUERY = gql`
  query {
    time(order_by: { hour: asc }) {
      id
      hour
    }
  }
`;

export const useTimePicker = () => {
  const [result] = useQuery({
    query: TIME_QUERY,
  });

  if (result.fetching) return [];

  if (result.error) return [];

  const { time } = result.data;

  return time;
};
