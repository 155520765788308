import { LoadingOutlined } from '@ant-design/icons';
import { Modal, notification, Spin } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { monetize } from '../Monetizer/Monetizer';
import styles from './GroupSolicitations.module.css';
import { distribute } from './helpers/distribute';
import Trip from './Trip';
import { TripsContext } from './TripsContextProvider.jsx';
import {
  getAllOriginsAndDestinationsDistances,
  selectShortestKmRoute
} from "../Monetizer/helpers/getDistanceBetweenDestinations";
import { metersToKilometers } from "./helpers/routerize";
import { MAX_DISTANCE_TO_EXTREME_COST } from "../../../constants";
import { hasDifferentOrigins } from "../Monetizer/helpers/hasDifferentOrigins";
import { hasDifferentDestinations } from "../Monetizer/helpers/hasDifferentDestinations";

export default function GroupSolicitations({ visible, setVisible, refreshSolicitations }) {
  const { movedTrips, hasMovedTrips, setTrips, add_trips, loadingRouter, isLoadingDistances, setIsLoadingDistances } = useContext(TripsContext);
  const [loading, setLoading] = useState(false);
  const maxDistanceToExtremeCostInKm = useMemo(() => metersToKilometers(MAX_DISTANCE_TO_EXTREME_COST), []);

  const dispatchError = (message, description) => {
    notification['error']({
      message,
      description,
    });

    return;
  };

  const transformDistanceInTextKm = distance => `${metersToKilometers(distance).toFixed(distance === 0 ? 0 : 1)}km`

  const transformDistancesSolicitationsInObject = (distances, solicitations) =>
      distances.map((distance, index) =>
          ({ tripNumber: solicitations[index].number, text: transformDistanceInTextKm(distance) })
      )

  const notifyCalculateDistancesError = () => {
    notification.error({
      message: 'Erro ao calcular distâncias das viagens',
      description: 'Não foi possível calcular as distâncias das viagens selecionadas na roteirização.'
    })
  }

  const notifyExceededMaxDistanceToExtremeCostWarning = trip => {
    notification.warning({
      message: 'Alerta de viagem',
      description: `Viagem de número ${trip.number} com extremos de mais de ${maxDistanceToExtremeCostInKm}km`,
      duration: 8,
    });
  }

  const generateNextTrips = (currentTrip, previousTrips) =>
      previousTrips.map((previousTrip) => previousTrip.number === currentTrip.number ? currentTrip : previousTrip);

  const getResetTripWithOneSolicitation = trip => {
    const [firstSolicitation] = trip.solicitations;

    trip.exceededMaxDistanceToExtremeCost = false
    trip.isExtremeCost = false
    firstSolicitation.origin.distances = [];
    firstSolicitation.destination.distances = [];

    return trip
  }

  const getTripWithDistancesConfig = (trip, { originsDistances, destinationsDistances }) => {
    const isExtremeCost = !hasDifferentOrigins(trip) && !hasDifferentDestinations(trip)

    trip.isExtremeCost = isExtremeCost

    if (isExtremeCost){
      const maxDistance = Math.max(selectShortestKmRoute(originsDistances), selectShortestKmRoute(destinationsDistances));
      const exceededMaxDistanceToExtremeCost = maxDistance > MAX_DISTANCE_TO_EXTREME_COST

      trip.exceededMaxDistanceToExtremeCost = exceededMaxDistanceToExtremeCost

      if(exceededMaxDistanceToExtremeCost) notifyExceededMaxDistanceToExtremeCostWarning(trip)
    } else trip.exceededMaxDistanceToExtremeCost = false

    trip.solicitations = trip.solicitations.map((solicitation, index, solicitations) => {
      if(isExtremeCost){
        const originDistances = transformDistancesSolicitationsInObject(originsDistances[index], solicitations)
        const destinationDistances = transformDistancesSolicitationsInObject(destinationsDistances[index], solicitations)

        solicitation.origin.distances = originDistances
        solicitation.destination.distances = destinationDistances

        originDistances.splice(index, 1)
        destinationDistances.splice(index, 1)
      } else {
        solicitation.origin.distances = []
        solicitation.destination.distances = []
      }

      return solicitation
    })

    return trip
  }

  const calculateDistances = async () => {
    if(isLoadingDistances) return;

    try {
      setIsLoadingDistances(true);

      const trips = await distribute(movedTrips, dispatchError);

      for (let trip of trips) {
        if (trip.solicitations.length === 1) {
          setTrips(previousTrips => generateNextTrips(getResetTripWithOneSolicitation(trip), previousTrips))

          continue;
        }

        const distances = await getAllOriginsAndDestinationsDistances(trip)

        setTrips(previousTrips => generateNextTrips(getTripWithDistancesConfig(trip, distances), previousTrips))
      }
    } catch (error){
      console.error(error)
      notifyCalculateDistancesError()
    } finally {
      setIsLoadingDistances(false);
    }
  }

  useEffect(() => {
    if(!hasMovedTrips) return;

    calculateDistances()
  }, [movedTrips, hasMovedTrips])

  return (
    <Modal
      title="Roteirização de viagens"
      visible={visible}
      width={1120}
      maskClosable={false}
      onCancel={() => {
        setTrips([]);
        setVisible(false);
      }}
      okText={loading ? <LoadingOutlined style={{ fontSize: 20, color: 'white' }} spin /> : 'Confirmar'}
      onOk={async () => {
        if(loadingRouter || loading || !hasMovedTrips || isLoadingDistances) return

        setLoading(true);

        const trips = await distribute(movedTrips, dispatchError);
        const response = await monetize(trips, dispatchError);

        if (response?.error) {
          notification['error']({
            message: 'Erro ao enviar viagens',
            description: response.message || `Não foi possível atribuir um custo na viagem ${response.trip.number}. Verifique rotas duplicadas ou erradas em Configurações.`,
          });
          setLoading(false);
        } else {
          await add_trips(trips, response, setVisible, setLoading);
        }
      }}
    >
      <div className={styles.modalContainer}>
        {!loadingRouter ? (
          <>
            <p className={styles.instruction}>
              Confira as viagens propostas de acordo com as solicitações. Você pode modificá-las movendo uma solicitação para uma viagem existente ou criando
              uma nova
            </p>
            <h2 className={styles.title}>
              Viagens roteirizadas<span className={styles.count}>{movedTrips?.length} itens</span>
            </h2>
            <div>{movedTrips?.map((t, i) => !!t?.solicitations?.length && <Trip key={i} trip={t} tripIndex={i} />)}</div>
          </>
        ) : (
          <div className={styles.loading}>
            <Spin size="large" />
            <p>Roteirizando...</p>
          </div>
        )}

        {isLoadingDistances && <p className={styles.calculatingDistances}>Calculando distâncias...</p>}
      </div>
    </Modal>
  );
}
