import { Input, Modal, notification } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'urql';
import styles from './ConfigurationModal.module.css';

const LIMIT_PASSENGERS_MUTATION = gql`
  mutation($id: uuid!, $obj: configuration_set_input) {
    update_configuration(where: { id: { _eq: $id } }, _set: $obj) {
      affected_rows
    }
  }
`;

export default function PassengerLimitModal({ limitConfig, visible, setVisible }) {
  const [value, setValue] = useState(limitConfig?.value);

  const [, update_limit_passengers] = useMutation(LIMIT_PASSENGERS_MUTATION);

  const execute_mutation = () => {
    update_limit_passengers({ id: limitConfig?.id, obj: { value } }).then((e) => {
      if (e.data) {
        notification['success']({
          message: 'Limite alterado com sucesso',
          description: `A partir de agora, ${value} passageiros são permitidos`,
        });
        setVisible(false);
      } else {
        notification['error']({
          message: 'Falha ao alterar limite máximo',
          description: 'Contate o suporte.',
        });
        setVisible(false);
      }
    });
  };

  return (
    <Modal title="Atualizar limite de passageiros" destroyOnClose={true} visible={visible} onCancel={() => setVisible(false)} onOk={execute_mutation}>
      <div style={{ textAlign: 'center' }}>
        <p className={styles.instruction}>Insira o número máximo de pessoas para uma viagem</p>

        <p className={styles.costType}>LIMITE DE PASSAGEIROS</p>

        <Input
          onChange={(e) => setValue(Math.round(e.target.value).toFixed(0))}
          value={value}
          type="number"
          placeholder="Ex.: 4"
          addonAfter="pessoas"
          size="large"
          style={{ margin: '0.5rem 0', width: '50%' }}
        />
      </div>
    </Modal>
  );
}
