export const getGeocode = async address => {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN;
  const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${API_KEY}`);
  const data = await response.json();
  return data.status === 'OK' ? data.results[0] : null;
};

export const getFinalAddress = async address => {
  const geoCode = await getGeocode(address);

  if (geoCode) {
    const { lat: latitude, lng: longitude } = geoCode.geometry.location;
    let city = null;
    let state = null;
    let zipcode = null;
    let complement = null;

    geoCode.address_components.forEach(({ types, long_name: longName }) => {
      if (types.find(type => type === 'administrative_area_level_2')) city = longName;
      if (types.find(type => type === 'administrative_area_level_1')) state = longName;
      if (types.find(type => type === 'postal_code')) zipcode = longName;
      if (types.find(type => type === 'sublocality_level_1')) complement = longName;
    });

    return { latitude, longitude, description: geoCode.formatted_address, city, state, zipcode, complement };
  }

  return null;
};
