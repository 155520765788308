import React, { useContext } from 'react';
import { FaArrowRight, FaPlusCircle } from 'react-icons/fa';
import { LIMIT_PASSENGERS_SLUG } from '../../../constants';
import { ConfigurationContext } from '../../../contexts/ConfigurationContext';
import styles from './TripPopover.module.css';
import { TripsContext } from './TripsContextProvider';

export default function TripPopover({ index, solicitation, trip, tripIndex }) {
  const { movedTrips, move, newTrip } = useContext(TripsContext);
  const { configuration } = useContext(ConfigurationContext);

  const limit_passengers = configuration.find((config) => config.name === LIMIT_PASSENGERS_SLUG);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <p className={styles.title}>
          Mover solicitação <strong>{solicitation.number}</strong> para
        </p>
      </div>

      <div className={styles.list}>
        {movedTrips.map(
          (t, i) =>
            t.number !== trip.number &&
            t.solicitations.length < limit_passengers.value && (
              <div
                key={t.number}
                onClick={() => {
                  move(index, tripIndex, i);
                }}
                className={styles.listItemContainer}
              >
                <p className={styles.listItem}>
                  <span className={styles.number}>#{t.number}</span>Viagem
                </p>
                <FaArrowRight />
              </div>
            ),
        )}
      </div>

      <div className={styles.footer}>
        <div
          className={styles.newTrip}
          onClick={() => {
            newTrip(index, tripIndex);
          }}
        >
          <FaPlusCircle style={{ marginRight: '0.25rem' }} />
          Nova viagem
        </div>
      </div>
    </div>
  );
}
