import { BankFilled, BranchesOutlined, CarFilled, HomeFilled, PlusOutlined, ContactsOutlined, CalendarOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './css/Sidebar.module.css';
import { UserContext } from '../../contexts/UserContext';

export default function Sidebar() {
  const { user } = useContext(UserContext);
  const router = useLocation();

  const [collapsed, setCollapsed] = useState(false);

  const pathname = router.pathname;

  const user_permission = user.user?.user_permission;

  return (
    <Layout.Sider data-testid="menu" collapsible collapsed={collapsed} onCollapse={setCollapsed}>
      <div className={styles.buttonContainer}>
        {!collapsed ? (
          <Link to="/new-solicitation">
            <button className={styles.newRequest}>Nova solicitação</button>
          </Link>
        ) : (
          <Link to="/new-solicitation">
            <button className={styles.newRequest}>
              <PlusOutlined />
            </button>
          </Link>
        )}
      </div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={['/']} selectedKeys={[pathname]}>
        <Menu.Item style={{ backgroundColor: pathname === '/' && 'black' }} key="/">
          <Link style={{ color: pathname === '/' ? 'white' : 'black' }} to="/">
            <HomeFilled />
            <span>Página Inicial</span>
          </Link>
        </Menu.Item>
        <Menu.Item style={{ backgroundColor: pathname === '/trips' && 'black' }} key="/trips">
          <Link style={{ color: pathname === '/trips' ? 'white' : 'black' }} to="/trips">
            <CarFilled />
            <span>Histórico de Viagens</span>
          </Link>
        </Menu.Item>
        <Menu.Item style={{ backgroundColor: pathname === '/solicitations' && 'black' }} key="/solicitations">
          <Link style={{ color: pathname === '/solicitations' ? 'white' : 'black' }} to="/solicitations">
            <BranchesOutlined />
            <span>Solic. em Andamento</span>
          </Link>
        </Menu.Item>
        <Menu.Item style={{ backgroundColor: pathname === '/recurrent-solicitations' && 'black' }} key="/recurrent-solicitations">
          <Link style={{ color: pathname === '/recurrent-solicitations' ? 'white' : 'black' }} to="/recurrent-solicitations">
            <CalendarOutlined />
            <span>Solic. Recorrentes</span>
          </Link>
        </Menu.Item>
        {user_permission?.cruds && (
          <Menu.Item style={{ backgroundColor: pathname === '/cost-centers' && 'black' }} key="/cost-centers">
            <Link style={{ color: pathname === '/cost-centers' ? 'white' : 'black' }} to="/cost-centers">
              <BankFilled />
              <span>Centro de Custos</span>
            </Link>
          </Menu.Item>
        )}
        {user_permission?.cruds && (
          <Menu.Item style={{ backgroundColor: pathname === '/cooperatives' && 'black' }} key="/cooperatives">
            <Link style={{ color: pathname === '/cooperatives' ? 'white' : 'black' }} to="/cooperatives">
              <ContactsOutlined />
              <span>Cooperativas</span>
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </Layout.Sider>
  );
}
