import moment from "moment";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../../../helpers/date";
import { getRegistrationText } from "../../../helpers/messages";

export const transformCancelledSolicitationsToExcel = cancelledSolicitations => cancelledSolicitations.map(cancelledSolicitation => {
    const solicitationUser = cancelledSolicitation.user?.passenger
    const costCenter = cancelledSolicitation.cost_center

    return {
        number: cancelledSolicitation.number,
        createdDate: moment(cancelledSolicitation.created_at).format(DATE_TIME_FORMAT),
        tripDate: `${moment(cancelledSolicitation.trip_date).format(DATE_FORMAT)} (${cancelledSolicitation.time})`,
        entityName: cancelledSolicitation.passenger_name || cancelledSolicitation.entity_name,
        registration: getRegistrationText(cancelledSolicitation),
        origin: `${cancelledSolicitation.origin?.address} - ${cancelledSolicitation.origin_locality?.name}`,
        destination: `${cancelledSolicitation.destination?.address} - ${cancelledSolicitation.destination_locality?.name}`,
        costCenter: `${costCenter?.number} - ${costCenter?.description}`,
        solicitationUser: `${solicitationUser?.name} (${solicitationUser?.registration})`,
        reason: cancelledSolicitation.reason?.description,
        phone: cancelledSolicitation.phone,
        userWhoDeleted: cancelledSolicitation.user_who_deleted?.passenger?.name,
        observation: cancelledSolicitation.observation,
    }
})
