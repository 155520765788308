import { notification, Popconfirm } from 'antd';
import gql from 'graphql-tag';
import React, { useContext, useState } from 'react';
import { FaPen, FaPlusCircle, FaTimes } from 'react-icons/fa';
import { useMutation } from 'urql';
import CustomScrollbar from '../../components/CustomScrollbar';
import { ReasonsContext } from '../../contexts/ReasonsContext';
import styles from './List.module.css';
import ReasonsListModal from './ReasonsListModal';

const DELETE_REASON_MUTATION = gql`
  mutation($id: uuid!) {
    update_reason(where: { id: { _eq: $id } }, _set: { deleted_at: "now()" }) {
      affected_rows
    }
  }
`;

export default function ReasonsList() {
  const { reasons } = useContext(ReasonsContext);

  const [reasonsModal, setReasonsModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);

  const [, delete_reason] = useMutation(DELETE_REASON_MUTATION);

  const exclude_reason = (id) => {
    delete_reason({ id })
      .then((e) => {
        console.log(e);
        if (e.data) {
          notification['success']({
            message: 'Motivo deletado com sucesso',
            description: 'O histórico de solicitações com este motivo não foi alterado.',
          });
        } else {
          notification['error']({
            message: 'Erro ao deletar motivo',
            description: 'Contate o suporte.',
          });
        }
      })
      .catch((e) => console.error(e));
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>
          Motivos <span className={styles.counter}>{reasons.length || '-- '} itens</span>
        </h3>
        <FaPlusCircle className={styles.icon} onClick={() => setReasonsModal(true)} />
      </div>
      <div className={styles.listContainer}>
        <CustomScrollbar noScrollX noDefaultStyles>
          {reasons?.map((reason, index) => (
            <div key={index} className={styles.itemContainer}>
              <p className={styles.itemDescription}>{reason.description}</p>
              <div className={styles.containerIcon}>
                <FaPen
                  className={styles.icon}
                  onClick={() => {
                    setSelectedReason(reason);
                    setReasonsModal(true);
                  }}
                />
                <Popconfirm
                  title={
                    <div style={{ width: '300px' }}>
                      Você deseja remover este motivo da base? As solicitações com esse motivo <strong>não </strong>serão alteradas.
                    </div>
                  }
                  onConfirm={async () => {
                    exclude_reason(reason?.id);
                  }}
                >
                  <FaTimes className={styles.icon} />
                </Popconfirm>
              </div>
            </div>
          ))}
        </CustomScrollbar>
      </div>
      {reasonsModal && <ReasonsListModal reason={selectedReason} visible={reasonsModal} setVisible={setReasonsModal} />}
    </div>
  );
}
