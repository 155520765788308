import moment from 'moment';
import { generateSolicitationInfosModel } from "../../../helpers/generate-models";

export const generateExcelSolicitations = (solicitations: any) => {
  const format = solicitations.map((solicitation: any) => ({
    trip_date: moment(solicitation.trip_date).format('DD/MM/YY'),
    trip_time: solicitation.time,
    solicitation_number: solicitation.number,
    solicitant_name: solicitation?.user?.passenger?.name,
    solicitant_registration: solicitation?.user?.passenger?.registration,
    cost_center_number: solicitation.cost_center.number,
    cost_center_description: solicitation.cost_center.description,
    destination: solicitation.destination.address,
    destination_locality: solicitation.destination_locality.name,
    origin: solicitation.origin.address,
    origin_locality: solicitation.origin_locality.name,
    created_at: moment(solicitation?.created_at).format('DD/MM/YY'),
    reason: solicitation.reason.description,
    observation: solicitation?.observation || '',
    phone: solicitation?.phone,
    ...generateSolicitationInfosModel(solicitation),
  }));

  return format;
};
