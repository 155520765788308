import { devtoolsExchange } from '@urql/devtools';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { cacheExchange, createClient, dedupExchange, fetchExchange, ssrExchange, subscriptionExchange } from 'urql';
import { getAccessToken } from './helpers/auth';
import fetchOptionsExchange from './helpers/fetch-options-exchange';

async function getAuthParameters() {
  // get the authentication token from local storage if it exists
  const token = await getAccessToken();

  if (!token) {
    return {};
  }

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

const subscriptionClient = new SubscriptionClient(process.env.REACT_APP_WS_GRAPHQL_URL, {
  reconnect: true,
  lazy: true,
  connectionParams: getAuthParameters,
  connectionCallback: (err) => {
    if (err) subscriptionClient.close(false, false);
  },
});

const client = createClient({
  url: process.env.REACT_APP_GRAPHQL_URL,
  exchanges: [
    devtoolsExchange,
    dedupExchange,
    cacheExchange,
    ssrExchange(),
    // Must be called before fetchExchange and after all others sync exchanges, respecting the rule Synchronous first, Asynchronous last
    fetchOptionsExchange(async (fetchOptions) => {
      return Promise.resolve({
        ...fetchOptions,
        ...(await getAuthParameters()),
      });
    }),
    fetchExchange,
    subscriptionExchange({
      forwardSubscription: (operation) => subscriptionClient.request(operation),
    }),
  ],
});

export default client;
