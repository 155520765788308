import { Avatar } from 'antd';
import React, { useContext, useMemo, useState } from 'react';
import { FaPen, FaUser } from 'react-icons/fa';
import { ConfigurationContext } from '../../contexts/ConfigurationContext';
import styles from './Configuration.module.css';
import PassengerLimitModal from './PassengerLimitModal';

export default function PassengerLimit() {
  const { configuration } = useContext(ConfigurationContext);
  const [limitModal, setLimitModal] = useState(false);

  const limitConfig = useMemo(() => {
    return configuration.filter((config) => config.name === 'limit_passengers')[0];
  }, [configuration]);

  return (
    <div className={styles.containerItem}>
      <div className={styles.content}>
        <div className={styles.description}>
          <div className={styles.avatar}>
            <Avatar style={{ backgroundColor: '#FFA500' }} size="large" icon={<FaUser className={styles.dollar} />} />
          </div>
          <div>
            <h4 className={styles.infoPrimary}>Limite de passageiros</h4>
            <p className={styles.infoSecondary}>Quantidade máxima de pessoas por veículo</p>
          </div>
        </div>
        <div className={styles.value}>
          <p className={styles.parameter}>{limitConfig?.value || '--'}</p>
          <FaPen className={styles.pen} onClick={() => setLimitModal(true)} />
        </div>
      </div>
      {limitModal && <PassengerLimitModal visible={limitModal} setVisible={setLimitModal} limitConfig={limitConfig} />}
    </div>
  );
}
