import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { CONTINENTAL_ADDRESS, CONTINENTAL_ADDRESS_2 } from '../constants';

export const getAddress = async (address, selectedPassenger) => {
  const isHomeAddress = selectedPassenger?.address?.description === address;
  const isWorkAddress = address === (CONTINENTAL_ADDRESS || CONTINENTAL_ADDRESS_2);

  const geocodeAddress = await geocodeByAddress(address).then(async (results) => ({
    latLng: await getLatLng(results[0]),
    address: address,
    isHomeAddress,
    isWorkAddress,
  }));

  return geocodeAddress;
};
