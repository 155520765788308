export const QUERY_LOCALITIES = `
  query($origin: uuid!, $destination: uuid) {
    locality(where: { _or: [{ id: { _eq: $origin } }, { id: { _eq: $destination } }] }) {
      name
      id
    }
  }
`;

export const QUERY_ROUTE = `
  query($origin: String, $destination: String) {
    route(
      where: {
        _and: [
          { origin: { _ilike: $origin } }
          { destination: { _ilike: $destination } }
        ]
      }
    ) {
      id
      origin 
      destination
    }
  }
`;

export const QUERY_COST = `
  query($route_id: uuid, $cooperative_id: uuid) {
    cooperatives_route_cost(where: {route_id: {_eq: $route_id}, cooperative_id: {_eq: $cooperative_id}}) {
      cost
    }
  }
`;

export const QUERY_EXTRA_COST_VIA = `
query($cooperative_id: uuid!) {
  cooperatives_extra_cost(where: {cooperative_id: {_eq: $cooperative_id}, extra_cost_type: {name: {_eq: "VIA"}}}, order_by: {extra_cost_type: {name: asc}}) {
    extra_cost_type {
      id
    }
    id
    value
  }
} 
`;

export const QUERY_EXTRA_COST_EXTREMO = `
query($cooperative_id: uuid!) {
  cooperatives_extra_cost(where: {cooperative_id: {_eq: $cooperative_id}, extra_cost_type: {name: {_eq: "EXTREMO"}}}, order_by: {extra_cost_type: {name: asc}}) {
    extra_cost_type {
      id
    }
    id
    value
  }
} 
`;

export const MUTATION_COST = `
  mutation($objects: [cost_insert_input!]!) {
    insert_cost(objects: $objects) {
      returning {
        id
      }
    }
  }
`;
