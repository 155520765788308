import { Input, Modal, notification } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'urql';
import styles from './ConfigurationModal.module.css';
import { sanitizeTime } from './sanitizeTime';
import { validateTime } from './validateTime';

const ANTECEDENCE_TIME_MUTATION = gql`
  mutation($id: uuid!, $obj: configuration_set_input) {
    update_configuration(where: { id: { _eq: $id } }, _set: $obj) {
      affected_rows
    }
  }
`;

export default function AntecedenceTimeModal({ antecedenceConfig, visible, setVisible }) {
  const [value, setValue] = useState(antecedenceConfig?.value);

  const [, update_antecedence_passengers] = useMutation(ANTECEDENCE_TIME_MUTATION);

  const execute_mutation = () => {
    try {
      const time = sanitizeTime(value);

      if (validateTime(time)) {
        throw new Error('O horário configurado é inválido');
      }

      update_antecedence_passengers({ id: antecedenceConfig?.id, obj: { value } }).then((e) => {
        if (e.data) {
          notification['success']({
            message: 'Limite alterado com sucesso',
            description: `A partir de agora, é permitido ${value} hora(s) de antecedência para viagens`,
          });
          setVisible(false);
        } else {
          notification['error']({
            message: 'Falha ao alterar horas de antecedência',
            description: 'Contate o suporte.',
          });
          setVisible(false);
        }
      });
    } catch (e) {
      console.log(e);
      notification['error']({
        message: 'Erro ao alterar horário de antecedência',
        description: 'O horário configurado é inválido',
      });
    }
  };

  const onChangeInput = (e) => {
    const value = e.target.value;
    const formatValue = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1:$2')
      .replace(/(:\d{2})\d+?$/, '$1');

    setValue(formatValue);
  };

  return (
    <Modal title="Atualizar horas de antecedência" destroyOnClose={true} visible={visible} onCancel={() => setVisible(false)} onOk={execute_mutation}>
      <div style={{ textAlign: 'center' }}>
        <p className={styles.instruction}>Insira o tempo mínimo de antecedência para uma viagem</p>

        <p className={styles.costType}>TEMPO DE ANTECEDÊNCIA (hh:mm)</p>

        <Input onChange={onChangeInput} value={value} placeholder="Ex.: 01:30" size="large" style={{ margin: '0.5rem 0', width: '50%' }} />
      </div>
    </Modal>
  );
}
