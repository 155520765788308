import { notification, Popconfirm } from 'antd';
import gql from 'graphql-tag';
import React, { useContext, useState } from 'react';
import { FaPen, FaPlusCircle, FaTimes } from 'react-icons/fa';
import { useMutation } from 'urql';
import CustomScrollbar from '../../components/CustomScrollbar';
import { TimesContext } from '../../contexts/TimesContext';
import styles from './List.module.css';
import { sanitizeTimeObject } from './sanitizeTime';
import TimesListModal from './TimesListModal';

const DELETE_TIME_MUTATION = gql`
  mutation($id: uuid!) {
    delete_time(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export default function TimesList() {
  const { times } = useContext(TimesContext);

  const sanitizedTimes = times.map(sanitizeTimeObject);

  const [timesModal, setTimesModal] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);

  const [, delete_time] = useMutation(DELETE_TIME_MUTATION);

  const exclude_time = (id) => {
    delete_time({ id })
      .then((e) => {
        if (e.data) {
          notification['success']({
            message: 'Horário deletado com sucesso',
            description: 'O histórico de solicitações com este horário não foi alterado.',
          });
        } else {
          notification['error']({
            message: 'Erro ao deletar horário',
            description: 'Contate o suporte.',
          });
        }
      })
      .catch((e) => console.error(e));
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>
          Horários <span className={styles.counter}>{times.length || '-- '} itens</span>
        </h3>
        <FaPlusCircle className={styles.icon} onClick={() => setTimesModal(true)} />
      </div>
      <div className={styles.listContainer}>
        <CustomScrollbar noScrollX noDefaultStyles>
          {sanitizedTimes?.map((time, index) => (
            <div key={index} className={styles.itemContainer}>
              <p className={styles.itemDescription}>{time.formatted}</p>
              <div className={styles.containerIcon}>
                <FaPen
                  className={styles.icon}
                  onClick={() => {
                    setSelectedTime(time);
                    setTimesModal(true);
                  }}
                />
                <Popconfirm
                  title={
                    <div style={{ width: '300px' }}>
                      Você deseja remover este motivo da base? As solicitações com esse motivo <strong>não </strong>serão alteradas.
                    </div>
                  }
                  onConfirm={async () => {
                    exclude_time(time?.id);
                  }}
                >
                  <FaTimes className={styles.icon} />
                </Popconfirm>
              </div>
            </div>
          ))}
        </CustomScrollbar>
      </div>
      {timesModal && <TimesListModal time={selectedTime} visible={timesModal} setVisible={setTimesModal} setSelectedTime={setSelectedTime} />}
    </div>
  );
}
