import { hasIllegalSolicitations } from './hasIllegalSolicitations';

export const filterIllegalTrips = (trips) => {
  const illegalTrips = trips.filter((t) => {
    const solicitations = t.solicitations;

    return hasIllegalSolicitations(solicitations);
  });

  return illegalTrips;
};
