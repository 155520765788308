import React from 'react';
import CooperativeCostItem from './CooperativeCostItem';
import styled from 'styled-components';
import { groupBy } from 'lodash';

const Container = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 8px;
  display: inline-block;
  width: 320px;
`;

export default function CooperativeCost({ trips }) {
  const cooperativeTrips = Object.entries(groupBy(trips, 'cooperative.name'));

  return cooperativeTrips.map((cooperativeTripGroup, index) => (
    <Container key={index}>
      <CooperativeCostItem name={cooperativeTripGroup[0]} trips={cooperativeTripGroup[1]} />
    </Container>
  ));
}
