import { Button, Modal, notification } from 'antd';
import gql from 'graphql-tag';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'urql';
import styles from './EditSolicitationModal.module.css';
import { useSolicitationsContext } from "../../../providers/SolicitationsProvider";
import Locality from "../../NewSolicitation/components/Locality/Locality";
import { Formik, useFormikContext } from "formik";
import { LocalitiesContext } from "../../../contexts/LocalitiesContext";
import * as Yup from "yup";

const UPDATE_SOLICITATION_MUTATION = gql`
  mutation($id: uuid!, $solicitation: solicitation_set_input) {
    update_solicitation(where: { id: { _eq: $id } }, _set: $solicitation) { 
      returning {
        id
      }
    }
  }
`;

const Component = () => {
  const { editSolicitation, setEditSolicitation, showEditSolicitationModal, setShowEditSolicitationModal } = useSolicitationsContext()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, updateSolicitation] = useMutation(UPDATE_SOLICITATION_MUTATION);
  const { values: { originLocalityId, destinationLocalityId }, setValues, isValid } = useFormikContext();
  const isInvalid = !isValid;
  const { localities } = useContext(LocalitiesContext);
  const originLocality = localities.find(({ id }) => id === originLocalityId)
  const destinationLocality = localities.find(({ id }) => id === destinationLocalityId)

  const close = () => {
    setShowEditSolicitationModal(false)
    setEditSolicitation(null)
  }

  const submit = async (event) => {
    event.preventDefault()

    if(isSubmitting) return

    setIsSubmitting(true)

    try{
      if(isInvalid) throw new Error('Form error')

      const { data: { update_solicitation: { returning: [{ id }] } } } = await updateSolicitation({
        id: editSolicitation.id,
        solicitation: { origin_locality_id: originLocalityId, destination_locality_id: destinationLocalityId }
      })

      if(!id) throw new Error('Request edit error')

      close()
      notification.success({ message: 'Sucesso ao atualizar solicitação.' });
    } catch(error){
      console.error(error)
      notification.error({ message: 'Ocorreu um problema ao atualizar solicitação, reveja o formulário e tente novamente.' });
    } finally{
      setIsSubmitting(false)
    }
  };

  useEffect(() => {
    setValues({
      originLocalityId: editSolicitation?.originLocalityId ?? '',
      destinationLocalityId: editSolicitation?.destinationLocalityId ?? ''
    })
  }, [editSolicitation])

  return (
    <Modal
      destroyOnClose={true}
      visible={showEditSolicitationModal}
      onCancel={close}
      footer={null}
      title={!!editSolicitation ? `Editar solicitação: ${editSolicitation.number}` : ''}
      width={576}
    >
      <form onSubmit={submit} className={'form'}>
        <section className={`field ${styles.field}`}>
          <h4 className={styles.title}>Origem:</h4>
          <Locality initialLocalityId={originLocalityId}
                    nameLocality={'originLocalityId'}
                    locality={originLocality}
                    alertMessage={'Selecione a localidade mais próxima de sua origem'} />
        </section>

        <section className={`field ${styles.field}`}>
          <h4 className={styles.title}>Destino:</h4>
          <Locality initialLocalityId={destinationLocalityId}
                    nameLocality={'destinationLocalityId'}
                    locality={destinationLocality}
                    alertMessage={'Selecione a localidade mais próxima de seu destino'} />
        </section>

        <div className={styles.actions}>
          <Button htmlType={'button'} onClick={close}>Fechar</Button>
          <Button type="primary" htmlType={'submit'} loading={isSubmitting} disabled={isSubmitting || isInvalid}>Salvar</Button>
        </div>
      </form>
    </Modal>
  );
}

export const EditSolicitationModal = () => {
  const initialValues = {
    originLocalityId: '',
    destinationLocalityId: '',
  }

  const validationSchema = Yup.object().shape({
    originLocalityId: Yup.string().required(),
    destinationLocalityId: Yup.string().required(),
  })

  return (
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={null}
      >
        <Component />
      </Formik>
  )
}
