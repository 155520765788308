import { hasDifferentOrigins } from "../../Solicitations/Monetizer/helpers/hasDifferentOrigins";
import { hasDifferentDestinations } from "../../Solicitations/Monetizer/helpers/hasDifferentDestinations";
import { getRouteCost } from "../../Solicitations/Monetizer/helpers/getRouteCost";
import { getValuesOfSolicitations } from "../../Solicitations/Monetizer/helpers/getValuesOfSolicitations";
import { removeHigherCost } from "../../Solicitations/Monetizer/helpers/removeHigherCost";
import { simpleClient } from "../../../helpers/auth";
import { QUERY_EXTRA_COST_EXTREMO, QUERY_EXTRA_COST_VIA } from "../../Solicitations/Monetizer/helpers/queries";
import { BASE_COST, EXTREMO_COST, PARKING_COST, TIME_AVAILABLE_COST, VIA_COST } from "../../../constants";

const getPercentageValue = value => value / 100

const getCooperativeValues = async trips => {
    const cooperativeIds = [...new Set(trips.map(({ cooperative_id, cooperative }) => cooperative_id || cooperative?.id))]
    const cooperativeValues = {}

    for (const cooperativeId of cooperativeIds) {
        const params = { cooperative_id: cooperativeId }
        const { cooperatives_extra_cost: [{ value: extremeCost }] } = await simpleClient.request(QUERY_EXTRA_COST_EXTREMO, params);
        const { cooperatives_extra_cost: [{ value: viaCost }] } = await simpleClient.request(QUERY_EXTRA_COST_VIA, params);

        cooperativeValues[cooperativeId] = { extremeCost, viaCost }
    }

    return cooperativeValues
}

const getSolicitationCost = (costs, name, solicitationsLength) =>
    (costs.filter(cost => cost.name === name).reduce((accumulator, { value }) => accumulator + value, 0) / solicitationsLength)

const hasCost = (trip, name) => trip.costs.some(cost => cost.extra_cost_type?.name === name)

const fixedCost = cost => (Math.round(cost * 100) / 100).toFixed(2)

const getOtherCosts = trip =>
    trip.costs
        .filter(cost => cost.provenience === PARKING_COST || cost.extra_cost_type?.name === TIME_AVAILABLE_COST)
        .map(({ value, provenience }) => ({
            value,
            name: provenience === PARKING_COST ? PARKING_COST : TIME_AVAILABLE_COST
        }))

const getDifferenceCostSolicitations = (solicitations, recalculatedTrips) =>
    solicitations.map(trip => {
        const { totalCost, trip_number: tripNumber } = trip
        const { costs, solicitations: { length: solicitationsLength } } = recalculatedTrips.find(({ number }) => number === tripNumber)
        const currentTotalCost = costs.reduce((accumulator, { value }) => accumulator + value, 0) / solicitationsLength
        const currentExtremeCost = (costs.find(({ name }) => name === EXTREMO_COST)?.value ?? 0) / solicitationsLength;
        const currentViaCost = getSolicitationCost(costs, VIA_COST, solicitationsLength);
        const currentBaseCost = getSolicitationCost(costs, BASE_COST, solicitationsLength);
        const registration = trip.passenger_registration
        const passengerName = trip.passenger_name
        let entityName = trip.entity_name

        if (passengerName) {
            entityName = passengerName

            if (registration) entityName += ` (${registration})`
        }

        return {
            solicitationNumber: trip.solicitation_number,
            solicitant: trip.solicitant_name,
            entityName,
            costCenter: `${trip.cost_center_number} - ${trip.cost_center_description}`,
            tripNumber,
            tripDateTime: `${trip.trip_date} (${trip.trip_time})`,
            origin: trip.origin,
            originLocality: trip.origin_locality,
            destination: trip.destination,
            destinationLocality: trip.destination_locality,
            cooperative: trip.cooperative,
            baseCost: fixedCost(trip.baseCost),
            currentBaseCost: fixedCost(currentBaseCost),
            extremeCost: fixedCost(trip.extremCost),
            currentExtremeCost: fixedCost(currentExtremeCost),
            viaCost: fixedCost(trip.viaCost),
            currentViaCost: fixedCost(currentViaCost),
            parkingCost: fixedCost(trip.parkingCost),
            waitCost: fixedCost(trip.waitCost),
            totalCost: fixedCost(totalCost),
            currentTotalCost: fixedCost(currentTotalCost),
            differenceTotalCost: fixedCost(currentTotalCost - totalCost),
            status: trip.state,
        }
    })

export const generateDifferenceCostSolicitations = async (trips, solicitations, setEstimate) => {
    if (!trips) trips = []
    if (!solicitations) solicitations = []

    const recalculatedTrips = []
    const solicitationsLength = solicitations.length
    const cooperativeValues = await getCooperativeValues(trips)
    let initialEstimate = 0
    let finalEstimate = 0

    for (const trip of trips) {
        if (!initialEstimate) initialEstimate = performance.now()

        let costs = [{ value: 0, name: BASE_COST }];
        const isFromDifferentOrigins = hasDifferentOrigins(trip);
        const isFromDifferentDestinations = hasDifferentDestinations(trip);
        const cooperativeId = trip.cooperative_id || trip.cooperative?.id

        if (!isFromDifferentOrigins && !isFromDifferentDestinations) {
            const hasExtremeCost = hasCost(trip, EXTREMO_COST)
            const cost = await getRouteCost(trip);

            costs = hasExtremeCost
                ? [
                    { value: cost, name: BASE_COST },
                    { value: cost * getPercentageValue(cooperativeValues[cooperativeId].extremeCost), name: EXTREMO_COST }
                ]
                : [{ value: cost, name: BASE_COST }];
        } else if (isFromDifferentOrigins || isFromDifferentDestinations) {
            const hasViaCost = hasCost(trip, VIA_COST)
            const values = await getValuesOfSolicitations(trip);
            const maxValue = Math.max(...values);
            const viaCosts = removeHigherCost(values).map(cost => ({
                value: cost * (hasViaCost ? getPercentageValue(cooperativeValues[cooperativeId].viaCost) : 1),
                name: hasViaCost ? VIA_COST : BASE_COST
            }));

            costs = [{ value: maxValue, name: BASE_COST }, ...viaCosts];
        }

        costs.push(...getOtherCosts(trip))

        if (!finalEstimate) {
            finalEstimate = performance.now()

            setEstimate((finalEstimate - initialEstimate) * solicitationsLength)
        }

        recalculatedTrips.push({ ...trip, costs });
    }

    return getDifferenceCostSolicitations(solicitations, recalculatedTrips)
};
