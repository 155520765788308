import moment from 'moment';

export const validateLastSolicitations = (values, solicitations) => {
  const departureDate = moment(values.trip_date).format('YYYY-MM-DD');
  const departureTime = values.time;

  console.log(departureDate, departureTime);

  console.log('last solicitations:', solicitations);

  const equals = solicitations.filter((s) => s.trip_date === departureDate && s.time === departureTime);

  console.log(equals);

  return !!equals.length;
};
