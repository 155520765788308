import gql from 'graphql-tag';
import React from 'react';
import { useSubscription } from 'urql';

export const RoutesContext = React.createContext();

const ROUTES_SUBSCRIPTION = gql`
  subscription {
    route(order_by: { number: asc }, where: { deleted_at: { _is_null: true } }) {
      id
      number
      origin
      destination
    }
  }
`;

const handleRoutesSub = (routes = [], response) => {
  return response.route;
};

const useRoutes = () => {
  const [result] = useSubscription(
    {
      query: ROUTES_SUBSCRIPTION,
    },
    handleRoutesSub,
  );

  return result.data || [];
};

export default function RoutesProvider(props) {
  const routes = useRoutes();

  return (
    <RoutesContext.Provider
      value={{
        routes,
      }}
    >
      {props.children}
    </RoutesContext.Provider>
  );
}
