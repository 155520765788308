import gql from 'graphql-tag';
import { groupBy } from 'lodash';
import moment from 'moment';
import { useQuery } from 'urql';
import { ICostCenter, ITrip } from '../../types/types';

const TRIPS_SUBSCRIPTION = gql`
  query($endDate: date, $startDate: date, $cost_center_list: [uuid!]) {
    solicitation(
      where: {
        trip_date: { _gte: $startDate, _lte: $endDate }
        deleted_at: { _is_null: true }
        cost_center_id: { _in: $cost_center_list }
        state: { _eq: "sent" }
      }
      order_by: { trip_date: asc, time: asc }
    ) {
      id
      number
      observation
      trip_id
      type
      time
      phone
      passenger_name
      entity_name
      state
      trip_date
      origin
      destination
      passenger {
        id
        registration
      }
      origin_locality {
        id
        name
      }
      destination_locality {
        id
        name
      }
      cost_center {
        description
        id
        number
      }
      reason {
        id
        description
      }
      user {
        passenger {
          name
          id
        }
      }
      trip {
        id
        number
        state
        trip_date
        time
        approved_at
        charged_at
        deleted_at
        costs {
          id
          value
          provenience
          amount
          extra_cost_type {
            id
            description
            name
            type
          }
        }
        cooperative {
          id
          name
        }
      }
    }
  }
`;

interface IResponse {
  data: ITrip[];
  [key: string]: any;
}

export const useTrips = (startDate: Date, endDate: Date, cost_center_list: ICostCenter[]) => {
  const [result] = useQuery({
    query: TRIPS_SUBSCRIPTION,
    requestPolicy: 'cache-and-network',
    variables: {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      cost_center_list: cost_center_list.map(costCenter => costCenter.id),
    },
  });

  if (result.error) return { ...result, data: [], error: 'Erro ao retornar as viagens' };

  if (!result.data) return { ...result, data: [] };

  const solicitations = result.data?.solicitation;

  const groupedTrips = groupBy(solicitations, 'trip_id');

  const trips = Object.entries(groupedTrips)
    .map(entry => {
      const solicitations = entry[1];

      const trip = solicitations[0].trip;

      return {
        ...trip,
        solicitations,
      };
    })
    .filter(({ deleted_at, approved_at, charged_at }) => !deleted_at && approved_at && charged_at);

  return { ...result, data: trips } as IResponse;
};
