export default function (recurrenceConfig, values) {
  const dates = recurrenceConfig.dates;
  const type = recurrenceConfig.recurrenceType;

  const solicitations = dates.map((date) => {
    return {
      ...values,
      trip_date: date,
    };
  });

  const recurrentSolicitation = {
    passenger_id: values.passenger_id,
    recurrence_config: recurrenceConfig,
    type,
    solicitations: {
      data: solicitations,
    },
  };

  return recurrentSolicitation;
}
