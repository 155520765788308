import { Button, Input, Modal, notification, Select } from 'antd';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'urql';
import styles from './EditUserModal.module.css';
import { REQUIRED_FIELD_MESSAGE } from "../../../constants";

const { Option } = Select;

const UPDATE_PASSENGER_MUTATION = gql`
  mutation($id: uuid!, $passenger: passenger_set_input) {
    update_passenger(where: { id: { _eq: $id } }, _set: $passenger) { 
      returning {
        id
      }
    }
  }
`;

export default function EditUserModal({ user, setUser, isVisible, setIsVisible, costCenters }){
  const [submitting, setSubmitting] = useState(false);
  const [, updatePassenger] = useMutation(UPDATE_PASSENGER_MUTATION);
  const [newName, setNewName] = useState('');
  const [newRegistration, setNewRegistration] = useState('');
  const [newCostCenterId, setNewCostCenterId] = useState('');

  const close = () => {
    setIsVisible(false)
    setUser(null)
  }

  const submit = async event => {
    event.preventDefault()

    if(submitting) return

    setSubmitting(true)

    try{
      if(!newName.trim() || !newRegistration.trim() || !newCostCenterId.trim())
        throw new Error('Form edit error')

      const { data: { update_passenger: { returning: [{ id: currentId }] } } } = await updatePassenger({
        id: user.id,
        passenger: { name: newName, registration: newRegistration, cost_center_id: newCostCenterId }
      })

      if(!currentId) throw new Error('Request edit error')

      close()
      notification.success({ message: 'Sucesso ao atualizar usuário.' });
    } catch(error){
      console.error(error)
      notification.error({
        message: 'Ocorreu um problema ao atualizar usuário, reveja o formulário e tente novamente.',
        description: 'Matrícula pode estar já sendo usada.'
      });
    } finally{
      setSubmitting(false)
    }
  };

  useEffect(() => {
    setNewName(user?.name)
    setNewRegistration(user?.registration)
    setNewCostCenterId(user?.costCenterId)
  }, [user])

  return (
    <Modal
      destroyOnClose={true}
      visible={isVisible}
      onCancel={close}
      footer={null}
      title={user ? `Editar usuário: ${user.name}` : ''}
      width={800}
    >
      <form onSubmit={submit} className={'form'}>
        <div className={styles.infoFields}>
          <div className={'field'}>
            <label htmlFor={'fieldName'}>Nome:</label>
            <Input
                id={'fieldName'}
                type="text"
                placeholder="Coloque o nome"
                size="large"
                value={newName}
                maxLength={200}
                required={true}
                onChange={event => setNewName(event.currentTarget.value)}
            />
            <span className={'hint'}>{REQUIRED_FIELD_MESSAGE}</span>
          </div>

          <div className={'field'}>
            <label htmlFor={'fieldRegistration'}>Matrícula:</label>
            <Input
                id={'fieldRegistration'}
                type="number"
                placeholder="Coloque a matrícula"
                size="large"
                value={newRegistration}
                maxLength={10}
                required={true}
                onChange={event => setNewRegistration(event.currentTarget.value)}
            />
            <span className={'hint'}>{REQUIRED_FIELD_MESSAGE}</span>
          </div>
        </div>

        <div className={'field'}>
          <label htmlFor={'fieldCostCenter'}>Centro de custo:</label>
          <Select
              id={'fieldCostCenter'}
              placeholder="Escolha o centro de custo"
              value={newCostCenterId}
              size={'large'}
              onChange={value => setNewCostCenterId(value)}
          >
            {
              costCenters.map(({ id, ...costCenter }) => (
                <Option key={id} value={id}>
                  {costCenter.number} - {costCenter.description}
                </Option>
              ))
            }
          </Select>
          <span className={'hint'}>{REQUIRED_FIELD_MESSAGE}</span>
        </div>

        <div className={styles.actions}>
          <Button htmlType={'button'} onClick={close}>Fechar</Button>
          <Button type="primary" htmlType={'submit'} loading={submitting} disabled={submitting}>Salvar</Button>
        </div>
      </form>
    </Modal>
  );
}
