import { ClockCircleOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React from 'react';
import styles from './TimePicker.module.css';

export default function TimePicker(props) {
  const { time, setTime } = props;

  const onChangeInput = (e) => {
    const value = e.target.value;
    const formatValue = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1:$2')
      .replace(/(:\d{2})\d+?$/, '$1');

    setTime(formatValue);
  };

  return (
    <div>
      <div className={styles.inputContainer}>
        <Input value={time} onChange={onChangeInput} prefix={<ClockCircleOutlined style={{ color: '#909090' }} />} />
      </div>
    </div>
  );
}
