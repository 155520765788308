import { LockFilled } from '@ant-design/icons';
import { Input, message, Modal } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'urql';
import styles from './CreateUserModal.module.css';

const INSERT_USER_MUTATION = gql`
  mutation($email: String!, $password: String!) {
    auth_register(email: $email, password: $password) {
      affected_rows
    }
  }
`;

const SOLICITANT_MUTATION = gql`
  mutation($id: uuid!) {
    update_passenger(where: { id: { _eq: $id } }, _set: { is_solicitant: false }) {
      affected_rows
      returning {
        is_solicitant
      }
    }
  }
`;

export default function CreateUserModal({ passenger, visible, setVisible }) {
  const [password, setPassword] = useState('');

  const [, insert_user] = useMutation(INSERT_USER_MUTATION);
  const [, update_solicitant] = useMutation(SOLICITANT_MUTATION);

  const mutate_solicitant = () => {
    update_solicitant({ id: passenger.id })
      .then((e) => {
        console.log(e);
        setVisible(false);
        message.warning('Usuário não criado');
      })
      .catch((e) => console.log(e));
  };

  const createUser = () => {
    insert_user({ email: passenger.registration, password })
      .then((e) => {
        if (e.data) {
          message.success('Usuário criado com sucesso');
          setVisible(false);
        } else {
          message.error('Erro ao criar usuário');
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <Modal title="Criar um usuário" destroyOnClose={true} visible={visible} onCancel={mutate_solicitant} onOk={createUser}>
      <div style={{ textAlign: 'center' }}>
        <p className={styles.instruction}>Para este colaborador tornar-se um solicitante, você precisa criar o seu usuário fornecendo-lhe uma senha</p>
        <label style={{ color: '#909090' }}>Colaborador</label>
        <h2 className={styles.passengerName}>{passenger.name || '--'}</h2>
        <h5 className={styles.registration}>
          <strong>Matrícula: </strong>
          {passenger.registration || '--'}
        </h5>
        <Input
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Insira a senha do novo usuário"
          prefix={<LockFilled style={{ color: 'rgba(0,0,0,.25)' }} />}
          size="large"
          style={{ marginBottom: '1rem', width: '80%' }}
        />
      </div>
    </Modal>
  );
}
