import React, { useState } from 'react';
import { Modal, Input } from 'antd';

import styles from './ChargeTripModal.module.css';

export default function ChargeModal({ visible, setVisible, charge, keys, singleKey, setSingleKey }) {
  const [chargeInput, setCharge] = useState('');
  const [buttonDisable, setButtonDisable] = useState(true);
  const [error, setError] = useState(false);

  const onChange = (e) => {
    const value = e.target.value;
    setCharge(value);
    setButtonDisable(true);

    if (value === 'faturar') {
      setError(true);
    }

    if (value === 'FATURAR') {
      setButtonDisable(false);
      setError(false);
    }
  };

  const onClick = () => {
    singleKey.length ? charge(singleKey) : charge(keys);
    setSingleKey([]);
    setVisible(false);
    setCharge('');
  };

  const onCancel = () => {
    setSingleKey([]);
    setVisible(false);
  };

  return (
    <Modal title="Faturar as viagens" destroyOnClose={true} visible={visible} onCancel={onCancel} footer={null}>
      <div style={{ textAlign: 'center' }}>
        <h3 className={styles.question}>Você gostaria de faturar as viagens?</h3>
        <p className={styles.instruction}>
          Esta ação é <strong>permanente</strong>
        </p>
        <p className={styles.number}>{keys.length} selecionada(s)</p>
        <p className={styles.typingInstruction}>
          Digite <span className={styles.charge}>FATURAR</span> abaixo caso queira confirmar esta ação
        </p>
        <div className={styles.typingContainer}>
          <Input className={styles.typing} onChange={onChange} value={chargeInput} />
        </div>
        {error && <span className={styles.error}>Digite em CAPSLOCK</span>}
        <button disabled={buttonDisable} className={styles.chargeButton} onClick={onClick}>
          Sim, desejo faturar
        </button>
      </div>
    </Modal>
  );
}
