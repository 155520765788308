import React, { useState } from 'react';
import { Avatar } from 'antd';
import { FaDollarSign, FaPen } from 'react-icons/fa';
import styles from './ExtraCost.module.css';
import ExtraCostModal from './ExtraCostModal';

export default function ExtraCost({ cost }) {
  const [costModal, setCostModal] = useState(false);
  const { extra_cost_type, value } = cost;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.description}>
          <div className={styles.avatar}>
            <Avatar style={{ backgroundColor: '#FFA500' }} size="large" icon={<FaDollarSign className={styles.dollar} />} />
          </div>
          <div>
            <h4 className={styles.infoPrimary}>{extra_cost_type?.name || '-'}</h4>
            <p className={styles.infoSecondary}>{extra_cost_type?.description || '-'}</p>
          </div>
        </div>
        <div className={styles.value}>
          {extra_cost_type?.type !== 'fixed' ? (
            <p className={styles.cost}>
              {value || '0 '}
              <span className={styles.percentage}>%</span>
            </p>
          ) : (
            <p className={styles.cost}>R$ {value || ' 00,00'}</p>
          )}
          <FaPen className={styles.pen} onClick={() => setCostModal(true)} />
        </div>
      </div>
      {costModal && <ExtraCostModal extraCost={cost} visible={costModal} setVisible={setCostModal} />}
    </div>
  );
}
