import React, { useContext } from 'react';
import { Select } from 'antd';

import styles from './Reason.module.css';
import { ReasonsContext } from '../../../../contexts/ReasonsContext';

const { Option } = Select;

export default function Reason(props) {
  const onChange = (value) => {
    const { name, setFieldValue } = props;

    setFieldValue(name, value);
  };

  const { reasons } = useContext(ReasonsContext);

  return (
    <>
      <label className={styles.label}>{props.label} *</label>
      <div className={styles.inputContainer}>
        <Select style={{ width: '100%' }} defaultValue={props.value} placeholder="Selecione o motivo" onChange={onChange}>
          {reasons.map(({ id, description }) => (
            <Option key={id} value={id}>
              {description}
            </Option>
          ))}
        </Select>
      </div>
    </>
  );
}
