import { Col, Row, Table, Tooltip } from 'antd';
import Popconfirm from 'antd/es/popconfirm';
import gql from 'graphql-tag';
import React, { useContext, useState } from 'react';
import { FaArrowLeft, FaExclamationTriangle, FaTimes } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from 'urql';
import { RoutesContext } from '../../../contexts/RoutesContext';
import { CNPJMask } from '../InputCNPJ';
import styles from './EditCooperative.module.css';
import ExtraCost from './ExtraCost';
import ImportCosts from './ImportCosts';
import { useCooperative } from './useCooperative';
import { useExtraCosts } from './useExtraCosts';
import { useRoutesCosts } from './useRoutesCosts';

const DELETE_COSTS_MUTATION = gql`
  mutation($id: uuid!) {
    delete_cooperatives_route_cost(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export default function EditCooperative() {
  const { id } = useParams();
  const history = useHistory();
  const cooperative = useCooperative(id);
  const routesCost = useRoutesCosts(id);
  const extraCosts = useExtraCosts(id);
  const { routes } = useContext(RoutesContext);

  const [modalCosts, setModalCosts] = useState(false);
  const [, delete_costs] = useMutation(DELETE_COSTS_MUTATION);

  const remove_costs = (id) => {
    delete_costs({ id })
      .then((e) => console.log(e))
      .catch((e) => console.error(e));
  };

  const columns = [
    {
      title: 'Número',
      render: (text, record) => record.route.number,
      sorter: {
        compare: (a, b) => Number(a.route.number) > Number(b.route.number),
      },
      width: 100,
    },
    {
      title: 'Origem',
      render: (text, record) => record.route.origin,
      sorter: {
        compare: (a, b) => {
          if (a.route.origin > b.route.origin) {
            return 1;
          }

          if (a.route.origin < b.route.origin) {
            return -1;
          }
        },
      },
    },
    {
      title: 'Destino',
      render: (text, record) => record.route.destination,
      sorter: {
        compare: (a, b) => {
          if (a.route.ordestinationigin > b.route.destination) {
            return 1;
          }

          if (a.route.destination < b.route.destination) {
            return -1;
          }
        },
      },
    },
    {
      title: 'Custo (R$)',
      render: (text, record) =>
        record.cost || (
          <Tooltip title="Importe a planilha de Custos de Rota com este valor" placement="topLeft">
            <div className={styles.iconContainer}>
              <FaExclamationTriangle className={styles.danger} />
              <p style={{ color: 'red' }}>Não informado</p>
            </div>
          </Tooltip>
        ),
      sorter: {
        compare: (a, b) => Number(a.cost) > Number(b.cost),
      },
    },
    {
      title: 'Excluir',
      render: (text, record) => (
        <Popconfirm title="Você tem certeza que deseja excluir essa rota?" okText="Sim" cancelText="Não" onConfirm={() => remove_costs(record.id)}>
          <Tooltip placement="top" title="Excluir rota">
            <FaTimes style={{ cursor: 'pointer' }} className={styles.icon} />
          </Tooltip>
        </Popconfirm>
      ),
    },
  ];

  const routesWithoutCost = routesCost.filter(({ cost }) => cost === null);

  return (
    <div>
      <div className={styles.pageHeadingContainer}>
        <h2 className={styles.pageHeading}>
          <FaArrowLeft onClick={() => history.goBack()} className={styles.arrowIcon} />
          {cooperative.name} <span className={styles.CNPJ}>CNPJ: {CNPJMask(cooperative.CNPJ || '')}</span>
        </h2>
      </div>
      <Row gutter={48}>
        <Col span={16}>
          <h2 className={styles.title}>
            Custos de Rota <span className={styles.tableCounter}>{routesCost.length} itens</span>
          </h2>
          {!!routesWithoutCost.length && (
            <Tooltip title="Importe a planilha de Custos de Rota com este valor" placement="topLeft">
              <div className={styles.withoutCostCounter}>
                <FaExclamationTriangle className={styles.danger} />
                <p style={{ color: 'red' }}>{routesWithoutCost.length} rotas sem custo</p>
              </div>
            </Tooltip>
          )}

          <div style={{ marginTop: '1rem' }}>
            <Table rowKey="id" columns={columns} dataSource={routesCost} scroll={{ y: 350 }} pagination={false} />
          </div>
          <div className={styles.buttonContainer}>
            <button onClick={() => setModalCosts(true)} className={styles.primaryButton}>
              Importar
            </button>
          </div>
        </Col>
        <Col span={8}>
          <h2 className={styles.title}>Custos extras</h2>
          {extraCosts.map((c) => (
            <ExtraCost key={c.id} cost={c} />
          ))}
        </Col>
      </Row>
      {modalCosts && <ImportCosts costs={routesCost} routes={routes} cooperative={cooperative} visible={modalCosts} setVisible={setModalCosts} />}{' '}
    </div>
  );
}
