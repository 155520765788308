import React from 'react';
import styled from 'styled-components';

const Loading = styled.div`
  margin-top: 1rem;
  min-height: 500px;
  font-weight: bold;
`;

export default function LoadingForm(props) {
  return <div>{props.loading ? <Loading>Carregando...</Loading> : props.children}</div>;
}
