import { simpleClient } from '../../../helpers/auth';
import { getExtremExtraCost } from './helpers/getExtremExtraCost';
import { getRouteCost } from './helpers/getRouteCost';
import { getValuesOfSolicitations } from './helpers/getValuesOfSolicitations';
import { getViaExtraCost } from './helpers/getViaExtraCost';
import { hasDifferentDestinations } from './helpers/hasDifferentDestinations';
import { hasDifferentOrigins } from './helpers/hasDifferentOrigins';
import { MUTATION_COST } from './helpers/queries';
import { removeHigherCost } from './helpers/removeHigherCost';

export const monetize = async (trips, dispatchError) => {
  const cost_ids = [];

  try {
    for (let t of trips) {
      if (!hasDifferentOrigins(t) && !hasDifferentDestinations(t)) {
        const cost = await getRouteCost(t);
        const extremCost = await getExtremExtraCost(t);

        if(extremCost?.error) return extremCost

        const variableCostValue = extremCost
          ? { objects: [{ value: cost }, { value: (extremCost.value / 100) * cost, extra_cost_type_id: extremCost.extraCostTypeId }] }
          : { objects: [{ value: cost }] };
        const { insert_cost } = await simpleClient.request(MUTATION_COST, variableCostValue);

        cost_ids.push(insert_cost.returning.map(({ id }) => id));
      } else if (hasDifferentDestinations(t) || hasDifferentOrigins(t)) {
        // TEM NECESSARIAMENTE CUSTO DE VIA ENTÃO CALCULA-SE CUSTO DA ROTA DE TODAS AS SOLICITAÇÕES
        const costs = await getValuesOfSolicitations(t);
        const { viaExtraCostValue, extraCostTypeId } = await getViaExtraCost(t);

        const maxBaseCost = Math.max(...costs);
        const viaCosts = removeHigherCost(costs).map((cost) => ({
          value: cost * (viaExtraCostValue / 100),
          extra_cost_type_id: extraCostTypeId,
        }));

        // CRIA CUSTOS COM VIA SEM VIAGEM ASSOCIADA
        const variableCostValue = {
          objects: [{ value: maxBaseCost }, ...viaCosts],
        };
        const { insert_cost } = await simpleClient.request(MUTATION_COST, variableCostValue);
        cost_ids.push(insert_cost.returning.map(({ id }) => id));
      }
    }
  } catch (error) {
    return error;
  }

  if (!cost_ids.length) {
    if(dispatchError) dispatchError('Erro ao monetizar as viagens', 'O custo gerado não é compatível com o custo tabelado, contate o suporte.');

    return;
  }

  return cost_ids;
};
