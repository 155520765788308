import { groupBy, sortBy } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useDashboardContext } from '../../contexts/DashboardContext';
import { Subtitle } from '../Subtitle/Subtitle';
import TripsChartCanvas from './TripsChartCanvas';

const Container = styled.div`
  padding: 1rem;
  border-radius: 8px;
  height: 57vh;
  margin-right: 0.5rem;
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.75rem;
`;

const TripsChart = () => {
  const { formatTrips } = useDashboardContext();
  const groupedTrips = groupBy(formatTrips, trip => trip.passenger_name || trip.entity_name);
  const data = Object.entries(groupedTrips).map((group) => ({
    name: group[0],
    Custo: group[1].map((datum: any) => datum.cost).reduce((sum: number, cost: number) => sum + cost, 0),
  }));

  const orderedData = sortBy(data, 'Custo').reverse().slice(0, 5);

  return (
    <Container>
      <Title>Custo x Passageiro ou Material</Title>
      <Subtitle>Confira o gasto do top 5 de Passageiros ou Materiais do período selecionado</Subtitle>
      <TripsChartCanvas data={orderedData} />
    </Container>
  );
};

export default TripsChart;
