import React from 'react';
import { useDashboardContext } from '../../../contexts/DashboardContext';
import Card from '../Card';

interface Props {}

const TripsCard = (props: Props) => {
  const { trips } = useDashboardContext();

  return <Card title="Viagens" value={trips.length} />;
};

export default TripsCard;
