import React from 'react';
import { Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Label = styled.label`
  font-weight: bold;
`;

const { Option } = Select;

function FilterPassenger({ passenger, setPassenger, passengers }) {
  return (
    <div>
      <Label>Colaborador</Label>
      <br />
      <Select
        style={{ marginTop: '0.5rem', width: '250px' }}
        showSearch={true}
        placeholder="Digite o nome do passageiro"
        value={passenger?.name}
        allowClear={true}
        suffixIcon={<SearchOutlined type="search" />}
        onChange={(_, option) => {
          setPassenger(option?.key ?? null);
        }}
      >
        {passengers.map((p) => (
          <Option key={p.id} value={`${p.name} ${p.registration}`} passenger={p}>
            {p.name}
            <br />
            <span style={{ fontWeight: 'bold' }}>Matrícula: </span>
            {p.registration}
          </Option>
        ))}
      </Select>
    </div>
  );
}

export default FilterPassenger;
