import gql from 'graphql-tag';
import { groupBy } from 'lodash';
import { useQuery } from 'urql';
import moment from 'moment';

const TRIPS_SUBSCRIPTION = gql`
  query($date: date, $userId: uuid) {
    solicitation(
      where: { trip_date: { _gte: $date }, deleted_at: { _is_null: true }, state: { _eq: "sent" }, solicitant_id: { _eq: $userId } }
      order_by: { trip_date: desc, time: asc }
    ) {
      id
      number
      observation
      trip_id
      type
      time
      phone
      passenger_name
      entity_name
      state
      trip_date
      created_at
      origin
      destination
      passenger {
        id
        registration
      }
      origin_locality {
        id
        name
      }
      destination_locality {
        id
        name
      }
      cost_center {
        description
        id
        number
      }
      reason {
        id
        description
      }
      user {
        passenger {
          name
          id
        }
      }
      trip {
        id
        number
        state
        trip_date
        time
        approved_at
        charged_at
        deleted_at
        costs {
          id
          value
          provenience
          amount
          extra_cost_type {
            id
            description
            name
            type
          }
        }
        cooperative {
          id
          name
        }
      }
    }
  }
`;

const handleTripsSub = (solicitation = [], response) => {
  return response.trip;
};

export default function (cooperative, user_id) {
  const userId = !cooperative ? user_id : null;

  const [result, reexecuteQuery] = useQuery(
    {
      query: TRIPS_SUBSCRIPTION,
      requestPolicy: 'cache-and-network',
      variables: { date: moment().startOf('day').toISOString(), userId },
    },
    handleTripsSub,
  );

  const refresh = () => {
    // Refetch the query and skip the cache
    reexecuteQuery({ requestPolicy: 'network-only' });
  };

  if (result.error) return { ...result, refresh };

  if (!result.data) return { ...result, refresh };

  const solicitations = result.data?.solicitation;

  const groupedTrips = groupBy(solicitations, 'trip_id');

  const trips = Object.entries(groupedTrips)
    .map((entry) => {
      const solicitations = entry[1];

      const trip = solicitations[0].trip;

      return {
        ...trip,
        solicitations,
      };
    })
    .filter(({ deleted_at }) => !deleted_at);

  return { ...result, data: trips, refresh };
}
