import React from "react";
import styles from "./TripDistances.module.css";

export const tripDistancesTypes = {
    origins: {
        title: "Distância(s) da(s) outra(s) origen(s) em relação a essa"
    },
    destinations: {
        title: "Distância(s) do(s) outro(s) destino(s) em relação a esse"
    },
}

export const TripDistances = ({ distances, type }) => {
    if(!distances || distances.length === 0) return null

    return (
        <section className={styles.container}>
            <h3 className={styles.title}>{type.title}:</h3>
            <ul className={styles.list}>
                {
                    distances.map((distance, index) =>
                        (<li key={index}><strong>{distance.tripNumber}:</strong> {distance.text}</li>)
                    )
                }
            </ul>
        </section>
    )
}
