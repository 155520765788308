import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Link, useHistory } from 'react-router-dom';
import { deleteToken, deleteUser, deleteRefreshToken } from '../../helpers/auth';
import styles from './css/ConfigPopover.module.css';

export default function ConfigPopover({ passenger, setPasswordModal }) {
  const { name, registration } = passenger;
  const { is_admin } = passenger?.user?.user_permission;
  const history = useHistory();

  const splitName = name.split(' ');

  return (
    <>
      <div className={styles.configContainer}>
        <Grid>
          <Row start="xs">
            <Col>
              <Avatar size={64} icon={<UserOutlined />} />
            </Col>
            <Col>
              <div className={styles.configUserInfo}>
                <h3 className={styles.configUserName}>{`${splitName[0]} ${splitName[1] ?? ''}`}</h3>
                <h4 className={styles.configUserRole}>{is_admin ? 'Administrador' : 'Usuário'}</h4>
                <h4>
                  <strong>Matrícula: </strong> {registration || '--'}
                </h4>
              </div>
            </Col>
          </Row>

          {is_admin && (
            <>
              <p className={styles.configManageLabel}>Gerenciamento dos módulos</p>
              <ul className={styles.configList}>
                <li>
                  <Link to="/control-panel">
                    <UserOutlined className={styles.configIcon} />
                    <span className={styles.configItem}>Usuários</span>
                  </Link>
                </li>

                <li
                  onClick={() => {
                    history.push('/configuration');
                  }}
                >
                  <SettingOutlined className={styles.configIcon} />
                  <span className={styles.configItem}>Configurações</span>
                </li>
              </ul>
            </>
          )}
          {
            <div className={styles.actionContainer}>
              <ul className={styles.configList}>
                <li
                  onClick={() => {
                    setPasswordModal(true);
                  }}
                >
                  <span className={styles.configItem}>Alterar senha</span>
                </li>
              </ul>
            </div>
          }
          <div className={styles.actionContainer}>
            <button
              className={styles.logoutButton}
              onClick={() => {
                deleteUser();
                deleteToken();
                deleteRefreshToken();
                history.push('/');
                window.location.reload();
              }}
            >
              <LogoutOutlined className={styles.logoutIcon} />
              Sair
            </button>
          </div>
        </Grid>
      </div>
    </>
  );
}
