import gql from 'graphql-tag';
import React from 'react';
import { useSubscription } from 'urql';

export const TimesContext = React.createContext();

const TIMES_SUBSCRIPTION = gql`
  subscription {
    time(order_by: { hour: asc }) {
      id
      hour
    }
  }
`;

const handleTimesSub = (times = [], response) => {
  return response.time;
};

const useTimes = () => {
  const [result] = useSubscription(
    {
      query: TIMES_SUBSCRIPTION,
    },
    handleTimesSub,
  );

  return result.data || [];
};

export default function TimesProvider(props) {
  const times = useTimes();

  return (
    <TimesContext.Provider
      value={{
        times,
      }}
    >
      {props.children}
    </TimesContext.Provider>
  );
}
