import { Input } from 'antd';
import React, { useMemo } from 'react';
import styles from './Observation.module.css';
import { uniqueId } from "lodash";

const { TextArea } = Input;

export default function Observation({ label, placeholder, required, ...props }) {
  const id = useMemo(() => `observation${uniqueId()}`, []);
  const onChange = (e) => {
    const value = e.target.value;
    const { name, setFieldValue } = props;

    setFieldValue(name, value ?? '');
  };

  return (
    <>
      <label htmlFor={id} className={styles.label}>{label}</label>
      <div className={styles.inputContainer}>
        <TextArea id={id} placeholder={placeholder} rows={4} onChange={onChange} value={props.value} required={required} />
      </div>
    </>
  );
}
