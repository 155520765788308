import { UploadOutlined } from '@ant-design/icons';
import { message, Modal, notification, Steps } from 'antd';
import gql from 'graphql-tag';
import { find } from 'lodash';
import React, { useContext, useState } from 'react';
import { useMutation } from 'urql';
import XLSX from 'xlsx';
import { LocalitiesContext } from '../../../contexts/LocalitiesContext.jsx';
import { getGeocode } from '../geoCode.js';
import delay from './delay.js';
import styles from './ImportModal.module.css';
import UpdateAddresses from './UpdateAddresses';

const Step = Steps.Step;

const UPDATE_ADDRESSES = gql`
  mutation($objects: [passenger_insert_input!]!) {
    insert_passenger(objects: $objects, on_conflict: { constraint: passenger_registration_key, update_columns: [address_id] }) {
      affected_rows
    }
  }
`;

export default function ImportAddressModal({ passengers, costCenters, visible, setVisible, setImporting }) {
  const [current, setCurrent] = useState(0);
  const [importedWorkers, setImportWorkers] = useState([]);
  const [updatedAddresses, setAddresses] = useState([]);
  const [imported, setImported] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState('');
  const [, update_addresses] = useMutation(UPDATE_ADDRESSES);

  const { localities } = useContext(LocalitiesContext);

  const substitute_addresses = async (objs) => {
    update_addresses({ objects: objs })
      .then((e) => {
        console.log(e);
      })
      .catch((e) => console.error(e));
  };

  const onImportExcel = (event) => {
    const { files } = event.target;
    if (files.length === 1) {
      processFileContent(files[0]);
    }
  };

  const extractWorkers = async (item) => {
    return {
      registration: item['B'].toString(),
      cost_center_number: item['C'].toString(),
      cost_center_description: item['D'],
      name: item['E'],
      city: item['H'],
      address: `${item['F']} ${item['G']} ${item['H']}`,
    };
  };

  const processFileContent = async (file) => {
    const fileReader = new FileReader();
    fileReader.onload = async (event) => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        const first_sheet_name = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[first_sheet_name];
        const data = XLSX.utils.sheet_to_json(worksheet, {
          header: 'A',
          raw: true,
          defval: null,
        });

        const workersPromise = data.map(extractWorkers);
        const workers = await Promise.all(workersPromise);

        setFile(file.name);
        setImportWorkers(workers);
        message.success('Arquivo carregado com sucesso!');
      } catch (e) {
        message.error('Erro com o formato da planilha');
      }
    };
    fileReader.readAsBinaryString(file);
  };

  const next = () => {
    setCurrent((c) => c + 1);
  };

  const prev = () => {
    setCurrent((c) => c - 1);
  };

  const getAddress = async (worker) => {
    const geoCode = await getGeocode(worker.address);

    let latitude = (await geoCode) && geoCode.geometry.location.lat;
    let longitude = (await geoCode) && geoCode.geometry.location.lng;
    let description = '';
    let address_components;
    let city;
    let state;
    let zipcode = null;
    let complement = null;

    if (geoCode) {
      description = geoCode['formatted_address'];
      address_components = geoCode['address_components'];
      address_components.forEach((el) => {
        const types = el.types;
        if (types.find((t) => t === 'administrative_area_level_2')) {
          city = el.long_name;
        }
        if (types.find((t) => t === 'administrative_area_level_1')) {
          state = el.long_name;
        }
        if (types.find((t) => t === 'postal_code')) {
          zipcode = el.long_name;
        }
        if (types.find((t) => t === 'sublocality_level_1')) {
          complement = el.long_name;
        }
      });

      return { latitude, longitude, description, city, state, zipcode, complement };
    }

    return null;
  };

  const updateAddresses = async () => {
    setLoading(true);
    try {
      for (let [i, worker] of updatedAddresses.entries()) {
        await delay(5);

        const address = await getAddress(worker);

        let locality_id = find(localities, (o) => o.name.toLowerCase().trim() === address?.city?.toLowerCase())?.id;
        let cost_center_id = find(costCenters, (o) => o.number === worker.cost_center_number)?.id;

        const passenger = {
          name: worker.name,
          registration: worker.registration,
          cost_center_id,
          locality_id,
          address: { data: address },
        };

        await substitute_addresses([passenger]);
        setImported(i + 1);
      }
    } catch (error) {
      notification['error']({
        message: 'Erro ao criar passageiro proveniente da planilha',
        description: error.message,
      });
    }
  };

  const saveImport = async () => {
    setImporting(true);
    await updateAddresses();
    setLoading(false);
    setVisible(false);
    setImporting(false);
    notification['success']({
      message: 'Importação realizada com sucesso',
      description: 'Não esqueça de conferir os passageiros sem localidade definida!',
    });
  };

  const steps = [
    {
      title: 'Importar planilha',
      content: (
        <>
          <input id="file" name="file" onChange={onImportExcel} className={styles.fileUploader} type="file" accept=".xlsx, .xls" />
          <label htmlFor="file">
            <UploadOutlined className={styles.uploadIcon} />
            Escolha um arquivo
          </label>
          <p>{file}</p>
        </>
      ),
    },
    {
      title: 'Atualizar endereços',
      content: <UpdateAddresses currentWorkers={passengers} importedWorkers={importedWorkers} setAddresses={setAddresses} />,
    },
  ];

  return (
    <Modal
      destroyOnClose={true}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      footer={null}
      title="Importar endereços"
      width={1200}
    >
      <div className={styles.modalContainer}>
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title}></Step>
          ))}
        </Steps>
        <div className={styles.modalContent}>
          {loading ? (
            <div>
              <h1>Importando...</h1>
              <p>{imported ? `${imported}/${updatedAddresses.length}` : 'Pendente'}</p>
            </div>
          ) : (
            steps[current].content
          )}
        </div>
        <div className={styles.modalActions}>
          {current > 0 && (
            <button className={styles.prevButton} onClick={() => prev()}>
              Anterior
            </button>
          )}
          {current < steps.length - 1 && (
            <button className={styles.nextButton} onClick={() => next()}>
              Próximo
            </button>
          )}
          {current === steps.length - 1 && (
            <button onClick={saveImport} className={styles.nextButton}>
              Salvar
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}
