import React from 'react';

import SolicitationItem from '../SolicitationItem/SolicitationItem';
import styles from './ExpandedRow.module.css';

export default function ExpandedRow({ trip }) {
  return (
    <div>
      <p className={styles.instruction}>Solicitações desta viagem</p>
      {trip.solicitations.map((s) => (
        <SolicitationItem key={s.id} solicitation={s} />
      ))}
    </div>
  );
}
