import React from 'react';
import styled from 'styled-components';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const Label = styled.label`
  font-weight: bold;
  display: block;
`;

const StyledRangePicker = styled(RangePicker)`
  margin: 0.5rem 1rem 0 0;
`;

function CustomRangePicker(props) {
  return (
    <div>
      <Label>Criada no período de:</Label>
      <StyledRangePicker {...props} allowClear={false} />
    </div>
  );
}

export default CustomRangePicker;
