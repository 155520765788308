import { simpleClient } from '../../../../helpers/auth';
import { QUERY_EXTRA_COST_VIA } from './queries';

export const getViaExtraCost = async (trip) => {
  const variablesExtraCost = { cooperative_id: trip.cooperative_id || trip.cooperative?.id };
  const { cooperatives_extra_cost } = await simpleClient.request(QUERY_EXTRA_COST_VIA, variablesExtraCost);

  if (!cooperatives_extra_cost.length) return 0;

  const viaExtraCostValue = cooperatives_extra_cost[0].value || 0;
  const extraCostTypeId = cooperatives_extra_cost[0]?.extra_cost_type?.id;

  if (!extraCostTypeId) {
    throw Error({ error: true, trip });
  }

  return { viaExtraCostValue, extraCostTypeId };
};
