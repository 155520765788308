import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import styles from './Locality.module.css';
import LocalityPopover from './LocalityPopover';

export default function Locality(props) {
  return (
    <div>
      <Dropdown trigger="click" overlay={<LocalityPopover nameLocality={props.nameLocality} />}>
        <p className={styles.locality}>
          Localidade:
          <span className={styles.localityName}>
            {props.locality?.name ?? (
              <span>
                <FaExclamationTriangle className={styles.dangerIcon} />
                Não especificado
              </span>
            )}
            <DownOutlined className={styles.arrowIcon} />
          </span>
        </p>
      </Dropdown>
      {!!props.alertMessage ? <p className={styles.alertMessage}><strong>Muita atenção:</strong> {props.alertMessage}</p> : null}
    </div>
  );
}
