import { GraphQLClient } from 'graphql-request';
import jwtDecode from 'jwt-decode';

export const getToken = () => localStorage.getItem(AUTH_TOKEN);
export const setToken = (token) => localStorage.setItem(AUTH_TOKEN, token);
export const deleteToken = () => localStorage.removeItem(AUTH_TOKEN);

export const getRefreshToken = () => localStorage.getItem(AUTH_REFRESH_TOKEN);
export const setRefreshToken = (token) => localStorage.setItem(AUTH_REFRESH_TOKEN, token);
export const deleteRefreshToken = () => localStorage.removeItem(AUTH_REFRESH_TOKEN);

export const getUser = () => localStorage.getItem(USER_ID);
export const setUser = (user_id) => localStorage.setItem(USER_ID, user_id);
export const deleteUser = () => localStorage.removeItem(USER_ID);

const AUTH_TOKEN = '@conti-taxi/auth-token';
const AUTH_REFRESH_TOKEN = '@conti-taxi/auth-refresh-token';
const USER_ID = '@conti-taxi/user-id';

const MUTATION_REFRESH_TOKEN = `
  mutation refreshToken($refresh_token: String!) {
    auth_refresh_token(refresh_token: $refresh_token) {
      access_token
      refresh_token
      user_id
    }
  }
`;

export let simpleClient = null;

const initSimpleClient = () => {
  simpleClient = new GraphQLClient(process.env.REACT_APP_GRAPHQL_URL, getAuthorizationParams())
}

initSimpleClient()

export const isTokenExpired = () => {
  const token = getToken();

  if (token) {
    const decoded = jwtDecode(token);
    const isExpired = new Date().getTime() / 1000 > decoded.exp;

    console.log('isExpired:', isExpired);
    return isExpired;
  }

  return true;
};

let isRefreshingToken = false

export const refreshToken = async () => {
  const currentToken = getToken();

  if(!currentToken) return ''
  if(isRefreshingToken) return currentToken

  isRefreshingToken = true

  try {
    if (isTokenExpired()) {
      throw new Error('Sessão expirada');
    }

    const token = getRefreshToken();
    const response = await simpleClient.request(MUTATION_REFRESH_TOKEN, { refresh_token: token });

    const { access_token, refresh_token, user_id } = response.auth_refresh_token;

    setToken(access_token);
    setRefreshToken(refresh_token);
    setUser(user_id);

    return access_token;
  } catch (e) {
    localStorage.clear();
    window.location.replace('/login');

    return ''
  } finally {
    isRefreshingToken = false
    initSimpleClient()
  }
};

export function getAuthorizationParams() {
  const token = getToken();

  if (!token) {
    return {};
  }

  let params = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  return params;
}

let _refreshingAccessTokenPromise;

export function getAccessToken() {
  if (_refreshingAccessTokenPromise) return _refreshingAccessTokenPromise;

  const accessToken = getToken();

  if (!accessToken) return void 0;

  const payload = jwtDecode(accessToken);

  if (Number(payload['exp']) > Date.now() / 1000) return accessToken;

  _refreshingAccessTokenPromise = new Promise(async (resolve, reject) => {
    try {
      const newAccessToken = await refreshToken();
      resolve(newAccessToken);
    } catch (e) {
      reject(e);
    } finally {
      _refreshingAccessTokenPromise = void 0;
    }
  });

  return _refreshingAccessTokenPromise;
}
