import { Input } from 'antd';
import { useFormikContext } from 'formik';
import React, { useContext, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { FaBriefcase, FaHome } from 'react-icons/fa';
import { LocalitiesContext } from '../../../../contexts/LocalitiesContext';
import { inferLocality } from '../../../../helpers/inferLocality';
import { CONTINENTAL_ADDRESS } from '../../constants';
import { RadioContext } from '../../NewSolicitation';
import Locality from '../Locality/Locality';
import styles from './AddressAutocomplete.module.css';

export default function AddressAutocomplete(props) {
  const { name, nameLocality, initialLocalityId, passengerLocality } = props;
  const { initialValues, values, setFieldValue } = useFormikContext();
  const { localities, contiLocality } = useContext(LocalitiesContext);
  const radio = useContext(RadioContext);

  const [isHome, setIsHome] = useState(false);

  const locality =
    initialLocalityId && !isHome ? localities.find((l) => l.id === initialValues[nameLocality]) : localities.find((l) => l.id === values[nameLocality]);

  return (
    <>
      <label className={styles.label}>{props.label} *</label>
      <div className={styles.inputContainer}>
        <GooglePlacesAutocomplete
          {...props}
          autocompletionRequest={{
            componentRestrictions: {
              country: 'br',
            },
            location: { lat: -12.6877156, lng: -38.2699941 },
            radius: 600000,
          }}
          loader={
            <div className={styles.suggestionsContainer}>
              <div className={styles.suggestionsLoader}>Carregando...</div>
            </div>
          }
          renderInput={(props) => {
            return <Input {...props} value={props.value ? props.value : props.value === null ? values[name] : ''} />;
          }}
          renderSuggestions={(active, suggestions, onSelectSuggestion) => (
            <div className={styles.suggestionsContainer}>
              <div className={styles.suggestionsContent}>
                <>
                  {radio === 'worker' && (
                    <>
                      <div
                        onClick={(event) => {
                          onSelectSuggestion(props.address, event);
                          setFieldValue(name, props.address.description);
                          setFieldValue(nameLocality, passengerLocality?.id);
                          setIsHome(true);
                        }}
                        className={styles.suggestion}
                      >
                        <FaHome className={styles.icon} />
                        Casa
                      </div>
                      <div
                        onClick={async (event) => {
                          onSelectSuggestion({ description: CONTINENTAL_ADDRESS }, event);
                          setFieldValue(name, CONTINENTAL_ADDRESS);
                          setFieldValue(nameLocality, contiLocality.id);
                        }}
                        className={styles.suggestion}
                      >
                        <FaBriefcase className={styles.icon} />
                        Trabalho
                      </div>
                    </>
                  )}
                  {suggestions.map((suggestion) => {
                    return (
                      <div
                        key={suggestion.place_id}
                        className={styles.suggestion}
                        onClick={async (event) => {
                          setFieldValue(name, suggestion.description);
                          onSelectSuggestion(suggestion, event);

                          const locality = await inferLocality(suggestion.description, localities);
                          // setFieldValue(nameLocality, locality?.id);
                          setIsHome(false);
                        }}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </>
              </div>
            </div>
          )}
        />
        <Locality initialLocalityId={props.initialLocalityId} nameLocality={props.nameLocality} locality={locality} alertMessage={props.alertMessage} />
      </div>
    </>
  );
}
