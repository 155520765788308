import moment from 'moment';

export default function generateDailyWorkDays(startAt, endAt) {
  const end = moment(endAt);
  const endIndex = end.diff(moment(startAt), 'days') + 1;

  const dates = [];
  for (let i = 0; i <= endIndex; i++) {
    const start = moment(startAt);
    let recurrentDay = start.add(i, 'day');

    if (recurrentDay.isoWeekday() !== 6 && recurrentDay.isoWeekday() !== 7) {
      dates.push(recurrentDay);
    }
  }

  return dates;
}
