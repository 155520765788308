import { SettingFilled } from '@ant-design/icons';
import { Dropdown, Layout } from 'antd';
import React, { useContext, useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { UserContext } from '../../contexts/UserContext';
import ChangePasswordModal from '../../pages/ControlPanel/ChangePasswordModal';
import conti from './assets/conti-yellow.png';
import ConfigPopover from './ConfigPopover';
import styles from './css/Header.module.css';

export default function Header() {
  const { user } = useContext(UserContext);
  const [passwordModal, setPasswordModal] = useState(false);

  return (
    <>
      <Layout.Header style={{ backgroundColor: '#000', padding: '0 20px' }}>
        <Grid fluid>
          <Row between="xs">
            <Col>
              <div className={styles.logoContainer}>
                <img className={styles.logo} src={conti} alt="Logo" />
                <span className={styles.headerTitle}>Sistema de Táxi</span>
              </div>
            </Col>
            <Col>
              <Dropdown overlay={<ConfigPopover passenger={user} setPasswordModal={setPasswordModal} />} overlayStyle={{ zIndex: 0 }} placement="bottomRight">
                <SettingFilled style={{ color: '#fff', cursor: 'pointer' }} />
              </Dropdown>
            </Col>
          </Row>
        </Grid>
        {passwordModal && <ChangePasswordModal passenger={user} visible={passwordModal} setVisible={setPasswordModal} />}
      </Layout.Header>
    </>
  );
}
