import { Button, notification, Popconfirm, Table, Tag, Tooltip } from 'antd';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useContext } from 'react';
import { FaExclamationTriangle, FaRedoAlt, FaRegArrowAltCircleLeft } from 'react-icons/fa';
import styled from 'styled-components';
import { useMutation } from 'urql';
import ExpandedRow from '../../../components/ExpandedRow/ExpandedRow';
import { UserContext } from '../../../contexts/UserContext';
import styles from './Trips.module.css';

const DELETE_TRIP_MUTATION = gql`
  mutation($id: uuid!) {
    update_trip(where: { id: { _eq: $id } }, _set: { deleted_at: "now()" }) {
      affected_rows
      returning {
        solicitations {
          id
          number
          passenger_name
          trip_date
          time
          phone
          state
          created_at
          observation
          cost_center {
            id
            description
            number
          }
          destination
          destination_locality {
            id
            name
          }
          origin
          origin_locality {
            id
            name
          }
          passenger {
            name
            id
            registration
          }
          reason {
            id
            description
          }
          user {
            passenger {
              name
              registration
              id
            }
          }
        }
      }
    }
    update_solicitation(where: { trip_id: { _eq: $id } }, _set: { state: "pendent" }) {
      affected_rows
      returning {
        id
      }
    }
    delete_cost(where: { trip_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const Refused = styled.p`
  padding-top: 0.25rem;
  font-size: 12px;
  color: gray;
`;

const Refresh = styled.button`
  display: flex;
  align-items: center;
  column-gap: var(--half-space);
  color: #4e99c5;
  background-color: white;
  border: 1px solid white;
  font-size: 14px;
  cursor: pointer;
  margin: 0 0 var(--default-space) 0;
`;

export default function Trips({ trips, fetching, refresh }) {
  const [, delete_trip] = useMutation(DELETE_TRIP_MUTATION);
  const { user } = useContext(UserContext);
  const user_permission = user?.user?.user_permission;
  const actionsTitle = 'Ações'

  const exclude_trip = (id) => {
    delete_trip({ id })
      .then((e) => {
        console.log(e);
        if (e.data) {
          notification['success']({
            message: 'Viagem revertida',
            description:
              'Essa viagem foi revertida com sucesso e não estará mais visível para as cooperativas. As solicitações retornam para em andamento',
          });
        } else {
          notification['error']({
            message: 'Erro ao reverter a viagem',
            description: 'O erro pode ter sido causado por instabilidade na rede. Tente novamente e depois contate o suporte',
          });
        }
      })
      .catch((e) => console.error(e));
  };

  const columns = [
    {
      title: 'Nº Ordem de serviço',
      dataIndex: 'number',
    },
    {
      title: 'Data da viagem',
      render: (text, { trip_date, time }) => `${moment(trip_date).format('DD/MM/YY')} às ${time}`,
    },
    {
      title: 'Qtd. de pessoas',
      render: (text, record) => record.solicitations.length,
    },
    {
      title: 'Cooperativa',
      render: (text, record) => record.cooperative.name,
    },
    {
      title: 'Status (cooperativa)',
      render: (text, record) => {
        if (record.state === 'pendent') {
          return <Tag color="#007cc2">Pendente</Tag>;
        } else if (record.state === 'denied') {
          return (
            <>
              <Tag color="#007cc2">Pendente</Tag>
              <Refused>
                <FaExclamationTriangle style={{ color: 'ffbb00', marginRight: '0.5rem' }} />
                Recusada pela cooperativa anterior
              </Refused>
            </>
          );
        } else if (record.state === 'confirmed') {
          return <Tag color="#87d068">Confirmada</Tag>;
        }
      },
    },
    {
      title: actionsTitle,
      render: (text, record) => {
        const revertTripText = `Reverter viagem: ${record.number}`

        return (
          <Popconfirm title="Você tem certeza que deseja reverter essa viagem para em andamento?" placement={'topRight'} okText="Sim" cancelText="Não" onConfirm={() => exclude_trip(record.id)}>
            <Tooltip placement={'top'} title={revertTripText}>
              <Button type={'text'}
                      className={styles.actionButton}
                      aria-label={revertTripText}>
                <FaRegArrowAltCircleLeft aria-hidden={true} className={styles.icon} />
              </Button>
            </Tooltip>
          </Popconfirm>
        )
      },
    },
  ];

  return (
    <div>
      <p className={styles.instruction}>
        Acompanhe na lista abaixo o <i>status</i> de cada viagem enviada para as cooperativas a partir de <strong>HOJE</strong>
      </p>
      <Refresh onClick={() => refresh()}>
        <FaRedoAlt /> Atualizar
      </Refresh>
      <Table
        rowKey="id"
        className={styles.table}
        loading={fetching}
        columns={user_permission?.cooperative ? columns : columns.filter(({ title }) => title !== actionsTitle)}
        dataSource={trips}
        expandedRowRender={(record) => <ExpandedRow trip={record} />}
      />
    </div>
  );
}
