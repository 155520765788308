import gql from 'graphql-tag';
import React from 'react';
import { useSubscription } from 'urql';

export const ConfigurationContext = React.createContext();

const CONFIGURATION_SUBSCRIPTION = gql`
  subscription {
    configuration {
      id
      name
      value
    }
  }
`;

const handleConfigurationSub = (configuration = [], response) => {
  return response.configuration;
};

export const useConfiguration = () => {
  const [result] = useSubscription(
    {
      query: CONFIGURATION_SUBSCRIPTION,
    },
    handleConfigurationSub,
  );

  return result.data || [];
};

export default function ConfigurationProvider(props) {
  const configuration = useConfiguration();

  return (
    <ConfigurationContext.Provider
      value={{
        configuration,
      }}
    >
      {props.children}
    </ConfigurationContext.Provider>
  );
}
