import gql from 'graphql-tag';
import React from 'react';
import { useSubscription } from 'urql';

export const CooperativesContext = React.createContext();

const COOPERATIVES_SUBSCRIPTION = gql`
  subscription {
    cooperatives(order_by: { name: asc }, where: { deleted_at: { _is_null: true } }) {
      id
      name
      CNPJ
      proportion
    }
  }
`;

const handleCooperativesSub = (cooperative = [], response) => {
  return response.cooperatives;
};

export const useCooperatives = () => {
  const [result] = useSubscription(
    {
      query: COOPERATIVES_SUBSCRIPTION,
    },
    handleCooperativesSub,
  );

  return result.data || [];
};

export default function CooperativesProvider(props) {
  const cooperatives = useCooperatives();

  return (
    <CooperativesContext.Provider
      value={{
        cooperatives,
      }}
    >
      {props.children}
    </CooperativesContext.Provider>
  );
}
