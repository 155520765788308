import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Input, notification } from 'antd';
import { useFormik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useMutation } from 'urql';
import { setRefreshToken, setToken, setUser } from '../../helpers/auth';
import logo from './assets/logo.svg';
import styles from './Login.module.css';

const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    auth_login(email: $email, password: $password) {
      access_token
      refresh_token
      user_id
    }
  }
`;

export default function Login() {
  const [, executeMutation] = useMutation(LOGIN_MUTATION);

  const wrongCredentialsError = 'Invalid "email" or "password"';

  const mutate = React.useCallback(
    ({ email, password }, setSubmitting) => {
      executeMutation({ email, password }).then(async ({ data, error }) => {
        if (error) {
          notification['error']({
            message: 'Erro ao fazer login',
            description: error?.graphQLErrors[0]?.message === wrongCredentialsError ? 'Usuário ou senha inválidos' : 'Erro desconhecido. Contate o suporte',
          });
          setSubmitting(false);
          return;
        }

        if (!data?.auth_login) {
          notification['error']({
            message: 'Erro desconhecido',
            description: 'Contate o suporte',
          });
          setSubmitting(false);
          return;
        }

        const { access_token, refresh_token, user_id } = data.auth_login;

        if (access_token) {
          setToken(access_token);
          setRefreshToken(refresh_token);
          setUser(user_id);
          window.location.replace('/');
        }
      });
    },
    [executeMutation],
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values, { setSubmitting }) => {
      mutate(values, setSubmitting);
    },
  });

  return (
    <div className={styles.centerScreen}>
      <div className={styles.formContainer}>
        <div className={styles.logoContainer}>
          <img alt="Logo" className={styles.logoImage} src={logo} />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Input
            onChange={formik.handleChange}
            name="email"
            placeholder="Matrícula"
            prefix={<UserOutlined type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            size="large"
            style={{ marginBottom: '1rem' }}
          />
          <br />
          <Input.Password
            onChange={formik.handleChange}
            name="password"
            placeholder="Senha"
            prefix={<LockOutlined type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            size="large"
            style={{ marginBottom: '1rem' }}
          />
          <button className={styles.buttonLogin} type="submit">
            <strong>{formik.isSubmitting ? 'Entrando...' : 'Entrar'}</strong>
          </button>
        </form>
      </div>
    </div>
  );
}
