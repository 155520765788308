import { Input, message, Modal } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'urql';
import styles from './ExtraCostModal.module.css';

const COST_MUTATION = gql`
  mutation($id: uuid!, $obj: cooperatives_extra_cost_set_input) {
    update_cooperatives_extra_cost(where: { id: { _eq: $id } }, _set: $obj) {
      affected_rows
    }
  }
`;

export default function ExtraCostModal({ extraCost, visible, setVisible }) {
  const [value, setValue] = useState(extraCost.value);
  const { extra_cost_type } = extraCost;

  const [, update_cost] = useMutation(COST_MUTATION);

  const mutateCost = () => {
    update_cost({ id: extraCost.id, obj: { value } })
      .then((e) => {
        console.log(e);
        if (e.data) {
          message.success('Valor de custo extra atualizado');
        } else {
          message.error('Erro ao atualizar valor');
        }

        setVisible(false);
      })
      .catch((e) => console.log(e));
  };

  console.log(extraCost);

  return (
    <Modal title="Atualizar valor de custo extra" destroyOnClose={true} visible={visible} onCancel={() => setVisible(false)} onOk={mutateCost}>
      <div style={{ textAlign: 'center' }}>
        <p className={styles.instruction}>Insira o valor desse custo extra para esta cooperativa</p>

        <p className={styles.costType}>{extraCost?.extra_cost_type.name}</p>

        {extra_cost_type.type === 'fixed' ? (
          <Input
            onChange={(e) => setValue(e.target.value)}
            value={value}
            type="number"
            placeholder="00.00"
            addonBefore="R$"
            size="large"
            style={{ margin: '0.5rem 0', width: '50%' }}
          />
        ) : (
          <Input
            onChange={(e) => setValue(e.target.value)}
            value={value}
            type="number"
            placeholder="00.00"
            addonAfter="%"
            size="large"
            style={{ margin: '0.5rem 0', width: '50%' }}
          />
        )}
      </div>
    </Modal>
  );
}
