import React, { useMemo } from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import { FaArrowRight } from 'react-icons/fa';

import styles from './ExpandedRow.module.css';

export default function ExpandedRow({ solicitation }) {
  const userName = useMemo(() => solicitation?.user?.passenger?.name, [solicitation])
  const entityName = useMemo(() => solicitation?.entity_name, [solicitation])

  return (
    <div>
      <Row gutter={32} type="flex" justify="start" align="middle">
        {!!userName && <Col>
          <p className={styles.label}>Solicitante</p>
          <p className={styles.title}>
            {userName}
            <span className={styles.registration}>Nº {solicitation.user.passenger.registration}</span>
          </p>
        </Col>}
        {!!entityName && <Col>
          <p className={styles.label}>Material</p>
          <p className={styles.title}>{entityName}</p>
        </Col>}
        <Col>
          <label className={styles.label}>Data da solicitação</label>
          <p>{moment(solicitation.created_at).format('DD/MM/YY[ às ]HH:mm')}</p>
        </Col>
        <Col>
          <label className={styles.label}>Telefone para contato</label>
          <p>{solicitation.phone || 'Contato não fornecido'}</p>
        </Col>
        <Col>
          <label className={styles.label}>Observações</label>
          <p>{solicitation.observation || 'Sem observações'}</p>
        </Col>
      </Row>
      <br />
      <Row gutter={32} type="flex" justify="start" align="middle">
        <Col>
          <label className={styles.label}>Origem</label>
          <p className={styles.address}>{solicitation.origin.address}</p>
        </Col>
        <Col>
          <FaArrowRight className={styles.arrowIcon} />
        </Col>
        <Col>
          <label className={styles.label}>Destino</label>
          <p className={styles.address}>{solicitation.destination.address}</p>
        </Col>
      </Row>
    </div>
  );
}
