import { IPassenger } from '../../types/types';

export const generateExcelPassengers = (passengers = []) => {
  const response = passengers.map((passenger: IPassenger) => ({
    name: passenger?.name,
    registration: passenger?.registration || 'Visitante',
    cost_center_number: passenger?.cost_center?.number,
    cost_center_description: passenger?.cost_center?.description,
    is_solicitant: passenger?.is_solicitant ? 'Sim' : 'Não',
    address: passenger?.address?.description,
    locality: passenger?.locality?.name,
  }));

  return response;
};
