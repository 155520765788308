import { simpleClient } from '../../../../helpers/auth';
import { getDistanceBetweenDestinations } from './getDistanceBetweenDestinations';
import { QUERY_EXTRA_COST_EXTREMO } from './queries';
import { DISTANCE_TO_EXTREM_COST, MAX_DISTANCE_TO_EXTREME_COST } from '../../../../constants';
import { notification } from 'antd';
import { metersToKilometers } from "../../Router/helpers/routerize";

export const getExtremExtraCost = async (trip) => {
  if (trip.solicitations.length === 1) return;

  const distances = await getDistanceBetweenDestinations(trip);
  const maxDistance = Math.max(...distances);

  if(maxDistance > MAX_DISTANCE_TO_EXTREME_COST)
    notification.warning({
      message: 'Alerta de viagem',
      description: `Viagem de número ${trip.number} com extremos de mais de ${metersToKilometers(MAX_DISTANCE_TO_EXTREME_COST)}km foi enviada`,
      duration: 4,
    });

  if (maxDistance <= DISTANCE_TO_EXTREM_COST) {
    return;
  }

  const variablesExtraCost = { cooperative_id: trip.cooperative_id || trip.cooperative?.id };
  const { cooperatives_extra_cost } = await simpleClient.request(QUERY_EXTRA_COST_EXTREMO, variablesExtraCost);

  if (!cooperatives_extra_cost.length) return 0;

  const value = cooperatives_extra_cost[0].value || 0;
  const extraCostTypeId = cooperatives_extra_cost[0]?.extra_cost_type?.id || null;

  if (!extraCostTypeId) return { error: true, trip }

  return { value, extraCostTypeId };
};
