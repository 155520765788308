import { Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import FilterContainer from '../../components/Filters/FilterContainer';
import { UserContext } from '../../contexts/UserContext';
import ApplyFilters from './components/ApplyFilters/ApplyFilters';
import MeanPassengersCard from './components/Cards/MeanPassengersCard/MeanPassengersCard';
import SolicitationsCard from './components/Cards/SolicitationsCard/SolicitationsCard';
import TotalCostCard from './components/Cards/TotalCostCard/TotalCostCard';
import TripsCard from './components/Cards/TripsCard/TripsCard';
import CostCenterRanking from './components/CostCenterRanking/CostCenterRanking';
import CostChart from './components/CostChart/CostChart';
import TripsChart from './components/TripsChart/TripsChart';
import SearchPassenger from './components/SearchPassenger/SearchPassenger';
import SolicitationsByReason from './components/SolicitationsByReason/SolicitationsByReason';
import { useDashboardContext } from './contexts/DashboardContext';
import styles from './Home.module.css';
import Welcome from './Welcome';

const Charged = styled.span`
  padding: 0.2rem 0.4rem;
  background-color: #87d068;
  border-radius: 6px;
  color: white;
`;

const Section = styled(Row)`
  margin-top: 1rem;
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  max-width: 100%;
`;

const { RangePicker } = DatePicker;

export default function Home() {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState(undefined);

  useEffect(() => {
    if (user?.user?.user_permission) {
      setLoading(false);
      setPermissions(user?.user?.user_permission);
    }
  }, [user]);

  const { startDate, endDate, setStartDate, setEndDate, fetching, error } = useDashboardContext();

  const onChange = (value, dateString) => {
    setStartDate(value[0]);
    setEndDate(value[1]);
  };

  if (error) return <p>Erro ao carregar viagens</p>;

  return (
    <div>
      {!loading ? (
        permissions.dashboard_access ? (
          <div>
            <div className={styles.pageHeadingContainer}>
              <h2 className={styles.pageHeading}>Dashboard</h2>
            </div>
            <p className={styles.instruction}>
              Selecione o período de análise e os filtros para checar os indicadores das viagens <Charged>FATURADAS</Charged>
            </p>
            <FilterContainer>
              <RangePicker defaultValue={[moment(startDate), moment(endDate)]} format="DD/MM/YY" onChange={onChange} />
              <ApplyFilters />
            </FilterContainer>
            {!fetching ? (
              <>
                <Section gutter={16}>
                  <Col span={16}>
                    <CardsContainer>
                      <TotalCostCard />
                      <SolicitationsCard />
                      <TripsCard />
                      <MeanPassengersCard />
                    </CardsContainer>
                    <CostChart />
                  </Col>
                  <Col span={8}>
                    <CostCenterRanking />
                  </Col>
                </Section>
                <Section>
                  <Col span={16}>
                    <TripsChart />
                  </Col>
                  <Col span={8}>
                    <SearchPassenger />
                  </Col>
                </Section>
                <Section>
                  <Col span={24}>
                    <SolicitationsByReason />
                  </Col>
                </Section>
              </>
            ) : (
              <p>Carregando...</p>
            )}
          </div>
        ) : (
          <Welcome />
        )
      ) : (
        <p>Carregando...</p>
      )}
    </div>
  );
}
