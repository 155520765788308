import { Col, Dropdown, Popconfirm, Row, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import { FaBox, FaCalendarAlt, FaUser } from 'react-icons/fa';
import styled from 'styled-components';
import { LIMIT_PASSENGERS_SLUG, MAX_DISTANCE_TO_EXTREME_COST } from '../../../constants';
import { ConfigurationContext } from '../../../contexts/ConfigurationContext';
import styles from './Trip.module.css';
import TripPopover from './TripPopover';
import { TripsContext } from './TripsContextProvider';
import { TripDistances, tripDistancesTypes } from "./TripDistances";
import { metersToKilometers } from "./helpers/routerize";

const CustomTag = styled(Tag)`
  transform: scale(0.85);
  margin-left: 0.5rem;
`;

export default function Trip({ trip, tripIndex }) {
  const date = moment(trip.trip_date).format('DD/MM/YY');
  const { removeSolicitation, isLoadingDistances } = useContext(TripsContext);
  const { configuration } = useContext(ConfigurationContext);
  const maxDistanceToExtremeCostInKm = useMemo(() => metersToKilometers(MAX_DISTANCE_TO_EXTREME_COST), [])
  const limit_passengers = configuration.find((config) => config.name === LIMIT_PASSENGERS_SLUG);
  const exceededMaxDistanceToExtremeCost = useMemo(() => trip.exceededMaxDistanceToExtremeCost, [trip])

  return (
    <div className={`${styles.container} ${exceededMaxDistanceToExtremeCost ? styles.containerExceededMaxDistanceToExtremeCost : ''}`}>
      <Row style={{ backgroundColor: '#efefef', marginBottom: '0.5rem' }} align="middle">
        <Col span={6}>
          <div className={styles.dateNumber}>
            <span className={styles.number}>{trip.number}</span>
            <span>
              <FaCalendarAlt /> {`${date} às ${trip.time}`}
            </span>
            <span>
              {trip.solicitations.length === limit_passengers.value && (
                <Tooltip title="Essa viagem já possui o máximo de passageiros">
                  <CustomTag color="#DB0000">Máx.</CustomTag>
                </Tooltip>
              )}
            </span>
          </div>
        </Col>
        <Col span={6}>
          <p className={styles.headerItem}>Origem</p>
        </Col>
        <Col span={6}>
          <p className={styles.headerItem}>Destino</p>
        </Col>
        <Col span={3}>
          <p className={styles.headerItem}>Solicitado em</p>
        </Col>
      </Row>
      {trip.isExtremeCost ? <p className={styles.isExtremeCost}>Essa viagem é de custo extremo</p> : null}
      {exceededMaxDistanceToExtremeCost ? <p className={styles.exceededMaxDistanceToExtremeCost}>Viagem com extremos de mais de {maxDistanceToExtremeCostInKm}km</p> : null}
      {trip.solicitations.map((s, i) => {
        const passengerName = s.passenger_name
        const entityName = s.entity_name

        return (
          <div key={i} className={styles.solicitation}>
            <Row align="middle">
              {!!passengerName && <Col span={6}>
                <p>
                  <FaUser/> {passengerName} ({s.number})
                </p>
              </Col>}
              {!!entityName && <Col span={6}>
                <p>
                  <FaBox /> {entityName}
                </p>
              </Col>}
              <Col span={6}>
                <p className={styles.item}>{s.origin?.address || 'Não informado'}</p>
                <TripDistances distances={s.origin?.distances} type={tripDistancesTypes.origins} />
              </Col>
              <Col span={6}>
                <p className={styles.item}>{s.destination.address}</p>
                <TripDistances distances={s.destination?.distances} type={tripDistancesTypes.destinations} />
              </Col>
              <Col span={3}>
                <p className={styles.item}>{moment(s.created_at).format('DD/MM/YY[ às ]HH:mm')}</p>
              </Col>
              <Col span={3}>
                <div className={styles.iconContainer}>
                  <Dropdown trigger={['click']}
                            overlay={isLoadingDistances ? <></> : <TripPopover index={i} solicitation={s} trip={trip} tripIndex={tripIndex}/>}
                            placement="bottomRight">
                    <Tag style={{ cursor: 'pointer', marginRight: '0.5rem' }} color="#FFA500">
                      Mover
                    </Tag>
                  </Dropdown>
                  <Popconfirm
                      title="Remover essa solicitação? Esta solicitação voltará para as pendentes"
                      onConfirm={() => {
                        removeSolicitation(i, tripIndex);
                      }}
                  >
                    <Tag style={{ cursor: 'pointer' }} color="#db0000">
                      Excluir
                    </Tag>
                  </Popconfirm>
                </div>
              </Col>
            </Row>
          </div>
        )
      })}
    </div>
  );
}
