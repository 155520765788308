import React from 'react';
import { Col, Checkbox, message } from 'antd';
import gql from 'graphql-tag';
import { useMutation } from 'urql';

const SOLICITANT_MUTATION = gql`
  mutation($id: uuid!, $solicitant: Boolean!) {
    update_passenger(where: { id: { _eq: $id } }, _set: { is_solicitant: $solicitant }) {
      affected_rows
      returning {
        is_solicitant
      }
    }
  }
`;

export default function IsSolicitant({ passenger, is_solicitant, setVisible, setSelectedPassenger }) {
  const [, update_solicitant] = useMutation(SOLICITANT_MUTATION);

  const mutate_solicitant = (id, solicitant) => {
    update_solicitant({ id, solicitant })
      .then(e => {
        if (e.data) {
          setSelectedPassenger(passenger);
          if (!is_solicitant) {
            setVisible(true);
          }
        }
        message.success('Permissão de solicitante atualizada');
      })
      .catch(e => console.log(e));
  };

  return (
    <Col>
      <Checkbox onChange={e => mutate_solicitant(passenger.id, e.target.checked)} checked={is_solicitant}>
        Solicitante
      </Checkbox>
    </Col>
  );
}
