import { Table, Popconfirm, Tooltip, notification } from 'antd';
import React, { useMemo, useState } from 'react';
import { FaFileExcel, FaTimes } from 'react-icons/fa';
import { useRecurrentSolicitations } from './hooks/useRecurrentSolicitations';
import styles from './RecurrentSolicitation.module.css';
import moment from 'moment';
import styled from 'styled-components';
import getRecurrenceLabel from './helpers/get-recurrence-label';
import { useMutation } from 'urql';
import gql from 'graphql-tag';
import FilterContainer from '../../components/Filters/FilterContainer';
import FilterPassenger from './components/FilterPassenger/FilterPassenger';
import RangePicker from './components/RangePicker/RangePicker';
import usePermissions from './hooks/usePermissions';
import useCostCentersPassengers from './hooks/useCostCentersPassenger';
import ReactExport from "react-export-excel";
import { transformDataInRecurrentSolicitationsToExcel } from "./helpers/recurrent-solicitations-helper";

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

const DELETE_RECURRENT_MUTATION = gql`
  mutation DeleteRecurrent($id: uuid!) {
    delete_solicitation(where: { recurrent_solicitation_id: { _eq: $id }, state: { _eq: "pendent" } }) {
      affected_rows
    }
    delete_recurrent_solicitation_by_pk(id: $id) {
      id
      recurrence_config
      created_at
      attributes
      type
      passenger {
        name
      }
    }
  }
`;

const Instruction = styled.p`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 2rem;
`;

function RecurrentSolicitation() {
  const [, delete_recurrent] = useMutation(DELETE_RECURRENT_MUTATION);
  const [rangeDate, setRangeDate] = useState([moment().subtract(1, 'month'), moment()]);
  const [selectedPassenger, setSelectedPassenger] = useState(null);
  const { user_permission, user_id } = usePermissions();
  const cooperativePermission = user_permission?.cooperative;
  const { passengers } = useCostCentersPassengers();
  const { data, error, fetching } = useRecurrentSolicitations(rangeDate, cooperativePermission, user_id, selectedPassenger);
  const recurrentSolicitationsToExcel = useMemo(() => transformDataInRecurrentSolicitationsToExcel(data), [data])
  const recurrentSolicitationsFileName = `solicitacoes-recorrentes-${moment().format('DD-MM-YYYY-HH-mm-ss')}`

  const exclude_recurrent = id => {
    delete_recurrent({ id }).then(e => {
      console.log(e);
      if (e.data) {
        notification['success']({
          message: 'Recorrência excluída com sucesso',
          description: 'Todas as solicitações pendentes dessa recorrência foram apagadas',
        });
      }
    });
  };

  const columns = [
    {
      title: 'Passageiro',
      render: (_, record) => record.passenger.name,
    },
    {
      title: 'Criada em',
      data_index: 'created_at',
      render: value => moment(value).format('DD/MM/YY'),
    },
    {
      title: 'Ocorre até',
      render: (_, record) => {
        const dates = record.recurrence_config.dates;
        return moment(dates[dates.length - 1]).format('DD/MM/YY');
      },
    },
    {
      title: 'Tipo',
      data_index: 'type',
      render: (_, record) => getRecurrenceLabel(record.type),
    },
    {
      title: 'Ocorrências',
      render: (_, record) => record?.recurrence_config.dates.length,
    },
    {
      title: 'Excluir',
      render: (text, record) => (
        <Popconfirm
          title="Você tem certeza que deseja cancelar todas as solicitações?"
          okText="Sim"
          cancelText="Não"
          onConfirm={() => exclude_recurrent(record.id)}
        >
          <Tooltip placement="top" title="Excluir">
            <FaTimes style={{ cursor: 'pointer' }} className={styles.icon} />
          </Tooltip>
        </Popconfirm>
      ),
    },
  ];

  if (error) return <p>Erro ao carregar solicitações</p>;

  return (
    <div>
      <div className={styles.pageHeadingContainer}>
        <h2 className={styles.pageHeading}>Recorrência</h2>
      </div>
      <div>
        <Instruction>
          Verifique as solicitações recorrentes que você criou anteriormente. Ao excluir um registro de recorrência, todas as solicitações pendentes
          relacionadas a esta recorrência serão excluídas também.
        </Instruction>
      </div>
      <FilterContainer>
        <RangePicker value={rangeDate} onChange={values => setRangeDate(values)} format="DD/MM/YY" />
        <FilterPassenger passenger={selectedPassenger} setPassenger={setSelectedPassenger} passengers={passengers ?? []} />
      </FilterContainer>

      {recurrentSolicitationsToExcel.length > 0 && <ExcelFile
          fileExtension="xlsx"
          filename={recurrentSolicitationsFileName}
          element={
            <Tooltip title="O arquivo exportado conterá as solicitações recorrentes da lista">
              <button className={`export-button ${styles.exportRecurrentSolicitationsButton}`}>
                Exportar para Excel
                <FaFileExcel aria-hidden={true} />
              </button>
            </Tooltip>
          }
      >
        <ExcelSheet data={recurrentSolicitationsToExcel} name="Solicitações recorrentes">
          <ExcelColumn label="Nome do passageiro" value="passengerName"/>
          <ExcelColumn label="Data de criação" value="createdDate"/>
          <ExcelColumn label="Data de última ocorrência" value="untilDate"/>
          <ExcelColumn label="Quantidade de ocorrências" value="quantity"/>
          <ExcelColumn label="Tipo" value="type"/>
        </ExcelSheet>
      </ExcelFile>}

      <Table columns={columns} dataSource={data?.recurrent_solicitation ?? []} loading={fetching} rowKey="id" />
    </div>
  );
}

export default RecurrentSolicitation;
