const MILLISECONDS = 1000
const SECONDS = 60
const MINUTES = 60

export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_TIME_FORMAT = 'DD/MM/YYYY (HH:mm)'
export const DATE_NOT_FORMATTED = 'DD-MM-YYYY'
export const DATE_TIME_NOT_FORMATTED = 'DD-MM-YYYY-HH-mm'

export const getDifferenceInHours = (firstDate, secondDate = new Date()) => {
    const differenceInMilliseconds = firstDate.getTime() - secondDate.getTime();

    return differenceInMilliseconds / (MILLISECONDS * SECONDS * MINUTES);
}
