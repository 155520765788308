import { message, Modal } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'urql';
import XLSX from 'xlsx';
import styles from './CostCenters.module.css';
import { UploadOutlined } from '@ant-design/icons';

const INSERT_COST_CENTERS_MUTATION = gql`
  mutation($objects: [cost_center_insert_input!]!) {
    insert_cost_center(objects: $objects, on_conflict: { constraint: cost_center_number_key, update_columns: [number, description, deleted_at] }) {
      affected_rows
    }
  }
`;

export default function ImportCostCentersModal({ visible, setVisible }) {
  const [importedCostCenters, setImportCostCenters] = useState([]);
  const [file, setFile] = useState('');

  const [, add_cost_centers] = useMutation(INSERT_COST_CENTERS_MUTATION);

  const insert_cost_centers = (objs) => {
    add_cost_centers({ objects: objs })
      .then((e) => console.log(e))
      .catch((e) => console.error(e));
  };

  const extractWorkers = async (item) => {
    return {
      number: item['A'].toString(),
      description: item['B'].toString(),
    };
  };

  const processFileContent = async (file) => {
    const fileReader = new FileReader();
    fileReader.onload = async (event) => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        const first_sheet_name = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[first_sheet_name];
        const data = XLSX.utils.sheet_to_json(worksheet, {
          header: 'A',
          raw: true,
          defval: null,
        });
        data.shift();

        const costCentersPromise = data.map(extractWorkers);
        let costCenters = await Promise.all(costCentersPromise);
        
        setFile(file.name);
        setImportCostCenters(costCenters);
        message.success('Arquivo carregado com sucesso!');
      } catch (e) {
        message.error('Erro com o formato da planilha');
      }
    };
    fileReader.readAsBinaryString(file);
  };

  const onImportExcel = (event) => {
    const { files } = event.target;
    if (files.length === 1) {
      processFileContent(files[0]);
    }
  };

  const saveImport = () => {
    if(importedCostCenters.length < 1){
      message.error('Não é possível importar uma planilha sem itens');
    } else {
      insert_cost_centers(importedCostCenters);
      clearExcelFile()
      setVisible(false);
    }
  };

  const clearExcelFile = () => {
    setFile('');
    setImportCostCenters([]);
  }

  return (
    <Modal
      destroyOnClose={true}
      visible={visible}
      onCancel={() => {
        clearExcelFile()
        setVisible(false);
      }}
      footer={null}
      title="Importar centros de custo"
      width={800}
    >
      <div className={styles.modalContainer}>
        <div className={styles.modalContent}>
          <h3 className={styles.modalInstruction}>
            Faça o upload da planilha de <strong>Centro de Custos</strong> mais atualizada
          </h3>
          <input id="file" name="file" onChange={onImportExcel} className={styles.fileUploader} type="file" accept=".xlsx, .xls" />
          <label htmlFor="file">
            <UploadOutlined className={styles.uploadIcon} />
            Escolha um arquivo
          </label>
          <p>{file}</p>
        </div>
        <div className={styles.modalActions}>
          <button disabled={!file} onClick={saveImport} className={styles.saveButton}>
            Salvar importação
          </button>
        </div>
      </div>
    </Modal>
  );
}
