import { notification } from 'antd';
import gql from 'graphql-tag';
import { differenceBy, sortBy } from 'lodash';
import React, { createContext, useMemo, useState } from 'react';
import { useMutation } from 'urql';
import delay from '../../ControlPanel/Import/delay';

const INSERT_TRIP = gql`
  mutation($objects: [trip_insert_input!]!) {
    insert_trip(objects: $objects) {
      returning {
        number
        id
        solicitations {
          id
          passenger_name
        }
      }
    }
  }
`;

const UPDATE_SOLICITATION = gql`
  mutation($trip_id: uuid!, $solicitations: [uuid!]!, $costs: [uuid!]!) {
    update_solicitation(where: { id: { _in: $solicitations } }, _set: { trip_id: $trip_id, state: "sent" }) {
      affected_rows
      returning {
        id
        state
      }
    }
    update_cost(where: { id: { _in: $costs } }, _set: { trip_id: $trip_id }) {
      affected_rows
    }
  }
`;

export const TripsContext = createContext();

export default function TripsContextProvider(props) {
  const [movedTrips, setTrips] = useState([]);
  const [loadingRouter, setLoadingRouter] = useState(false);
  const [, insert_trip] = useMutation(INSERT_TRIP);
  const [, update_solicitation] = useMutation(UPDATE_SOLICITATION);
  const [isLoadingDistances, setIsLoadingDistances] = useState(false);
  const hasMovedTrips = useMemo(() => movedTrips?.length > 0, [movedTrips]);

  const add_trips = async (trips, cost_ids, setVisible, setLoading) => {
    console.log('add_trips', trips);
    console.log('cost_ids', cost_ids);
    const solicitations = trips.map((t) => t.solicitations.map((s) => s.id));
    console.log('solicitations', solicitations);

    try {
      if (trips.length !== cost_ids.length) {
        throw new Error('Wrong monetization');
      }

      for (let trip of trips) {
        delete trip['solicitations'];
        delete trip['number'];
        delete trip['exceededMaxDistanceToExtremeCost'];
        delete trip['isExtremeCost'];
      }

      insert_trip({ objects: trips }).then(async (e) => {
        const trip_ids = e.data.insert_trip.returning;
        console.log('trip_ids', trip_ids);
        for (let [index, trip] of trip_ids.entries()) {
          console.log('updating to costs', index, trip);
          await delay(300);
          set_solicitation_costs({ trip_id: trip.id, solicitations: solicitations[index], costs: cost_ids[index] }, trip.number);
        }

        notification['success']({
          message: 'Viagens enviadas',
          description: 'As viagens foram enviadas para as cooperativas.',
        });
        setTrips(null);
        setVisible(false);
        setLoading(false);
      });
    } catch (error) {
      notification['error']({
        message: 'Erro ao monetizar as viagens',
        description: 'Confira os valores nas cooperativas ou contate o suporte',
      });
      setLoading(false);
    }
  };

  const set_solicitation_costs = (obj, tripNumber) => {
    update_solicitation(obj).then((e) => {
      if (e.error) {
        notification['error']({
          message: `Erro ao colocar solicitações na viagem ${tripNumber}`,
          description: e.error.message,
        });
      }
    });
  };

  const move = (solicitationIndex, tripIndex, finalTripIndex) => {
    const solicitation = movedTrips[tripIndex].solicitations[solicitationIndex];
    let initTrip = movedTrips[tripIndex];
    let finishTrip = movedTrips[finalTripIndex];

    finishTrip.solicitations.push(solicitation);
    initTrip.solicitations.splice(solicitationIndex, 1);

    setTrips((prev) => {
      const tripsAdd = [initTrip, finishTrip];
      const oldTrips = differenceBy(prev, tripsAdd, 'number');

      const newTrips = sortBy([...oldTrips, ...tripsAdd], ['number']).filter((t) => t.solicitations.length !== 0);

      return newTrips;
    });
  };

  const newTrip = (solicitationIndex, tripIndex) => {
    const solicitation = movedTrips[tripIndex].solicitations[solicitationIndex];
    let initTrip = { ...movedTrips[tripIndex] };
    let newTrip = { ...movedTrips[tripIndex] };

    initTrip.solicitations.splice(solicitationIndex, 1);
    newTrip.number = Number(movedTrips[movedTrips.length - 1].number) + 1;
    newTrip.solicitations = [solicitation];

    setTrips((prev) => {
      const tripsAdd = [initTrip, newTrip];

      const oldTrips = differenceBy(prev, tripsAdd, 'number');

      const newTrips = sortBy([...oldTrips, ...tripsAdd], ['number']).filter((t) => t.solicitations.length !== 0);

      return newTrips;
    });
  };

  const removeSolicitation = (solicitationIndex, tripIndex) => {
    const trips = movedTrips
      .map((trip, index) => {
        if (index === tripIndex) {
          let newTrip = trip;
          newTrip.solicitations.splice(solicitationIndex, 1);
          return newTrip;
        } else {
          return trip;
        }
      })
      .filter((trip) => trip.solicitations.length !== 0);

    setTrips(trips);
  };

  return (
    <TripsContext.Provider value={{ movedTrips, hasMovedTrips, loadingRouter, setLoadingRouter, move, newTrip, removeSolicitation, setTrips, add_trips, isLoadingDistances, setIsLoadingDistances }}>
      {props.children}
    </TripsContext.Provider>
  );
}
