import { Radio } from 'antd';
import { groupBy, uniq } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { RANGE_TYPE_DAY, RANGE_TYPE_MONTH, RANGE_TYPE_WEEK } from '../../constants';
import { useDashboardContext } from '../../contexts/DashboardContext';
import { Subtitle } from '../Subtitle/Subtitle';
import CostChartCanvas from './CostChartCanvas';

const Container = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 8px;
  height: 40vh;
  margin-right: 0.5rem;
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.75rem;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default function CostChart() {
  const { selectedCostCenters, formatTrips } = useDashboardContext();

  const [rangeType, setRangeType] = useState(RANGE_TYPE_DAY);

  const costCenterNumbers = uniq(selectedCostCenters.map((costCenter) => costCenter?.number)) ?? [];

  const formatData = (groupedTrips) => {
    return Object.entries(groupedTrips)
      .map((group) => ({
        trip_date: group[0],
        trips: group[1],
      }))
      .map((date) => {
        const trips = date.trips;
        const groupedCostCenters = groupBy(trips, 'cost_center_number');

        const sumCosts = Object.entries(groupedCostCenters).map((costCenterWithSolicitation) => ({
          [costCenterWithSolicitation[0]]: costCenterWithSolicitation[1].reduce((sum, cost) => sum + cost.cost, 0),
        }));

        const keyValueStructure = sumCosts.reduce((obj, item) => ({ ...obj, ...item }));

        return { trip_date: date.trip_date, ...keyValueStructure };
      });
  };

  const tripCosts = useMemo(() => {
    if (rangeType === RANGE_TYPE_DAY) {
      const groupedTrips = groupBy(formatTrips, 'trip_date');

      const dateWithCostCenterCosts = formatData(groupedTrips);

      return dateWithCostCenterCosts;
    }

    if (rangeType === RANGE_TYPE_WEEK) {
      const groupedTrips = groupBy(formatTrips, (trip) => moment(trip.original_trip_date).format('[S] WW[ - ]YYYY'));

      const dateWithCostCenterCosts = formatData(groupedTrips);

      return dateWithCostCenterCosts;
    }

    if (rangeType === RANGE_TYPE_MONTH) {
      const groupedTrips = groupBy(formatTrips, (trip) => moment(trip.original_trip_date).format('MMMM/YY'));

      const dateWithCostCenterCosts = formatData(groupedTrips);

      return dateWithCostCenterCosts;
    }
  }, [formatTrips, rangeType]);

  return (
    <Container>
      <TitleContainer>
        <Title>Custo x Período</Title>
        <Radio.Group defaultValue={RANGE_TYPE_DAY} onChange={(e) => setRangeType(e.target.value)}>
          <Radio.Button value={RANGE_TYPE_DAY}>Dia</Radio.Button>
          <Radio.Button value={RANGE_TYPE_WEEK}>Semana</Radio.Button>
          <Radio.Button value={RANGE_TYPE_MONTH}>Mês</Radio.Button>
        </Radio.Group>
      </TitleContainer>
      <Subtitle>Confira os gastos relacionados a Táxi no período selecionado</Subtitle>
      <CostChartCanvas data={tripCosts} keys={costCenterNumbers} />
    </Container>
  );
}
