import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../contexts/UserContext';

export default function () {
  const [permission, setPermission] = useState({});
  const { user } = useContext(UserContext);

  useEffect(() => {
    const user_permission = user.user?.user_permission;

    setPermission({ user_permission, user_id: user?.user?.id });
  }, [user]);

  return permission;
}
