import gql from 'graphql-tag';
import { useSubscription } from 'urql';

const ROUTES_COST_SUBSCRIPTION = gql`
  subscription($cooperative_id: uuid!) {
    cooperatives_route_cost(where: { cooperative_id: { _eq: $cooperative_id } }, order_by: { route: { number: asc } }) {
      id
      cost
      route {
        destination
        origin
        number
      }
    }
  }
`;

const handleRoutesCostsSub = (cooperative = [], response) => {
  return response.cooperatives_route_cost;
};

export const useRoutesCosts = (cooperative_id) => {
  const [result] = useSubscription(
    {
      query: ROUTES_COST_SUBSCRIPTION,
      variables: { cooperative_id },
    },
    handleRoutesCostsSub,
  );

  return result.data || [];
};
