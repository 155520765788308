import { ExclamationCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Checkbox, DatePicker, notification, Popconfirm, Select, Table, Tag, Tooltip } from 'antd';
import pt_BR from 'antd/es/date-picker/locale/pt_BR';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useContext, useMemo, useState } from 'react';
import ReactExport from 'react-export-excel';
import { FaFileExcel, FaPen, FaSpinner, FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import { useMutation } from 'urql';
import ExpandedRow from '../../components/ExpandedRow/ExpandedRow';
import { CooperativesContext } from '../../contexts/CooperativesContext';
import { UserContext } from '../../contexts/UserContext';
import ChargeTripModal from './ChargeTripModal';
import CooperativeCost from './CooperativeCost';
import EditTripModal from './EditTripModal';
import { filterIllegalTrips } from './helpers/filterIllegalTrips';
import { generateExcelTrips } from './helpers/generateExcelTrips';
import { hasIllegalSolicitations } from './helpers/hasIllegalSolicitations';
import styles from './Historic.module.css';
import { useFormattedTrips } from './useFormattedTrips';
import { usePassengers } from './usePassengers';
import {
  generateDifferenceCostSolicitations,
} from "./helpers/generateDifferenceCostSolicitations";
import { DATE_NOT_FORMATTED, DATE_TIME_NOT_FORMATTED } from "../../helpers/date";
import { transformTextToFileName } from "../../helpers/files";
import {
  DifferenceCostSolicitationsDownloadModal
} from "./DifferenceCostSolicitationsDownloadModal/DifferenceCostSolicitationsDownloadModal";
import {
  DifferenceCostSolicitationsExporting
} from "./DifferenceCostSolicitationsExporting/DifferenceCostSolicitationsExporting";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Exclamation = styled(ExclamationCircleFilled)`
  color: red;
`;

const Alert = styled.p`
  display: inline-block;
`;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const CHARGE_MUTATION = gql`
  mutation($id: [uuid!]) {
    update_trip(where: { id: { _in: $id }, approved_at: { _is_null: false } }, _set: { charged_at: "now()" }) {
      affected_rows
      returning {
        id
        number
        state
        trip_date
        time
        approved_at
        charged_at
        observation
        costs {
          id
          value
          provenience
          amount
          extra_cost_type {
            id
            description
            name
            type
          }
        }
        cooperative {
          id
          name
        }
      }
    }
  }
`;

const APPROVE_MUTATION = gql`
  mutation($id: [uuid!]) {
    update_trip(where: { id: { _in: $id }, charged_at: { _is_null: true } }, _set: { approved_at: "now()" }) {
      affected_rows
      returning {
        id
        number
        state
        trip_date
        time
        approved_at
        charged_at
        observation
        costs {
          id
          value
          provenience
          amount
          extra_cost_type {
            id
            description
            name
            type
          }
        }
        cooperative {
          id
          name
        }
      }
    }
  }
`;

const DELETE_TRIP_MUTATION = gql`
  mutation($id: uuid!) {
    update_trip(where: { id: { _eq: $id } }, _set: { deleted_at: "now()" }) {
      affected_rows
    }
  }
`;

export default function Historic() {
  const [rangeDate, setRangeDate] = useState([moment().startOf('month'), moment().endOf('day')]);
  const [passenger, setPassenger] = useState(undefined);
  const [cooperative, setCooperative] = useState(null);
  const [, charge_trip] = useMutation(CHARGE_MUTATION);
  const [, approve_trip] = useMutation(APPROVE_MUTATION);
  const [, delete_trip] = useMutation(DELETE_TRIP_MUTATION);
  const [, setSelected] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [singleSelectedKey, setSingleSelectedKey] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [chargeModal, setChargeModal] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(undefined);
  const [filterIllegal, setFilterIllegal] = useState(false);
  const [differenceCostSolicitations, setDifferenceCostSolicitations] = useState([]);
  const [isExportingDifferenceCostSolicitations, setIsExportingDifferenceCostSolicitations] = useState(false);
  const [isOpenedDifferenceCostSolicitationsDownloadModal, setIsOpenedDifferenceCostSolicitationsDownloadModal] = useState(false);
  const [differenceCostSolicitationsEstimate, setDifferenceCostSolicitationsEstimate] = useState(0);
  const { cooperatives } = useContext(CooperativesContext);
  const { user } = useContext(UserContext);
  const user_permission = user?.user?.user_permission;
  const isAdmin = useMemo(() => user_permission?.is_admin ?? false, [user_permission]);
  const user_cost_center_list = user.user?.user_cost_centers.map(({ cost_center }) => cost_center.id) || [];
  const cost_center_list = [...user_cost_center_list, user?.cost_center_id].filter(Boolean);
  const { passengers } = usePassengers(cost_center_list);
  const { data, fetching } = useFormattedTrips(rangeDate, cost_center_list);
  const formattedDateFromNow = moment().format(DATE_TIME_NOT_FORMATTED)

  const filteredData = useMemo(() => {
    const result = data.filter((d) => {
      if (!passenger) return true;

      const passenger_ids = d.solicitations.map((s) => s.passenger?.id);

      return passenger_ids.indexOf(passenger.id) >= 0;
    });

    return result;
  }, [data, passenger]);

  const illegalTripsCount = useMemo(() => {
    return filterIllegalTrips(filteredData).length;
  }, [filteredData]);

  const tableTrips = useMemo(() => {
    if (filterIllegal) {
      return filterIllegalTrips(filteredData);
    } else {
      return filteredData;
    }
  }, [filteredData, filterIllegal]);

  const excelTrips = useMemo(() => {
    return generateExcelTrips(tableTrips);
  }, [tableTrips]);

  const exportDifferenceCostSolicitations = async () => {
    if (isExportingDifferenceCostSolicitations || !isAdmin) return;

    setIsExportingDifferenceCostSolicitations(true)
    setDifferenceCostSolicitations([])

    let newDifferenceCostSolicitations = []

    try{
      newDifferenceCostSolicitations = await generateDifferenceCostSolicitations(tableTrips, excelTrips, setDifferenceCostSolicitationsEstimate)
    } catch(error){
      console.error(error)
      notification.error({ message: 'Ocorreu um problema ao exportar as solicitações com a diferença de custos da base atual' })
    } finally{
      setDifferenceCostSolicitations(newDifferenceCostSolicitations)
      setIsExportingDifferenceCostSolicitations(false)
      setDifferenceCostSolicitationsEstimate(0)

      if(newDifferenceCostSolicitations.length !== 0) setIsOpenedDifferenceCostSolicitationsDownloadModal(true)
    }
  }

  const differenceCostSolicitationsFileName = useMemo(() => {
    let fileName = 'diferenca-de-custos'
    const passengerName = passenger?.name
    const [firstDate, secondDate] = rangeDate ?? []

    if(filterIllegal) fileName += '-de-viagens-inconformes'
    if(firstDate && secondDate) fileName += `-de-${firstDate.format(DATE_NOT_FORMATTED)}-a-${secondDate.format(DATE_NOT_FORMATTED)}`
    if(cooperative) fileName += `-com-a-cooperativa-${cooperatives.find(({ id }) => id === cooperative).name}`
    if(passengerName) fileName += `-de-passageiro-${passengerName}`

    return transformTextToFileName(`${fileName}-em-${formattedDateFromNow}`)
  }, [formattedDateFromNow, rangeDate, cooperative, cooperatives, passenger, filterIllegal])

  const closeDifferenceCostSolicitationsDownloadModal = () => {
    setIsOpenedDifferenceCostSolicitationsDownloadModal(false)
    setDifferenceCostSolicitations([]);
  }

  const charge_mutation = (idsList) => {
    charge_trip({ id: idsList })
      .then((e) => {
        console.log(e);
        if (e.data) {
          notification['success']({
            message: 'Viagem faturada',
            description: 'Essa viagem foi computada para faturamento. O valor não pode ser mais alterado.',
          });
          setSingleSelectedKey([]);
        }
      })
      .catch((e) => console.error(e));
  };

  const approve_mutation = (idsList) => {
    approve_trip({ id: idsList })
      .then((e) => {
        console.log(e);
        if (e.data) {
          notification['success']({
            message: 'Viagem aprovada',
            description: 'Essa viagem foi aprovada para faturamento. As cooperativas não podem mais alterar este valor',
          });
        }
      })
      .catch((e) => console.error(e));
  };

  const exclude_trip = (id) => {
    delete_trip({ id })
      .then((e) => {
        console.log(e);
        if (e.data) {
          notification['success']({
            message: 'Viagem cancelada',
            description: 'Essa viagem foi cancelada com sucesso e não ficará mais disponível para as cooperativas',
          });
        }
      })
      .catch((e) => console.error(e));
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelected(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: 'Nº Ordem de serviço',
      dataIndex: 'number',
      render: (text, record) => {
        return (
          <>
            {hasIllegalSolicitations(record.solicitations) && (
              <Tooltip title="Nessa viagem há solicitações inconformes">
                <Exclamation />
              </Tooltip>
            )}{' '}
            <span>{record.number}</span>
          </>
        );
      },
    },
    {
      title: 'Data da viagem',
      render: (text, { trip_date, time }) => `${moment(trip_date).format('DD/MM/YY')} às ${time}`,
    },
    {
      title: 'Qtd. de pessoas',
      render: (text, record) => record.solicitations.length,
    },
    {
      title: 'Custo (R$)',
      dataIndex: 'cost',
      render: (text, record) => {
        return record.costs.reduce((sum, cost) => sum + cost.value, 0).toFixed(2);
      },
    },
    {
      title: 'Cooperativa',
      render: (text, record) => record.cooperative.name,
    },
    {
      title: 'Status',
      render: (text, record) =>
        record.charged_at ? (
          <Tooltip placement="top" title="Essa viagem já foi faturada e não pode ser alterada">
            <Tag color="#87d068">Faturado</Tag>
          </Tooltip>
        ) : record.approved_at ? (
          <div className={styles.actionContainer}>
            <Tooltip placement="top" title="Essa viagem teve seus custos aprovados">
              <Tag color="#FFA500">Aprovado</Tag>
            </Tooltip>
            {user_permission?.billing && (
              <div
                className={styles.link}
                onClick={() => {
                  setChargeModal(true);
                  setSingleSelectedKey([record.id]);
                }}
              >
                Faturar
              </div>
            )}
          </div>
        ) : (
          <div className={styles.actionContainer}>
            <Tooltip placement="top" title="Essa viagem ainda não teve seus custos aprovados">
              <Tag color="gray">Pendente</Tag>
            </Tooltip>
            {user_permission?.billing && (
              <div className={styles.link} onClick={() => approve_mutation([record.id])}>
                Aprovar
              </div>
            )}
          </div>
        ),
    },
    {
      title: 'Ações',
      render: (text, record) => (
        <div className={styles.actionContainer}>
          {user_permission?.billing && (
            <>
              <Tooltip placement="top" title="Editar">
                <FaPen
                  style={{ cursor: 'pointer' }}
                  className={styles.icon}
                  onClick={() => {
                    setSelectedTrip(record);
                    setEditModal(true);
                  }}
                />
              </Tooltip>
              <Popconfirm title="Você tem certeza que deseja excluir essa viagem?" okText="Sim" cancelText="Não" onConfirm={() => exclude_trip(record.id)}>
                <Tooltip placement="top" title="Excluir viagem">
                  <FaTimes style={{ cursor: 'pointer' }} className={styles.icon} />
                </Tooltip>
              </Popconfirm>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className={styles.pageHeadingContainer}>
        <h2 className={styles.pageHeading}>
          Histórico de viagens <span className={styles.pageHeadingCounter}>{fetching ? 'Carregando' : data.length} itens</span>
        </h2>
      </div>
      <p className={styles.instruction}>
        Neste histórico são mostradas as viagens <strong>confirmadas</strong> até hoje
      </p>
      <div className={styles.filterContainer}>
        <h3 className={styles.filterTitle}>Filtrar viagens por:</h3>
        <div className={styles.filterFields}>
          <div>
            <label className={styles.label}>Data</label>
            <br />
            <RangePicker
              locale={pt_BR}
              className={styles.field}
              value={rangeDate}
              onChange={(date) => setRangeDate(date)}
              format="DD/MM/YY"
              allowClear={false}
            />
          </div>
          <div>
            <label className={styles.label}>Cooperativa</label>
            <Select value={cooperative} onChange={(value) => setCooperative(value)} className={styles.fieldSelect} placeholder="Selecionar cooperativa">
              <Option value={null}>Todas</Option>
              {cooperatives.map((o) => (
                <Option key={o.id} value={o.id}>
                  {o.name}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <label className={styles.label}>Colaborador</label>
            <br />
            <Select
              style={{ marginTop: '0.5rem', width: '250px' }}
              showSearch={true}
              placeholder="Digite o nome do passageiro"
              value={passenger?.name}
              allowClear={true}
              suffixIcon={<SearchOutlined type="search" />}
              onChange={(value, option) => {
                const passenger = option?.props?.passenger;
                setPassenger(passenger);
              }}
            >
              {passengers.map((p) => (
                <Option key={p.id} value={`${p.name} ${p.registration}`} passenger={p}>
                  {p.name}
                  <br />
                  <span style={{ fontWeight: 'bold' }}>Matrícula: </span>
                  {p.registration || 'Sem matrícula'}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      {user_permission?.billing ? (
        <>
          {!!tableTrips.length && <CooperativeCost rangeDate={rangeDate} search={passenger} cost_center_list={cost_center_list} trips={tableTrips} />} <br />
          <Tooltip title="Essas viagens estão fora do eixo Casa-Trabalho">
            <Alert>
              <Exclamation /> {illegalTripsCount === 1 ? `${illegalTripsCount} viagem com inconformidade` : `${illegalTripsCount} viagens com inconformidade`}
            </Alert>
          </Tooltip>
          <br />
          <Checkbox
            checked={filterIllegal}
            onChange={(e) => {
              setFilterIllegal(e.target.checked);
            }}
          >
            Mostrar apenas viagens inconformes
          </Checkbox>
        </>
      ) : null}
      {!!selectedRowKeys.length && user_permission?.billing && (
        <div className={styles.buttonsContainer}>
          <button onClick={() => approve_mutation(selectedRowKeys)} className={styles.approveAll}>
            Aprovar selecionados
          </button>
          <button onClick={() => setChargeModal(true)} className={styles.chargeAll}>
            Faturar selecionados
          </button>
        </div>
      )}

      <div className={styles.exportContainer}>
        {
          isAdmin && (
            <Popconfirm title={'Você tem certeza que deseja exportar as diferenças de custos de cada solicitação de viagem com a base atual de custos?'}
                        okText={'Sim'}
                        cancelText={'Não'}
                        placement={'topLeft'}
                        disabled={isExportingDifferenceCostSolicitations}
                        onConfirm={exportDifferenceCostSolicitations}>
              <Tooltip title={'Essa funcionalidade consome bastante processamento das distâncias (HERE Maps). Será exportado a diferença das viagens filtradas abaixo em relação a tabela de custos atuais da base. Evite exportar muitos itens. Quando estiver sendo exportada, aguarde, por favor, pois pode demorar um pouco.'}
                       placement={'bottom'}>
                <div>
                  <button className={'export-button'} disabled={isExportingDifferenceCostSolicitations}>
                    {
                      isExportingDifferenceCostSolicitations ?
                      <><FaSpinner className={'spinner'} aria-hidden={true} /> Exportando diferença de custos…</> :
                      <><FaFileExcel aria-hidden={true}/> Exportar diferença de custos</>
                    }
                  </button>
                </div>
              </Tooltip>
            </Popconfirm>
          )
        }

        <ExcelFile
          fileExtension="xlsx"
          filename={`Táxi Histórico de Viagens`}
          element={
            <Tooltip title="O arquivo exportado conterá os custos relacionados aos centros de custos que você possui acesso">
              <button className={styles.exportButton}>
                <FaFileExcel style={{ marginRight: '0.25rem' }} />
                Exportar
              </button>
            </Tooltip>
          }
        >
          <ExcelSheet data={excelTrips} name="Histórico">
            <ExcelColumn label="Número da Solicitação" value="solicitation_number" />
            <ExcelColumn label="Nome do solicitante" value="solicitant_name" />
            <ExcelColumn label="Matrícula do passageiro" value="passenger_registration" />
            <ExcelColumn label="Nome do passageiro" value="passenger_name" />
            <ExcelColumn label="Nome do material" value="entity_name" />
            <ExcelColumn label="Nº do Centro de Custo" value="cost_center_number" />
            <ExcelColumn label="Nome do Centro de Custo" value="cost_center_description" />
            <ExcelColumn label="Número da Viagem" value="trip_number" />
            <ExcelColumn label="Data da viagem" value="trip_date" />
            <ExcelColumn label="Horário da viagem" value="trip_time" />
            <ExcelColumn label="Origem" value="origin" />
            <ExcelColumn label="Destino" value="destination" />
            <ExcelColumn label="Localidade de Origem" value="origin_locality" />
            <ExcelColumn label="Localidade de Destino" value="destination_locality" />
            <ExcelColumn label="Cooperativa" value="cooperative" />
            <ExcelColumn label="Motivo" value="reason" />
            <ExcelColumn label="Custo total (R$)" value="totalCost" />
            <ExcelColumn label="Custo-base (R$)" value="baseCost" />
            <ExcelColumn label="Custo de Extremo (R$)" value="extremCost" />
            <ExcelColumn label="Custo de Via (R$)" value="viaCost" />
            <ExcelColumn label="Custo de Estacionamento (R$)" value="parkingCost" />
            <ExcelColumn label="Custo de Hora à Disposição (R$)" value="waitCost" />
            <ExcelColumn label="Solicitação fora do eixo Casa-Trabalho" value="isIllegal" />
            <ExcelColumn label='Explicação de origem e destino não padrão ("Casa" e "Trabalho")' value="explain_why_no_default_way" />
            <ExcelColumn label="Status" value="state" />
            <ExcelColumn label="Observações" value="observation" />
          </ExcelSheet>
        </ExcelFile>
      </div>
      <Table
        rowKey="id"
        rowSelection={user_permission?.billing ? rowSelection : null}
        loading={fetching}
        columns={user_permission?.billing ? columns : columns.filter(({ title }) => title !== 'Ações').filter(({ title }) => title !== 'Status')}
        dataSource={tableTrips}
        expandedRowRender={(record) => <ExpandedRow trip={record} />}
      />
      {editModal && <EditTripModal visible={editModal} setVisible={setEditModal} trip={selectedTrip} />}
      <ChargeTripModal
        visible={chargeModal}
        setVisible={setChargeModal}
        charge={charge_mutation}
        keys={selectedRowKeys}
        singleKey={singleSelectedKey}
        setSingleKey={setSingleSelectedKey}
      />

      <DifferenceCostSolicitationsExporting isExporting={isExportingDifferenceCostSolicitations}
                                            estimate={differenceCostSolicitationsEstimate} />

      <DifferenceCostSolicitationsDownloadModal isOpened={isOpenedDifferenceCostSolicitationsDownloadModal}
                                                fileName={differenceCostSolicitationsFileName}
                                                solicitations={differenceCostSolicitations}
                                                close={closeDifferenceCostSolicitationsDownloadModal} />
    </div>
  );
}
