import React, { useMemo } from 'react';
import { useDashboardContext } from '../../../contexts/DashboardContext';
import Card from '../Card';

interface Props {}

const SolicitationsCard = (props: Props) => {
  const { formatTrips } = useDashboardContext();

  const totalAmount = useMemo(() => {
    return formatTrips.length;
  }, [formatTrips]);

  return <Card title="Solicitações" value={totalAmount} />;
};

export default SolicitationsCard;
