import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import styled from 'styled-components';

const Label = styled.p`
  font-size: 12px;
  color: gray;
`;

const Value = styled.p`
  font-size: 16px;
  color: black;
`;

const Title = styled.p`
  font-size: 14px;
  position: relative;
  top: -28px;
  left: -10px;
  display: inline-block;
  background-color: white;
  padding: 0 0.5rem;
`;

function CooperativeCostItem({ name, trips }) {
  const sumCosts = (trips) => {
    return trips.reduce((sum, trip) => sum + trip.costs.reduce((sum, cost) => sum + cost.value, 0), 0).toFixed(2) || 0;
  };

  const pendentTrips = (trips) => {
    return trips.filter((trip) => {
      return trip.approved_at === null && trip.charged_at === null;
    });
  };

  const approvedTrips = (trips) => {
    return trips.filter((trip) => {
      return trip.approved_at !== null && trip.charged_at === null;
    });
  };

  const chargedTrips = (trips) => {
    return trips.filter((trip) => {
      return trip.approved_at !== null && trip.charged_at !== null;
    });
  };

  return (
    <>
      <div>
        <Title>Faturamento - {name}</Title>
        <Row gutter={16}>
          <Col span={8}>
            <Tooltip title="Faturamento da cooperativa neste período">
              <Label>Pendente</Label>
              <Value>R$ {sumCosts(pendentTrips(trips))}</Value>
            </Tooltip>
          </Col>
          <Col span={8}>
            <Tooltip title="Faturamento da cooperativa neste período">
              <Label>Aprovado</Label>
              <Value>R$ {sumCosts(approvedTrips(trips))}</Value>
            </Tooltip>
          </Col>
          <Col span={8}>
            <Tooltip title="Faturamento da cooperativa neste período">
              <Label>Faturado</Label>
              <Value>R$ {sumCosts(chargedTrips(trips))}</Value>
            </Tooltip>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CooperativeCostItem;
