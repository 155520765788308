import { groupBy, sortBy } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useDashboardContext } from '../../contexts/DashboardContext';
import { Subtitle } from '../Subtitle/Subtitle';
import { Title } from '../Title/Title';
import SolicitationsByReasonCanvas from './SolicitationsByReasonCanvas';

const Container = styled.div`
  padding: 1rem;
  border-radius: 8px;
  height: 57vh;
  margin-right: 0.5rem;
`;

interface Props {}

const SolicitationsByReason = (props: Props) => {
  const { formatTrips } = useDashboardContext();

  const groupedReasons = groupBy(formatTrips, 'reason');

  const data = Object.entries(groupedReasons).map((group) => ({
    reason: group[0],
    Solicitações: group[1].length,
  }));

  const orderedData = sortBy(data, ['Solicitações']).reverse().slice(0, 5);

  return (
    <Container>
      <Title>Solicitações x Motivo</Title>
      <Subtitle>Confira o top 5 motivos de solicitações de táxi no período</Subtitle>
      <SolicitationsByReasonCanvas data={orderedData} />
    </Container>
  );
};

export default SolicitationsByReason;
