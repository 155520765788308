import { useSubscription } from 'urql';
import gql from 'graphql-tag';
import { getUser } from '../../../helpers/auth';

const COST_CENTERS_QUERY = gql`
  subscription($id: uuid!) {
    user_cost_center(where: { user_id: { _eq: $id } }) {
      cost_center_id
    }
  }
`;

export const useUserCostCenters = () => {
  const userId = getUser();

  const [result] = useSubscription({
    query: COST_CENTERS_QUERY,
    variables: { id: userId },
  });

  if (result.error) return [];

  const { user_cost_center } = result.data ? result.data : [];

  if (user_cost_center) {
    const costCenters = user_cost_center.map((c) => c.cost_center_id);

    return costCenters;
  }

  return [];
};
