import gql from 'graphql-tag';
import { useSubscription } from 'urql';

const COST_SUBSCRIPTION = gql`
  subscription($id: uuid!) {
    cost(where: { trip_id: { _eq: $id } }) {
      id
      value
      provenience
      amount
      trip_id
      extra_cost_type {
        name
        type
        id
      }
    }
  }
`;

const handleCostsSub = (cost = [], response) => {
  return response.cost;
};

export const useCosts = (id) => {
  const [result] = useSubscription(
    {
      query: COST_SUBSCRIPTION,
      variables: { id },
    },
    handleCostsSub,
  );

  return result.data || [];
};
