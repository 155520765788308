import moment from 'moment';

export default function (startAt, endAt) {
  const end = moment(endAt);
  const endIndex = end.diff(moment(startAt), 'months');

  const dates = [];
  for (let i = 0; i <= endIndex; i++) {
    const start = moment(startAt);
    let recurrentDay = start.add(i, 'month');

    dates.push(recurrentDay);
  }

  return dates;
}
