import { Tooltip } from 'antd';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import { FaClock, FaFileExcel, FaRedoAlt } from 'react-icons/fa';
import { UserContext } from '../../../contexts/UserContext';
import { CancelledSolicitationsExpandedRow } from './CancelledSolicitationsExpandedRow/CancelledSolicitationsExpandedRow';
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useCancelledSolicitations } from "./useCancelledSolicitations";
import {
  StyledActions,
  StyledButton,
  StyledContainer,
  StyledFieldControl,
  StyledFilters,
  StyledNumberColumn,
  StyledRangePicker,
  StyledTable
} from "./styles";
import { DATE_FORMAT } from "../../../helpers/date";
import { getRegistrationText } from "../../../helpers/messages";
import TimePicker from "../../NewSolicitation/components/TimePicker/TimePicker";
import { PassengerSelect } from "../../../components/PassengerSelect/PassengerSelect";
import { transformCancelledSolicitationsToExcel } from "./helper";
import ReactExport from "react-export-excel";

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

const Content = () => {
  const { user } = useContext(UserContext);
  const { values: { date, time, passenger }, initialValues, setFieldValue } = useFormikContext();
  const { error, data, fetching, refresh } = useCancelledSolicitations({ date, time, passenger, cooperative: user?.user?.user_permission?.cooperative, userId: user?.user_id });
  const cancelledSolicitations = useMemo(() => data?.solicitation || [], [data]);
  const cancelledSolicitationsFileName = `solicitacoes-canceladas-${moment().format('DD-MM-YYYY-HH-mm-ss')}`
  const cancelledSolicitationsToExcel = useMemo(() => transformCancelledSolicitationsToExcel(cancelledSolicitations), [cancelledSolicitations])

  const quantityText = useMemo(() => {
    const { length } = cancelledSolicitations

    if(length === 0 || length > 1) return `${length} solicitações canceladas`

    return `${length} solicitação cancelada`
  }, [cancelledSolicitations]);

  const columns = [
    {
      title: 'Número',
      dataIndex: 'number',
      width: 104,
      render: (text, { recurrent_solicitation_id }) => (
        <StyledNumberColumn>
          {recurrent_solicitation_id && <Tooltip title={'Solicitação recorrente'}><FaClock aria-hidden={true} /></Tooltip>} {text}
        </StyledNumberColumn>
      ),
    },
    {
      title: 'Data (viagem)',
      render: (_, { trip_date, time }) => `${moment(trip_date).format(DATE_FORMAT)} às ${time}`,
    },
    {
      title: 'Passageiro ou material',
      render: (_, { passenger_name, entity_name }) => passenger_name || entity_name,
    },
    {
      title: 'Origem',
      render: (_, { origin_locality }) => origin_locality?.name || '--',
    },
    {
      title: 'Destino',
      render: (_, { destination_locality }) => destination_locality?.name || '--',
    },
    {
      title: 'Matrícula',
      dataIndex: 'passenger.registration',
      render: (_, solicitation) => getRegistrationText(solicitation),
    },
    {
      title: 'Quem cancelou',
      dataIndex: 'user_who_deleted',
      render: userWhoDeleted => userWhoDeleted?.passenger?.name || '--',
    },
  ];

  return (
    <StyledContainer>
      <StyledFilters>
        <StyledRangePicker defaultValue={initialValues.date}
                           format={DATE_FORMAT}
                           value={date}
                           allowClear={false}
                           onChange={([startDate, endDate]) => setFieldValue('date', [startDate.startOf('day'), endDate.endOf('day')])} />

        <StyledFieldControl>
          <TimePicker name={'time'} label={'Horário'} setFieldValue={setFieldValue} value={time} />
        </StyledFieldControl>

        <StyledFieldControl width={'326px'}>
          <PassengerSelect name={'passenger'} value={passenger} setFieldValue={setFieldValue} />
        </StyledFieldControl>
      </StyledFilters>

      <p>{quantityText}</p>

      {error && <p>Erro ao listar solicitações canceladas</p>}

      <StyledActions>
        {cancelledSolicitationsToExcel.length > 0 && (
          <ExcelFile
              fileExtension={'xlsx'}
              filename={cancelledSolicitationsFileName}
              element={<StyledButton type={'button'}><FaFileExcel aria-hidden={true} /> Exportar para Excel</StyledButton>}
          >
            <ExcelSheet data={cancelledSolicitationsToExcel} name={'Solicitações canceladas'}>
              <ExcelColumn label={'Número'} value={'number'} />
              <ExcelColumn label={'Data de criação'} value={'createdDate'} />
              <ExcelColumn label={'Data da viagem'} value={'tripDate'} />
              <ExcelColumn label={'Passageiro ou material'} value={'entityName'} />
              <ExcelColumn label={'Matrícula'} value={'registration'} />
              <ExcelColumn label={'Origem'} value={'origin'} />
              <ExcelColumn label={'Destino'} value={'destination'} />
              <ExcelColumn label={'Centro de custo'} value={'costCenter'} />
              <ExcelColumn label={'Solicitante'} value={'solicitationUser'} />
              <ExcelColumn label={'Motivo'} value={'reason'} />
              <ExcelColumn label={'Telefone'} value={'phone'} />
              <ExcelColumn label={'Quem cancelou'} value={'userWhoDeleted'} />
              <ExcelColumn label={'Observações'} value={'observation'} />
            </ExcelSheet>
          </ExcelFile>
        )}

        <StyledButton type={'button'} disabled={fetching} onClick={refresh}>
          <FaRedoAlt aria-hidden={true} /> Atualizar
        </StyledButton>
      </StyledActions>

      <StyledTable
        rowKey={'id'}
        loading={fetching}
        columns={columns}
        dataSource={cancelledSolicitations}
        expandedRowRender={cancelledSolicitation => <CancelledSolicitationsExpandedRow cancelledSolicitation={cancelledSolicitation} />}
      />
    </StyledContainer>
  );
}

export const CancelledSolicitations = () => {
  const validationSchema =  Yup.object().shape({
    date: Yup.array().of(Yup.object().required()).required(),
    time: Yup.string(),
    passenger: Yup.string(),
  })

  return (
    <Formik initialValues={{ date: [moment().startOf('day'), moment().endOf('day').add(5, 'days')], time: '', passenger: null }}
            validationSchema={validationSchema}
            onSubmit={null}>
      <Content />
    </Formik>
  )
}
