import { simpleClient } from '../../../helpers/auth';
import { QUERY_LOCALITIES, QUERY_ROUTE } from '../../Solicitations/Monetizer/helpers/queries';

export const validateBadSolicitation = async (solicitation) => {
  // RETORNA LOCALIDADES DA ROTA
  const variablesLocalities = { origin: solicitation.origin_locality_id, destination: solicitation.destination_locality_id };
  const { locality } = await simpleClient.request(QUERY_LOCALITIES, variablesLocalities);

  const origin = locality[0].name;
  const destination = locality[1]?.name ?? locality[0].name;

  // RETORNA ROTA ALOCADA A ESSA VIAGEM
  const variablesRoutes = { origin, destination };
  const { route } = await simpleClient.request(QUERY_ROUTE, variablesRoutes);

  if (!route.length) {
    return true;
  }

  return false;
};
