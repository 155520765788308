import React, { useContext, useEffect, useMemo, useState } from 'react';
import CooperativeItem from './CooperativeItem';
import styles from './Cooperatives.module.css';
import NewCooperative from './NewCooperative';
import { useCooperatives } from './useCooperatives';
import { useExtraCostsType } from './useExtraCostsType';
import { useHistory } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

export const ProportionErrorContext = React.createContext();

export default function Cooperatives() {
  const [newModal, setNewModal] = useState(false);
  const [, setError] = useState(false);

  const cooperatives = useCooperatives();
  const extraCostsType = useExtraCostsType();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const isCruds = useMemo(() => user?.user?.user_permission?.cruds, [user])

  const openModal = () => setNewModal(true);

  const sumProportion = cooperatives && cooperatives.reduce((a, b) => a + b.proportion, 0);

  useEffect(() => {
    if (sumProportion !== 100 && cooperatives.length) {
      setError(true);
    } else {
      setError(false);
    }
  }, [sumProportion, cooperatives]);

  useEffect(() => {
    if(typeof isCruds !== 'boolean' || isCruds) return

    history.goBack()
  }, [isCruds, history])

  if(!isCruds) return null

  return (
    <div>
      <div className={styles.pageHeadingContainer}>
        <h2 className={styles.pageHeading}>
          Cooperativas <span className={styles.pageHeadingCounter}>{cooperatives.length} itens</span>
        </h2>
        <div>
          <button className={styles.primaryButton} onClick={openModal}>
            Nova cooperativa
          </button>
        </div>
      </div>

      <div className={styles.listContainer}>
        {cooperatives.map((c) => (
          <CooperativeItem cooperative={c} key={c.id} />
        ))}
      </div>

      {newModal && <NewCooperative visible={newModal} setVisible={setNewModal} extraCostsType={extraCostsType} />}
    </div>
  );
}
