import gql from 'graphql-tag';
import { useSubscription } from 'urql';

import React from 'react';

export const LocalitiesContext = React.createContext();

const LOCALITIES_SUBSCRIPTION = gql`
  subscription {
    locality(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
      id
      name
    }
  }
`;

const CONTI_SUBSCRIPTION = gql`
  subscription {
    locality(where: { name: { _ilike: "%CONTI%" } }) {
      id
      name
    }
  }
`;

const handleLocalitiesSub = (localities = [], response) => {
  return response.locality;
};

const useLocalities = () => {
  const [result] = useSubscription(
    {
      query: LOCALITIES_SUBSCRIPTION,
    },
    handleLocalitiesSub,
  );

  return result.data || [];
};

const useContiLocality = () => {
  const [result] = useSubscription(
    {
      query: CONTI_SUBSCRIPTION,
    },
    handleLocalitiesSub,
  );

  return result.data || [];
};

export default function LocalitiesProvider(props) {
  const localities = useLocalities();
  const contiLocality = useContiLocality()[0];

  return (
    <LocalitiesContext.Provider
      value={{
        localities,
        contiLocality,
      }}
    >
      {props.children}
    </LocalitiesContext.Provider>
  );
}
