import { Modal, notification, Spin } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'urql';
import styles from './ConfigurationModal.module.css';
import { sanitizeTime } from './sanitizeTime';
import TimePicker from './TimePicker';
import { validateTime } from './validateTime';

const UPDATE_MUTATION = gql`
  mutation($id: uuid!, $obj: time_set_input) {
    update_time(where: { id: { _eq: $id } }, _set: $obj) {
      affected_rows
    }
  }
`;

const INSERT_MUTATION = gql`
  mutation($obj: [time_insert_input!]!) {
    insert_time(objects: $obj) {
      affected_rows
    }
  }
`;

export default function TimesListModal({ time, visible, setVisible, setSelectedTime }) {
  const [value, setValue] = useState(time?.formatted);
  const [loading, setLoading] = useState(false);

  const [, update_time] = useMutation(UPDATE_MUTATION);
  const [, insert_time] = useMutation(INSERT_MUTATION);

  const execute_mutation = () => {
    const parseable = sanitizeTime(value);

    if (validateTime(parseable)) {
      notification['error']({
        message: 'Erro ao inserir horário',
        description: 'Este horário contém valores inválidos',
      });
      return;
    }

    if (time) {
      update_time({ id: time.id, obj: { hour: value } }).then((e) => {
        if (e.data) {
          notification['success']({
            message: 'Horário atualizado com sucesso',
            description: `Esse valor será atualizado no formulário de solicitação`,
          });
          setSelectedTime(null);
          setVisible(false);
          setLoading(false);
        } else {
          notification['error']({
            message: 'Erro ao alterar o horário',
            description: 'Contate o suporte.',
          });
          setSelectedTime(null);
          setLoading(false);
          setVisible(false);
        }
      });
    } else {
      insert_time({ obj: { hour: value } }).then((e) => {
        if (e.data) {
          notification['success']({
            message: 'Horário criado com sucesso',
            description: `Esse valor será atualizado no formulário de solicitação`,
          });
          setSelectedTime(null);
          setLoading(false);
          setVisible(false);
        } else {
          console.log(e);
          notification['error']({
            message: 'Falha ao adicionar o motivo',
            description: 'Contate o suporte.',
          });
          setSelectedTime(null);
          setLoading(false);
          setVisible(false);
        }
      });
    }
  };

  return (
    <Modal
      title="Horário"
      destroyOnClose={true}
      visible={visible}
      okText={loading ? <Spin /> : 'Confirmar'}
      onOk={() => execute_mutation()}
      onCancel={() => {
        setSelectedTime(null);
        setVisible(false);
      }}
    >
      <div style={{ textAlign: 'center', width: '50%', marginLeft: '25%' }}>
        <p className={styles.instruction}>Insira um nome para este horário</p>
        <p className={styles.costType}>{time?.formatted ?? 'Novo horário'}</p>
        <TimePicker label="Horário" time={value} setTime={setValue} />
      </div>
    </Modal>
  );
}
