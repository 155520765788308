import styles from "./PassengerSelect.module.css";
import { Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, { useContext, useMemo } from "react";
import { uniqueId } from "../../pages/Solicitations/Router/helpers/ids";
import { usePassengers } from "../../pages/Historic/usePassengers";
import { UserContext } from "../../contexts/UserContext";

const { Option } = Select;

export const PassengerSelect = ({ name, value, setFieldValue }) => {
    const id = useMemo(() => uniqueId(), [])
    const { user } = useContext(UserContext);
    const userCostCenters = user?.user?.user_cost_centers?.map(({ cost_center }) => cost_center.id) || [];
    const costCenters = [...userCostCenters, user?.cost_center_id].filter(Boolean);
    const { passengers } = usePassengers(costCenters);

    return (
        <div className={styles.container}>
            <label htmlFor={id} className={styles.label}>Colaborador</label>
            <Select showSearch={true}
                    id={id}
                    placeholder="Digite o nome do colaborador (passageiro)"
                    value={value}
                    name={name}
                    allowClear={true}
                    suffixIcon={<SearchOutlined type="search" />}
                    onChange={(value, option) => setFieldValue(name, option?.passenger?.name || null)}>
                {
                    passengers.map(passenger => {
                        const { name, registration } = passenger;

                        return (
                            <Option key={passenger.id} value={`${name} ${registration}`} passenger={passenger}>
                                {name}
                                <br />
                                <b>Matrícula:</b> {registration}
                            </Option>
                        )
                    })
                }
            </Select>
        </div>
    )
}
