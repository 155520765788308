import { UploadOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'urql';
import XLSX from 'xlsx';
import styles from './EditCooperative.module.css';

const INSERT_COSTS_MUTATION = gql`
  mutation($objects: [cooperatives_route_cost_insert_input!]!) {
    insert_cooperatives_route_cost(
      objects: $objects
      on_conflict: { constraint: cooperatives_route_cost_cooperative_id_route_id_key, update_columns: [cost] }
    ) {
      affected_rows
    }
  }
`;

export default function ImportCosts({ routes, cooperative, costs, visible, setVisible }) {
  const [importedCosts, setImportCosts] = useState([]);
  const [file, setFile] = useState('');

  const [, add_costs] = useMutation(INSERT_COSTS_MUTATION);

  const insert_costs = (objs) => {
    add_costs({ objects: objs })
      .then((e) => console.log(e))
      .catch((e) => console.error(e));
  };

  const extractCosts = async (item) => {
    return {
      number: item['A'],
      origin: item['B'].toString().trim(),
      destination: item['C'].toString().trim(),
      cost: item['D'],
    };
  };

  const processFileContent = async (file) => {
    const fileReader = new FileReader();
    fileReader.onload = async (event) => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        const first_sheet_name = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[first_sheet_name];
        const data = XLSX.utils.sheet_to_json(worksheet, {
          header: 'A',
          raw: true,
          defval: null,
        });
        data.shift();

        const costsPromise = data.map(extractCosts);
        let costs = await Promise.all(costsPromise);

        setFile(file.name);
        setImportCosts(costs);
        message.success('Arquivo carregado com sucesso!');
      } catch (e) {
        message.error('Erro com o formato da planilha');
      }
    };
    fileReader.readAsBinaryString(file);
  };

  const onImportExcel = (event) => {
    const { files } = event.target;
    if (files.length === 1) {
      processFileContent(files[0]);
    }
  };

  const insertCosts = (costs) => {
    const toSaveRoutes = routes.map((r) => {
      let route = { route_id: r.id, cooperative_id: cooperative.id, cost: null };

      costs.forEach((c, i) => {
        if (c.number === r.number) {
          route = {
            route_id: r.id,
            cooperative_id: cooperative.id,
            cost: c.cost,
          };
        }
      });

      return route;
    });

    insert_costs(toSaveRoutes);
  };

  const saveImport = () => {
    insertCosts(importedCosts);
    setVisible(false);
  };

  return (
    <Modal
      destroyOnClose={true}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      footer={null}
      title="Importar custos de cooperativa"
      width={800}
    >
      <div className={styles.modalContainer}>
        <div className={styles.modalContent}>
          <h3 className={styles.modalInstruction}>
            Faça o upload da planilha de <strong>Custos de Rotas</strong> mais atualizada
          </h3>
          <input id="file" name="file" onChange={onImportExcel} className={styles.fileUploader} type="file" accept=".xlsx, .xls" />
          <label htmlFor="file">
            <UploadOutlined className={styles.uploadIcon} />
            Escolha um arquivo
          </label>
          <p>{file}</p>
        </div>
        <div className={styles.modalActions}>
          <button disabled={!file} onClick={saveImport} className={styles.saveButton}>
            Salvar importação
          </button>
        </div>
      </div>
    </Modal>
  );
}
