import React, { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
`;

const Description = styled.p`
  color: gray;
  font-weight: 400;
`;

const SolicitationButton = styled.button`
  margin-top: 1rem;
  background: #5a8ba8;
  border-radius: 8px;
  border-color: #5a8ba8;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.75rem;
`;

function Welcome() {
  const { user } = useContext(UserContext);
  const history = useHistory();

  return (
    <Container>
      <h2>Bem-vindo ao Sistema de Táxi, {user.name}!</h2>
      <Description>Faça sua solicitação clicando no botão abaixo</Description>
      <SolicitationButton
        onClick={() => {
          history.push('/new-solicitation');
        }}
      >
        Fazer solicitação
      </SolicitationButton>
    </Container>
  );
}

export default Welcome;
