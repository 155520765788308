import { CaretDownFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Popconfirm, Table, Tooltip } from 'antd';
import gql from 'graphql-tag';
import React, { useContext, useEffect, useState } from 'react';
import ReactExport from 'react-export-excel';
import {
  FaCaretSquareUp,
  FaCarSide,
  FaClock,
  FaEdit,
  FaExclamationTriangle,
  FaFileExcel,
  FaFlagCheckered,
  FaLock,
  FaMapMarkerAlt,
  FaMoneyBillAlt,
  FaRegCaretSquareUp,
  FaShieldAlt,
  FaTrash,
  FaUser,
  FaUserAlt,
} from 'react-icons/fa';
import { useMutation } from 'urql';
import { getUser } from '../../helpers/auth';
import AddressModal from './AddressModal';
import ChangePasswordModal from './ChangePasswordModal';
import styles from './ControlPanel.module.css';
import CreateUserModal from './CreateUserModal';
import ExpandedRow from './ExpandedRow';
import { generateExcelPassengers } from './generateExcelPassengers';
import ImportAddressModal from './Import/ImportAddressModal';
import ImportUsersModal from './Import/ImportUsersModal';
import { useCostCenters } from './useCostCenters';
import { usePassengersSubscription } from './useUsers';
import EditUserModal from "./Import/EditUserModal";
import { UserContext } from "../../contexts/UserContext";
import { UserCreateModal } from "./Import/UserCreateModal";
import { useHistory } from "react-router-dom";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DELETE_PASSENGER_MUTATION = gql`
  mutation($id: uuid!) {
    update_passenger(where: { id: { _eq: $id } }, _set: { deleted_at: "now()" }) {
      affected_rows
    }
  }
`;

const DELETE_USER_MUTATION = gql`
  mutation($user_id: uuid!) {
    delete_user(where: { id: { _eq: $user_id } }) {
      affected_rows
    }
  }
`;

export default function ControlPanel() {
  const history = useHistory();
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [userEditing, setUserEditing] = useState(null);
  const [isOpenedUserCreateModal, setIsOpenedUserCreateModal] = useState(false);
  const { user } = useContext(UserContext);
  const isCurrentUserAdmin = user?.user?.user_permission?.is_admin

  const openUserCreateModal = () => setIsOpenedUserCreateModal(true)
  const closeUserCreateModal = () => setIsOpenedUserCreateModal(false)

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      render: (text, record) => {
        return (
          <span>
            {record.name}{' '}
            {!record.locality && (
              <Tooltip title="Endereço de custo não informado">
                <FaExclamationTriangle className={styles.exclamationIcon} />
              </Tooltip>
            )}
          </span>
        );
      },
    },
    {
      title: 'Matrícula',
      dataIndex: 'registration',
      key: 'registration',
      render: registration => (!registration ? '-' : registration),
    },
    {
      title: 'Centro de Custo',
      dataIndex: 'cost_center',
      key: 'cost_center',
      render: cost_center => (!cost_center ? '-' : `${cost_center.number} - ${cost_center.description}`),
    },
    {
      title: 'Permissões',
      key: 'user.user_permission',
      dataIndex: 'user',
      render: (permissions, passenger) => {
        const { free_time, cooperative, billing, cruds } = passenger.user?.user_permission || {};
        const { is_solicitant } = passenger;
        const { user_cost_centers } = passenger.user || {};

        const icons = (
          <span>
            {is_solicitant && (
              <Tooltip placement="top" title="Permissão para solicitar táxis">
                <FaFlagCheckered className={styles.permissionIcon} />
              </Tooltip>
            )}
            {free_time && (
              <Tooltip placement="top" title="Permissão para horário livre">
                <FaClock className={styles.permissionIcon} />
              </Tooltip>
            )}
            {user_cost_centers && user_cost_centers.length ? (
              <Tooltip placement="top" title="Permissão para centros de custo">
                <FaShieldAlt className={styles.permissionIcon} />
              </Tooltip>
            ) : (
              ''
            )}
            {cooperative && (
              <Tooltip placement="top" title="Permissão para liberar viagens para cooperativa">
                <FaCarSide className={styles.permissionIcon} />{' '}
              </Tooltip>
            )}
            {billing && (
              <Tooltip placement="top" title="Permissão para acessar faturamento">
                <FaMoneyBillAlt className={styles.permissionIcon} />
              </Tooltip>
            )}
            {cruds && (
              <Tooltip placement="top" title="Permissão para acessar cadastros">
                <FaUserAlt className={styles.permissionIcon} />
              </Tooltip>
            )}
          </span>
        );

        return icons;
      },
    },
    {
      title: 'Ações',
      key: 'action',
      render: (text, record) => {
        const currentUserId = getUser();
        const { is_solicitant, name } = record;
        const editUserText = `Editar usuário: ${name}`

        return (
          <>
            {
              isCurrentUserAdmin && (
                <Tooltip placement="top" title={editUserText}>
                  <Button type="text"
                          className={styles.actionButton}
                          aria-label={editUserText}
                          onClick={() => {
                            setUserEditing({ id: record.id, name, registration: record.registration, costCenterId: record.cost_center?.id || null });
                            setShowEditUserModal(true);
                          }}>
                    <FaEdit className={styles.actionIcon} aria-hidden={true} />
                  </Button>
                </Tooltip>
              )
            }

            <Tooltip placement="top" title="Associar endereço ao colaborador">
              <FaMapMarkerAlt
                style={{ cursor: 'pointer' }}
                className={styles.permissionIcon}
                onClick={() => {
                  setSelectedPassenger(record);
                  setAddressModal(true);
                }}
              />
            </Tooltip>
            {is_solicitant && (
              <Tooltip placement="top" title="Alterar senha do colaborador">
                <FaLock
                  style={{ cursor: 'pointer' }}
                  className={styles.permissionIcon}
                  onClick={() => {
                    setSelectedPassenger(record);
                    setChangePassword(true);
                  }}
                />
              </Tooltip>
            )}
            <Popconfirm
              title="Você tem certeza que deseja deletar este usuário?"
              onConfirm={() => {
                exclude_passenger(record.id, record.user_id);
              }}
              okText="Sim"
              cancelText="Não"
            >
              {currentUserId !== record.user_id && (
                <Tooltip placement="top" title="Excluir">
                  <FaTrash style={{ cursor: 'pointer' }} className={styles.permissionIcon} />
                </Tooltip>
              )}
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const [addressModal, setAddressModal] = useState(false);
  const [importAddressModal, setImportAddress] = useState(false);
  const [importUsersModal, setImportUsers] = useState(false);
  const [importing, setImporting] = useState(false);
  const [createUsersModal, setCreateUser] = useState(false);
  const [changePasswordModal, setChangePassword] = useState(false);
  const [selectedPassenger, setSelectedPassenger] = useState({});
  const [delete_passenger_result, delete_passenger] = useMutation(DELETE_PASSENGER_MUTATION);
  const [, delete_user] = useMutation(DELETE_USER_MUTATION);

  const exclude_passenger = (id, user_id) => {
    delete_passenger({ id })
      .then()
      .catch(e => console.error(e));

    if (user_id) {
      delete_user({ user_id })
        .then(e => console.log(e))
        .catch(e => console.log(e));
    }
  };

  const { passengers, setPassengerName } = usePassengersSubscription();
  const { costCenters } = useCostCenters();

  const excelPassengers = generateExcelPassengers(passengers);

  const passengersWithoutLocality = passengers?.filter(({ locality }) => locality === null) || [];

  useEffect(() => {
    if(typeof isCurrentUserAdmin === 'boolean' && !isCurrentUserAdmin) history.goBack()
  }, [isCurrentUserAdmin, history])

  if(!isCurrentUserAdmin) return null

  return (
    <div>
      <div className={styles.pageHeadingContainer}>
        <div>
          <h2 className={styles.pageHeading}>
            Controle de Usuários <span className={styles.pageHeadingCounter}>{passengers ? passengers.length : 0} itens</span>
          </h2>
          <p className={styles.pageImporting}>{importing ? 'Importando...' : ''}</p>
        </div>
        <div className={styles.actions}>
          <button type={'button'} className={styles.primaryButton} onClick={openUserCreateModal}>
            Criar usuário <FaUser aria-hidden={true} />
          </button>

          <Dropdown
            overlay={
              <div className={styles.buttonsContainer}>
                <ul className={styles.buttonsList}>
                  <li onClick={() => setImportUsers(true)} className={styles.buttonElement}>
                    <FaCaretSquareUp className={styles.importIcon} />
                    Importar usuários
                  </li>
                  <li onClick={() => setImportAddress(true)} className={styles.buttonElement}>
                    <FaRegCaretSquareUp className={styles.importIcon} />
                    Importar endereços
                  </li>
                </ul>
              </div>
            }
            placement="bottomRight"
          >
            <button className={styles.primaryButton}>
              Importar <CaretDownFilled className={styles.buttonIcon} />
            </button>
          </Dropdown>
        </div>
      </div>
      <div className={styles.exportContainer}>
        <div className={styles.inputContainer}>
          <Input onChange={e => setPassengerName(e.target.value)} size="large" placeholder="Pesquisar por um colaborador" prefix={<SearchOutlined />} />
        </div>
        <ExcelFile
          fileExtension="xlsx"
          filename={`Continental Táxi - Colaboradores`}
          element={
            <Tooltip title="O arquivo exportado conterá os custos relacionados aos centros de custos que você possui acesso">
              <button className={styles.exportButton}>
                <FaFileExcel style={{ marginRight: '0.25rem' }} />
                Exportar
              </button>
            </Tooltip>
          }
        >
          <ExcelSheet data={excelPassengers} name="Colaboradores">
            <ExcelColumn label="Nome" value="name" />
            <ExcelColumn label="Matrícula" value="registration" />
            <ExcelColumn label="Nº Centro de Custo" value="cost_center_number" />
            <ExcelColumn label="Nome do Centro de Custo" value="cost_center_description" />
            <ExcelColumn label="É solicitante?" value="is_solicitant" />
            <ExcelColumn label="Endereço" value="address" />
            <ExcelColumn label="Localidade" value="locality" />
          </ExcelSheet>
        </ExcelFile>
      </div>
      {!!passengersWithoutLocality.length && (
        <Tooltip title="Insira o endereço corretamente desses colaboradores" placement="topLeft">
          <div className={styles.passengersWithoutLocality}>
            <FaExclamationTriangle className={styles.danger} />
            <p style={{ color: 'red' }}>{passengersWithoutLocality.length} colaboradores sem endereço adequado</p>
          </div>
        </Tooltip>
      )}
      {delete_passenger_result.error ? (
        <p>Erro. Contate o suporte</p>
      ) : (
        <Table
          rowKey="id"
          loading={!passengers}
          columns={columns}
          dataSource={passengers}
          expandedRowRender={record => (
            <ExpandedRow passenger={record} costCenters={costCenters} setSelectedPassenger={setSelectedPassenger} setVisible={setCreateUser} />
          )}
        />
      )}
      {addressModal && <AddressModal visible={addressModal} setVisible={setAddressModal} selectedPassenger={selectedPassenger} />}
      {importAddressModal && (
        <ImportAddressModal
          visible={importAddressModal}
          setVisible={setImportAddress}
          passengers={passengers}
          setImporting={setImporting}
          costCenters={costCenters}
        />
      )}
      {importUsersModal && (
        <ImportUsersModal
          visible={importUsersModal}
          setVisible={setImportUsers}
          passengers={passengers}
          costCenters={costCenters}
          setImporting={setImporting}
        />
      )}

      <EditUserModal
          user={userEditing}
          setUser={setUserEditing}
          isVisible={showEditUserModal}
          setIsVisible={setShowEditUserModal}
          costCenters={costCenters}
      />

      <UserCreateModal
          isOpened={isOpenedUserCreateModal}
          costCenters={costCenters}
          close={closeUserCreateModal}
      />

      {createUsersModal && (
        <CreateUserModal visible={createUsersModal} setVisible={setCreateUser} setSelectedPassenger={setSelectedPassenger} passenger={selectedPassenger} />
      )}
      {changePasswordModal && (
        <ChangePasswordModal
          visible={changePasswordModal}
          setVisible={setChangePassword}
          setSelectedPassenger={setSelectedPassenger}
          passenger={selectedPassenger}
        />
      )}
    </div>
  );
}
