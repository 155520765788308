import { useSubscription } from 'urql';
import gql from 'graphql-tag';

const SOLICITATIONS_QUERY = gql`
  subscription($passenger_id: uuid!) {
    solicitation(
      order_by: { trip_date: desc, time: desc }
      where: { passenger_id: { _eq: $passenger_id }, trip_date: { _lte: "now()" }, deleted_at: { _is_null: true } }
      limit: 3
    ) {
      id
      passenger_name
      passenger_id
      entity_name
      destination
      origin
      time
      trip_date
      reason_id
      cost_center_id
      type
      phone
      reason {
        id
        description
      }
      cost_center {
        id
        description
        number
      }
      observation
      destination_locality_id
      origin_locality_id
    }
  }
`;

export const useSolicitations = (id) => {
  const [result] = useSubscription({
    query: SOLICITATIONS_QUERY,
    variables: { passenger_id: id },
  });

  if (result.error) return [];

  const { solicitation } = result.data ? result.data : {};

  if (solicitation) {
    return solicitation;
  }

  return [];
};
