export const BASE_COST = 'BASE';

export const VIA_COST = 'VIA';

export const EXTREMO_COST = 'EXTREMO';

export const PARKING_COST = 'PARKING';

export const TIME_AVAILABLE_COST = 'HORA À DISPOSIÇÃO';

export const LIMIT_PASSENGERS_SLUG = 'limit_passengers';

export const DISTANCE_TO_EXTREM_COST = process.env.REACT_APP_DISTANCE_TO_EXTREM_COST;

export const MAX_DISTANCE_TO_EXTREME_COST = process.env.REACT_APP_MAX_DISTANCE_TO_EXTREME_COST;

export const REQUIRED_FIELD_MESSAGE = 'Campo obrigatório'

export const DEFAULT_MAXLENGTH = 200
