import { Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { RadioContext, SelectedPassengerContext } from '../../NewSolicitation';
import styles from './CostCenter.module.css';

const { Option } = Select;

export default function CostCenter(props) {
  const radio = useContext(RadioContext);
  const selectedPassenger = useContext(SelectedPassengerContext);
  const { user } = useContext(UserContext);
  const [value, setValue] = useState(selectedPassenger?.cost_center);

  const onChange = (_, option) => {
    const { name, setFieldValue } = props;
    const { key, cost_center } = option;

    setFieldValue(name, key);
    setValue(cost_center);
  };

  useEffect(() => {
    setValue(selectedPassenger?.cost_center);
  }, [selectedPassenger]);

  const cost_centers_list = user?.user?.user_cost_centers;

  if (radio === 'worker') {
    return (
      <>
        <label className={styles.label}>{props.label}</label>
        <div className={styles.inputContainer}>
          <Select
            disabled={!user?.user?.user_permission?.is_admin}
            style={{ width: '100%' }}
            onChange={onChange}
            value={`${value?.number} - ${value?.description}`}
          >
            {cost_centers_list.map(({ cost_center }) => (
              <Option
                cost_center={cost_center}
                key={cost_center.id}
                value={`${cost_center.number} - ${cost_center.description}`}
              >{`${cost_center.number} - ${cost_center.description}`}</Option>
            ))}
          </Select>
        </div>
      </>
    );
  }

  return (
    <>
      <label className={styles.label}>{props.label}</label>
      <div className={styles.inputContainer}>
        <Select
          style={{ width: '100%' }}
          defaultValue={`${user.cost_center.number} - ${user.cost_center.description}`}
          placeholder="Selecione o centro de custo"
          onChange={onChange}
        >
          {cost_centers_list.map(({ cost_center }) => (
            <Option
              key={cost_center.id}
              value={`${cost_center.number} - ${cost_center.description}`}
            >{`${cost_center.number} - ${cost_center.description}`}</Option>
          ))}
        </Select>
      </div>
    </>
  );
}
