import React, { useMemo } from 'react';
import moment from 'moment';
import { Col } from 'antd';
import { FaArrowRight } from 'react-icons/fa';
import styles from './CancelledSolicitationsExpandedRow.module.css';
import { DATE_FORMAT } from "../../../../helpers/date";

export const CancelledSolicitationsExpandedRow = ({ cancelledSolicitation }) => {
  const date = useMemo(() => moment(cancelledSolicitation.created_at).format(`${DATE_FORMAT}[ às ]HH:mm`), [cancelledSolicitation])

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Col>
          <p className={styles.label}>Solicitante</p>
          <p className={styles.title}>{cancelledSolicitation.user.passenger.name} <span className={styles.registration}>Nº {cancelledSolicitation.user.passenger.registration}</span></p>
        </Col>
        <Col>
          <p className={styles.label}>Data da solicitação</p>
          <p className={styles.text}>{date}</p>
        </Col>
        <Col>
          <p className={styles.label}>Telefone</p>
          <p className={styles.text}>{cancelledSolicitation.phone || 'Não fornecido'}</p>
        </Col>
        <Col>
          <p className={styles.label}>Observações</p>
          <p className={styles.text}>{cancelledSolicitation.observation || 'Sem observações'}</p>
        </Col>
      </div>
      <div className={styles.row}>
        <Col>
          <p className={styles.label}>Origem</p>
          <p className={styles.text}>{cancelledSolicitation.origin.address}</p>
        </Col>
        <FaArrowRight aria-hidden={true} />
        <Col>
          <p className={styles.label}>Destino</p>
          <p className={styles.text}>{cancelledSolicitation.destination.address}</p>
        </Col>
      </div>
    </div>
  );
}
