import { CaretDownFilled } from '@ant-design/icons';
import { Checkbox, Dropdown, message } from 'antd';
import gql from 'graphql-tag';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useMutation } from 'urql';
import CostCenterList from './CostCenterList';
import styles from './ExpandedRow.module.css';
import IsSolicitant from './IsSolicitant';
import { useUsersSubscription } from './useUsers';

const FREE_TIME_MUTATION = gql`
  mutation($user_id: uuid!, $free_time: Boolean!) {
    update_user_permission(where: { user_id: { _eq: $user_id } }, _set: { free_time: $free_time }) {
      affected_rows
    }
  }
`;

const COOPERATIVE_MUTATION = gql`
  mutation($user_id: uuid!, $cooperative: Boolean!) {
    update_user_permission(where: { user_id: { _eq: $user_id } }, _set: { cooperative: $cooperative }) {
      affected_rows
    }
  }
`;

const BILLING_MUTATION = gql`
  mutation($user_id: uuid!, $billing: Boolean!) {
    update_user_permission(where: { user_id: { _eq: $user_id } }, _set: { billing: $billing }) {
      affected_rows
    }
  }
`;

const CRUDS_MUTATION = gql`
  mutation($user_id: uuid!, $cruds: Boolean!) {
    update_user_permission(where: { user_id: { _eq: $user_id } }, _set: { cruds: $cruds }) {
      affected_rows
    }
  }
`;

const ALLOW_VISITANT_MUTATION = gql`
  mutation($user_id: uuid!, $allow_visitant: Boolean!) {
    update_user_permission(where: { user_id: { _eq: $user_id } }, _set: { allow_visitant: $allow_visitant }) {
      affected_rows
    }
  }
`;

const DASHBOARD_ACCESS_MUTATION = gql`
  mutation($user_id: uuid!, $dashboard_access: Boolean!) {
    update_user_permission(where: { user_id: { _eq: $user_id } }, _set: { dashboard_access: $dashboard_access }) {
      affected_rows
    }
  }
`;

export default function ExpandedRow({ passenger, costCenters, setVisible, setSelectedPassenger }) {
  const { is_solicitant } = passenger;

  const user = useUsersSubscription(passenger.user_id) || {};

  const { free_time, cooperative, billing, cruds, allow_visitant, dashboard_access } = user.user_permission || {};
  const { user_cost_centers } = user || {};

  const [free_time_result, update_free_time] = useMutation(FREE_TIME_MUTATION);
  const [cooperative_result, update_cooperative] = useMutation(COOPERATIVE_MUTATION);
  const [billing_result, update_billing] = useMutation(BILLING_MUTATION);
  const [cruds_result, update_cruds] = useMutation(CRUDS_MUTATION);
  const [allow_visitant_result, update_allow_visitant] = useMutation(ALLOW_VISITANT_MUTATION);
  const [dashboard_access_result, update_dashboard_access] = useMutation(DASHBOARD_ACCESS_MUTATION);

  if (
    free_time_result.error ||
    cooperative_result.error ||
    billing_result.error ||
    cruds_result.error ||
    allow_visitant_result.error ||
    dashboard_access_result.error
  ) {
    return 'Erro. Contate o suporte';
  }

  const mutate_free_time = (user_id, free_time) => {
    update_free_time({ user_id, free_time })
      .then((e) => {
        console.log(e);
        message.success('Permissão de horário livre atualizada');
      })
      .catch((e) => console.log(e));
  };

  const mutate_cooperative = (user_id, cooperative) => {
    update_cooperative({ user_id, cooperative })
      .then(() => message.success('Permissão de acesso a cooperativas atualizada'))
      .catch((e) => console.log(e));
  };

  const mutate_billing = (user_id, billing) => {
    update_billing({ user_id, billing })
      .then(() => message.success('Permissão de acesso a faturamento atualizada'))
      .catch((e) => console.log(e));
  };

  const mutate_cruds = (user_id, cruds) => {
    update_cruds({ user_id, cruds })
      .then(() => message.success('Permissão de acesso a cadastros atualizada'))
      .catch((e) => console.log(e));
  };

  const mutate_allow_visitant = (user_id, allow_visitant) => {
    update_allow_visitant({ user_id, allow_visitant })
      .then((e) => {
        console.log(e);
        message.success('Permissão a solicitação de visitantes atualizada');
      })
      .catch((e) => console.log(e));
  };

  const mutate_dashboard_access = (user_id, dashboard_access) => {
    update_dashboard_access({ user_id, dashboard_access })
      .then((e) => {
        console.log(e);
        message.success('Permissã de acesso ao dashboard atualizada');
      })
      .catch((e) => console.log(e));
  };

  return (
    <div>
      <p className={styles.rowInstruction}>Defina as permissões de solicitação do sistema deste usuário</p>
      <div className={styles.checkboxContainer}>
        <Grid fluid style={{ padding: '0.5rem' }}>
          <Row between="xs" start="xs">
            <IsSolicitant is_solicitant={is_solicitant} passenger={passenger} setSelectedPassenger={setSelectedPassenger} setVisible={setVisible} />
            {is_solicitant ? (
              <>
                <Col>
                  <Checkbox onChange={(e) => mutate_dashboard_access(passenger.user_id, e.target.checked)} checked={dashboard_access}>
                    Dashboard
                  </Checkbox>
                </Col>
                <Col>
                  <Checkbox onChange={(e) => mutate_free_time(passenger.user_id, e.target.checked)} checked={free_time}>
                    Horário livre
                  </Checkbox>
                </Col>
                <Col>
                  <Checkbox onChange={(e) => mutate_allow_visitant(passenger.user_id, e.target.checked)} checked={allow_visitant}>
                    Solicitação para visitantes
                  </Checkbox>
                </Col>
                <Col>
                  <Checkbox checked={user_cost_centers ? !!user_cost_centers.length : false}>Centro de Custo</Checkbox>
                  <Dropdown
                    trigger="click"
                    overlay={
                      <CostCenterList userId={passenger.user_id} selectedCostCenters={user_cost_centers ? user_cost_centers : []} costCenters={costCenters} />
                    }
                    placement="bottomCenter"
                  >
                    <CaretDownFilled style={{ color: '#909090', cursor: 'pointer' }} />
                  </Dropdown>
                </Col>
                <Col>
                  <Checkbox onChange={(e) => mutate_cooperative(passenger.user_id, e.target.checked)} checked={cooperative}>
                    Liberação para cooperativa
                  </Checkbox>
                </Col>
                <Col>
                  <Checkbox onChange={(e) => mutate_billing(passenger.user_id, e.target.checked)} checked={billing}>
                    Faturamento
                  </Checkbox>
                </Col>
                <Col>
                  <Checkbox onChange={(e) => mutate_cruds(passenger.user_id, e.target.checked)} checked={cruds}>
                    Cadastros
                  </Checkbox>
                </Col>
              </>
            ) : null}
          </Row>
        </Grid>
      </div>
    </div>
  );
}
