import { Button, Dropdown, Input, Modal, notification, Select } from 'antd';
import gql from 'graphql-tag';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'urql';
import { REQUIRED_FIELD_MESSAGE } from "../../../constants";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import { Formik } from 'formik';
import { LocalitiesContext } from "../../../contexts/LocalitiesContext";
import LocalitiesPopover from "../LocalitiesPopover";
import { DownOutlined } from "@ant-design/icons";
import { find } from "lodash";
import styles from './UserCreateModal.module.css';
import { getFinalAddress } from "../geoCode";
import { onlyNumbers } from "../../../helpers/validations";

const { Option } = Select;
const DEFAULT_MAXLENGTH = 200
const REGISTRATION_MAXLENGTH = 10

const INSERT_PASSENGER_MUTATION = gql`
  mutation($objects: [passenger_insert_input!]!) {
    insert_passenger(
      objects: $objects,
      on_conflict: { constraint: passenger_registration_key, update_columns: [name, cost_center_id, locality_id, address_id, is_solicitant, deleted_at] }
    ) {
      affected_rows
    }
  }
`;

const Content = ({ isOpened, costCenters, close }) => {
  const [, insertPassenger] = useMutation(INSERT_PASSENGER_MUTATION);
  const { values: { name, registration, costCenterId, street, district, localityId }, setFieldValue, isValid, ...formik } = useFormikContext()
  const { localities } = useContext(LocalitiesContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFindingAddress, setIsFindingAddress] = useState(false);
  const [address, setAddress] = useState(null);
  const hasAddress = useMemo(() => !!address, [address])
  const localityName = useMemo(() => find(localities, { id: localityId })?.name, [localities, localityId])
  const isInvalid = useMemo(() => isSubmitting || isFindingAddress || !hasAddress || !isValid, [isSubmitting, isFindingAddress, hasAddress, isValid])
  const isFindAddressDisabled = useMemo(() => isFindingAddress || hasAddress || !street || !district || !localityId, [isFindingAddress, hasAddress, street, district, localityId])

  const clearAddress = () => setAddress(null)

  const findAddress = async () => {
    if(isFindAddressDisabled) return

    setIsFindingAddress(true)

    try{
      const finalAddress = await getFinalAddress(`${street} ${district} ${localityName}`)

      if(!finalAddress) throw new Error('Request address not found')

      setAddress(finalAddress)
    } catch(error){
      console.error(error)
      notification.error({ message: 'Ocorreu um problema ao obter o endereço pelo sistema, tente novamente, por favor.' });
    } finally{
      setIsFindingAddress(false)
    }
  }

  const submit = async event => {
    event.preventDefault()

    if(isInvalid) return

    setIsSubmitting(true)

    try{
      const response = await insertPassenger({
        objects: [{
          name,
          registration,
          cost_center_id: costCenterId,
          locality_id: localityId,
          address: hasAddress ? { data: address } : undefined
        }]
      })

      if(typeof response.data.insert_passenger.affected_rows !== 'number') throw new Error('Request create error')

      close()
      notification.success({ message: 'Sucesso ao criar usuário.' });
    } catch(error){
      console.error(error)
      notification.error({ message: 'Ocorreu um problema ao criar usuário, reveja o formulário e tente novamente, por favor.' });
    } finally{
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if(!isOpened) formik.resetForm()
  }, [isOpened])

  return (
      <Modal
          title={'Criar usuário'}
          width={1200}
          footer={null}
          destroyOnClose={true}
          visible={isOpened}
          onCancel={close}
      >
        <form onSubmit={submit} className={'form'}>
          <div className={styles.fieldsContainer}>
            <div className={'field'}>
              <label htmlFor={'fieldName'}>Nome:</label>

              <Input
                  id={'fieldName'}
                  type={'text'}
                  placeholder={'Coloque o nome'}
                  size={'large'}
                  value={name}
                  maxLength={DEFAULT_MAXLENGTH}
                  required={true}
                  onChange={event => setFieldValue('name', event.currentTarget.value)}
              />

              <span className={'hint'}>{REQUIRED_FIELD_MESSAGE}</span>
            </div>

            <div className={'field'}>
              <label htmlFor={'fieldRegistration'}>Matrícula:</label>

              <Input
                  id={'fieldRegistration'}
                  type={'number'}
                  placeholder={'Coloque a matrícula'}
                  size={'large'}
                  max={9999999999}
                  maxLength={REGISTRATION_MAXLENGTH}
                  required={true}
                  value={registration}
                  onChange={event => setFieldValue('registration', onlyNumbers(event.currentTarget.value))}
              />

              <span className={'hint'}>{REQUIRED_FIELD_MESSAGE}</span>
            </div>
          </div>

          <div className={'field'}>
            <label htmlFor={'fieldCostCenter'}>Centro de custo:</label>

            <Select
                id={'fieldCostCenter'}
                placeholder={'Escolha o centro de custo'}
                size={'large'}
                required={true}
                value={costCenterId}
                onChange={value => setFieldValue('costCenterId', value)}
            >
              {costCenters.map(({ id, ...costCenter }) => <Option key={id} value={id}>{costCenter.number} - {costCenter.description}</Option>)}
            </Select>

            <span className={'hint'}>{REQUIRED_FIELD_MESSAGE}</span>
          </div>

          <div className={styles.fieldsContainer}>
            <div className={'field'}>
              <label htmlFor={'fieldStreet'}>Rua e número:</label>

              <Input
                  id={'fieldStreet'}
                  type={'text'}
                  placeholder={'Coloque a rua e número, exemplo: "Rua Pedro Paulo, 18"'}
                  size={'large'}
                  value={street}
                  maxLength={DEFAULT_MAXLENGTH}
                  required={true}
                  onChange={event => {
                    clearAddress()
                    setFieldValue('street', event.currentTarget.value)
                  }}
              />

              <span className={'hint'}>{REQUIRED_FIELD_MESSAGE}</span>
            </div>

            <div className={'field'}>
              <label htmlFor={'fieldDistrict'}>Bairro:</label>

              <Input
                  id={'fieldDistrict'}
                  type={'text'}
                  placeholder={'Coloque o bairro'}
                  size={'large'}
                  value={district}
                  maxLength={DEFAULT_MAXLENGTH}
                  required={true}
                  onChange={event => {
                    clearAddress()
                    setFieldValue('district', event.currentTarget.value)
                  }}
              />

              <span className={'hint'}>{REQUIRED_FIELD_MESSAGE}</span>
            </div>
          </div>

          <Dropdown trigger="click" overlay={<LocalitiesPopover localities={localities} setLocality={({ id }) => {
            clearAddress()
            setFieldValue('localityId', id)
          }} />}>
            <p className={styles.locality}>
              Localidade: <span className={styles.localityName}>{localityName || 'Não especificado'} <DownOutlined className={styles.localityIcon} /></span>
            </p>
          </Dropdown>

          {hasAddress && <p className={styles.address}><span className={styles.addressTitle}>Endereço encontrado pelo sistema:</span> <strong>{address.description}</strong></p>}

          <div className={styles.actions}>
            <Button htmlType={'button'} onClick={close}>Fechar</Button>

            {
              hasAddress ?
              <Button htmlType={'submit'} type={'primary'} loading={isSubmitting} disabled={isInvalid}>Criar usuário</Button> :
              <Button htmlType={'button'} type={'primary'} loading={isFindingAddress} disabled={isFindAddressDisabled} onClick={findAddress}>Encontrar endereço</Button>
            }
          </div>
        </form>
      </Modal>
  )
}

export const UserCreateModal = props => {
  const schema = Yup.object().shape({
    name: Yup.string().max(DEFAULT_MAXLENGTH).required(),
    registration: Yup.string().max(REGISTRATION_MAXLENGTH).required(),
    costCenterId: Yup.string().required(),
    street: Yup.string().max(DEFAULT_MAXLENGTH).required(),
    district: Yup.string().max(DEFAULT_MAXLENGTH).required(),
    localityId: Yup.string().required(),
  })

  return (
      <Formik initialValues={{ name: '', registration: null, costCenterId: null, street: '', district: '', localityId: null }}
              validationSchema={schema}
              onSubmit={null}>
        <Content {...props} />
      </Formik>
  );
}
