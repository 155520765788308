export const isIllegalSolicitation = (solicitation) => {
  if (solicitation.origin.hasOwnProperty('isHomeAddress') && solicitation.destination.hasOwnProperty('isWorkAddress')) {
    const homeOrigin = solicitation.origin.isHomeAddress;
    const homeDestination = solicitation.destination.isHomeAddress;
    const workOrigin = solicitation.origin.isWorkAddress;
    const workDestination = solicitation.destination.isWorkAddress;

    if ((homeOrigin && workDestination) || (workOrigin && homeDestination)) {
      return false;
    }

    return true;
  } else {
    return false;
  }
};
