import gql from 'graphql-tag';
import React from 'react';
import { useSubscription } from 'urql';
import { getUser } from '../helpers/auth';

const USER_QUERY = gql`
  subscription getUserInfo($user_id: uuid!) {
    passenger(where: { user_id: { _eq: $user_id } }) {
      id
      registration
      name
      cost_center_id
      user_id
      user {
        id
        user_permission {
          is_admin
          free_time
          cooperative
          cruds
          billing
          allow_visitant
          dashboard_access
        }
        user_cost_centers(where: { user_id: { _eq: $user_id } }) {
          cost_center {
            id
            number
            description
          }
        }
      }
      cost_center {
        id
        number
        description
      }
    }
  }
`;

export const UserContext = React.createContext();

const handleUser = (passenger = [], response) => {
  return response.passenger;
};

const useUser = () => {
  const user_id = getUser();

  const [result] = useSubscription(
    {
      query: USER_QUERY,
      variables: { user_id },
    },
    handleUser,
  );

  if (result.error) return {};

  const passenger = result.data ? result.data[0] : {};

  return passenger;
};

export default function UserProvider(props) {
  const user = useUser();

  return (
    <UserContext.Provider
      value={{
        user,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
