  
import styled from 'styled-components';
import Scrollbar from 'react-scrollbars-custom';

const CustomScrollbar = styled(Scrollbar)`
  --track-color: #d6d6d6;
  --thumb-color: #FFA500;
  height: 100%;
  &,
  & .ScrollbarsCustom-ThumbY {
    width: 100%;
  }
  & > .ScrollbarsCustom-Wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  & .ScrollbarsCustom-Track {
    position: absolute;
  }
  & .ScrollbarsCustom-Track,
  & .ScrollbarsCustom-Thumb {
    border-radius: 0.25rem;
  }
  & .ScrollbarsCustom-Thumb {
    cursor: pointer;
    background-color: var(--thumb-color);
    transition: opacity 0.3s ease-in-out;
    &:hover,
    .dragging {
      opacity: 0.7;
    }
  }
  & .ScrollbarsCustom-TrackY {
    width: 4px;
    top: 1rem;
    bottom: 1rem;
    right: 0;
    background-color: var(--track-color);
  }
  &.trackYVisible > .ScrollbarsCustom-Wrapper {
    right: 10px;
  }
`;

CustomScrollbar.defaultProps = {
  noScrollX: true,
  noDefaultStyles: true,
};

export default CustomScrollbar;