import { simpleClient } from '../../../../helpers/auth';
import { QUERY_COST, QUERY_ROUTE } from './queries';

export const getValuesOfSolicitations = async (t) => {
  let costValues = [];

  for (let solicitation of t.solicitations) {
    const { origin_locality, destination_locality } = solicitation;
    const origin_name = origin_locality.name;
    const destination_name = destination_locality.name;

    // RETORNA ROTA ALOCADA A ESSA VIAGEM
    const variablesRoutes = { origin: origin_name, destination: destination_name };
    const { route } = await simpleClient.request(QUERY_ROUTE, variablesRoutes);

    if (!route.length) {
      throw Error({ error: true, trip: t });
    }

    // RETORNA CUSTO DA ROTA ALOCADA
    const variableCost = { route_id: route[0].id, cooperative_id: (t.cooperative_id || t.cooperative?.id) };
    const { cooperatives_route_cost } = await simpleClient.request(QUERY_COST, variableCost);
    const { cost } = cooperatives_route_cost.find(({ cost }) => typeof cost === 'number') ?? { cost: 0 }

    costValues.push(cost);
  }

  return costValues;
};
