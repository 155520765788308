import { Popconfirm } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { FaPen } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation } from 'urql';
import EditableInput from '../../components/EditableInput/EditableInput';
import styles from './Card.module.css';
import ChangePasswordModal from './ChangePasswordModal';
import { CNPJMask } from './InputCNPJ';

const ChangePassword = styled.span`
  margin-right: 0.5rem;
  cursor: pointer;
  color: #007cc2;
`;

const UPDATE_COOPERATIVE = gql`
  mutation($id: uuid!, $obj: cooperatives_set_input) {
    update_cooperatives(where: { id: { _eq: $id } }, _set: $obj) {
      affected_rows
    }
  }
`;

export default function Card({ cooperative }) {
  const [, update_mutation] = useMutation(UPDATE_COOPERATIVE);
  const [passwordModal, setPasswordModal] = useState(false);

  const deleteCooperative = () => {
    const id = cooperative.id;
    const obj = { deleted_at: 'now()' };

    update_mutation({ id, obj })
      .then((e) => console.log(e))
      .catch((e) => console.error(e));
  };

  const updateName = (value) => {
    const obj = { name: value };
    const id = cooperative.id;
    update_mutation({ id, obj })
      .then((e) => console.log(e))
      .catch((e) => console.error(e));
  };

  const updateCNPJ = (value) => {
    const obj = { CNPJ: value };
    const id = cooperative.id;
    update_mutation({ id, obj })
      .then((e) => console.log(e))
      .catch((e) => console.error(e));
  };

  return (
    <div className={styles.cardContainer}>
      <div>
        <div style={{ marginBottom: '0.5rem' }}>
          <EditableInput
            value={cooperative.name}
            handleUpdate={updateName}
            original={<h3 className={styles.cardTitle}>{cooperative.name}</h3>}
            editIcon={<FaPen className={styles.editIcon} />}
          />
        </div>
        <EditableInput
          value={cooperative.CNPJ}
          handleUpdate={updateCNPJ}
          original={
            <p className={styles.cardDescription}>
              <span style={{ fontWeight: 'bold' }}>CNPJ:</span> {cooperative.CNPJ}
            </p>
          }
          editIcon={<FaPen className={styles.editIconCNPJ} />}
          mask={CNPJMask}
        />
      </div>
      <div>
        <ChangePassword onClick={() => setPasswordModal(true)}>Alterar senha</ChangePassword>
        <Link to={`/cooperatives/${cooperative.id}`}>
          <button className={styles.editButton}>Alterar custos</button>
        </Link>
        <Popconfirm title="Você tem certeza que deseja deletar?" onConfirm={deleteCooperative} okText="Sim, quero" cancelText="Não">
          <button className={styles.deleteButton}>Excluir</button>
        </Popconfirm>
      </div>
      {passwordModal && <ChangePasswordModal cooperative={cooperative} visible={passwordModal} setVisible={setPasswordModal} />}
    </div>
  );
}
