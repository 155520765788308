import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { validateTime } from "../../NewSolicitation/helpers/validate-date-time";

const CANCELLED_SOLICITATIONS_QUERY = gql`
  query($startAt: date, $endAt: date, $time: String, $passenger: String, $userId: uuid) {
    solicitation(
      where: { trip_date: { _gte: $startAt, _lte: $endAt }, time: { _eq: $time }, passenger_name: { _eq: $passenger }, solicitant_id: { _eq: $userId }, deleted_at: { _is_null: false } },
      order_by: { trip_date: asc, time: asc }
    ) {
      id
      number
      passenger_name
      entity_name
      trip_date
      time
      phone
      state
      observation
      recurrent_solicitation_id
      created_at
      origin
      origin_locality {
        id
        name
      }
      destination
      destination_locality {
        id
        name
      }
      passenger {
        name
        id
        registration
      }
      cost_center {
        id
        description
        number
      }
      reason {
        id
        description
      }
      user {
        passenger {
          name
          registration
          id
        }
      }
      user_who_deleted {
        passenger {
          name
        }
      }
    }
  }
`;

export const useCancelledSolicitations = ({ date: [startAt, endAt], time, passenger, cooperative, userId }) => {
  if(!time || validateTime({ time })) time = null

  const [result, refreshQuery] = useQuery(
    {
      query: CANCELLED_SOLICITATIONS_QUERY,
      variables: { startAt, endAt, time, passenger, userId: !cooperative ? userId : null },
      requestPolicy: 'cache-and-network',
    },
    (_, { solicitation }) => solicitation,
  );

  const refresh = () => refreshQuery({ requestPolicy: 'network-only' });

  return { ...result, refresh };
}
