import React from 'react';
import CurrencyFormat from 'react-currency-format';
import styles from './EditTripModal.module.css';

export default function Recipe({ extremCost, viaCost, hourCost, parkingCost, baseCost }) {
  const totalCost = extremCost + viaCost + (hourCost || 0) + parkingCost + baseCost?.value;

  return (
    <div className={styles.costsContainer}>
      <div className={styles.row}>
        <div>
          <p>Valor calculado</p>
        </div>
        <div className={styles.middleColumn}></div>
        <div>
          <p>R$ {baseCost?.value}</p>
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <p className={styles.col}>Extremo</p>
        </div>
        <div className={styles.middleColumn}></div>
        <div>
          <p className={styles.col}>R$ {extremCost}</p>
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <p className={styles.col}>Via</p>
        </div>
        <div className={styles.middleColumn}></div>
        <div>
          <p className={styles.col}>R$ {viaCost?.toFixed(2)}</p>
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <p className={styles.col}>Hora à disposição</p>
        </div>
        <div className={styles.middleColumn}></div>
        <div>
          <p className={styles.col}>R$ {hourCost || 0}</p>
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <p className={styles.col}>Estacionamento</p>
        </div>
        <div className={styles.middleColumn}></div>
        <div>
          <p className={styles.col}>R$ {parkingCost}</p>
        </div>
      </div>
      <div className={styles.sumContainer}>
        <h3 className={styles.total}>TOTAL</h3>
        <span className={styles.totalValue}>
          <CurrencyFormat value={totalCost} displayType={'text'} thousandSeparator={true} prefix={'R$ '} decimalScale={2} fixedDecimalScale={true} />
        </span>
      </div>
    </div>
  );
}
