import React from 'react';
import styled from 'styled-components';

const CardLayout = styled.div`
  height: 6rem;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  width: 100%;
  margin-right: 0.5rem;
`;

const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CardTitle = styled.h4`
  margin-top: 0.3rem;
  color: gray;
`;

const CardValue = styled.p`
  text-align: right;
  font-weight: bold;
  font-size: 1rem;
`;

interface Props {
  icon?: React.ReactNode;
  title: string;
  value: string | number;
}

const Card = (props: Props) => {
  return (
    <CardLayout>
      <CardTitleContainer>
        {props.icon}
        <CardTitle>{props.title}</CardTitle>
      </CardTitleContainer>
      <CardValue>{props.value}</CardValue>
    </CardLayout>
  );
};

export default Card;
