import gql from 'graphql-tag';
import { useQuery } from 'urql';

const SOLICITATIONS_QUERY = gql`
  query($startAt: timestamptz, $endAt: timestamptz, $userId: uuid, $passengerId: uuid) {
    recurrent_solicitation(where: { user_id: { _eq: $userId }, created_at: { _lte: $endAt, _gte: $startAt }, passenger_id: { _eq: $passengerId } }) {
      id
      attributes
      created_at
      recurrence_config
      type
      passenger {
        name
      }
    }
  }
`;

const handleSolicitation = (_, response) => {
  return response.recurrent_solicitation;
};

export const useRecurrentSolicitations = (rangeDate, cooperativePermission, user_id, passengerId) => {
  const userId = cooperativePermission ? null : user_id;

  const [result] = useQuery(
    {
      query: SOLICITATIONS_QUERY,
      requestPolicy: 'cache-and-network',
      variables: {
        startAt: rangeDate[0].toISOString(),
        endAt: rangeDate[1].toISOString(),
        userId,
        passengerId,
      },
    },
    handleSolicitation,
  );

  return result;
};
