import moment from 'moment';
import { sanitizeTime } from '../components/TimePicker/sanitizeTime';

moment.locale('pt-br');

const defaultDateFormat = 'YYYY-MM-DD'

export const validateReturn = (values) => {
  if (!values.return_time) return;

  const departureDate = moment(values.trip_date);
  const arrivalDate = moment(values.return_date);
  const departureTimeHours = sanitizeTime(values.time).parseable[0];
  const departureTimeMinutes = sanitizeTime(values.time).parseable[1];
  const arrivalTimeHours = sanitizeTime(values.return_time).parseable[0];
  const arrivalTimeMinutes = sanitizeTime(values.return_time).parseable[1];

  if (arrivalDate.isBefore(departureDate)) {
    return true;
  }

  if (arrivalDate && departureDate && arrivalDate.toDate().toLocaleDateString() === departureDate.toDate().toLocaleDateString()) {
    if (departureTimeHours > arrivalTimeHours) {
      return true;
    }

    if (departureTimeHours === arrivalTimeHours) {
      if (departureTimeMinutes >= arrivalTimeMinutes) {
        return true;
      }
    }
  }
};

export const parseStringTime = (time) => {
  const sanitized = sanitizeTime(time);

  const decimalMinutes = sanitized.parseable[1] / 60;

  return sanitized.parseable[0] + decimalMinutes;
};

export const validateAntecedence = ({ trip_date, time }, antecedenceTime, isAdmin) => {
  if (isAdmin) return false;

  const date = moment(`${trip_date.format(defaultDateFormat)} ${time}`, `${defaultDateFormat} HH:mm`);
  const antecedenceDate = moment().add(parseStringTime(antecedenceTime), 'hours');

  antecedenceDate.set({ seconds: 0, milliseconds: 0 })

  return !date.isSameOrAfter(antecedenceDate)
};

export const validatePast = (values) => {
  const departureDate = moment(values.trip_date).format('YYYY-MM-DD');

  if (moment(departureDate).endOf('day').valueOf() < moment().valueOf()) {
    console.log('passado');
    return true;
  }
};

export const validateTime = (values) => {
  const departureTimeHours = sanitizeTime(values.time).parseable[0];
  const departureTimeMinutes = sanitizeTime(values.time).parseable[1];

  if (departureTimeHours > 23) {
    return true;
  }

  if (departureTimeMinutes > 59) {
    return true;
  }

  if(values.time.length != 5){
    return true;
  }
};
