import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from '../../pages/Configuration/Configuration';
import ControlPanel from '../../pages/ControlPanel/ControlPanel';
import Cooperatives from '../../pages/Cooperatives/Cooperatives';
import EditCooperative from '../../pages/Cooperatives/EditCooperative/EditCooperative';
import CostCenters from '../../pages/CostCenters/CostCenters';
import Historic from '../../pages/Historic/Historic';
import HomeWrapper from '../../pages/Home';
import Login from '../../pages/Login/Login';
import NewSolicitation from '../../pages/NewSolicitation/NewSolicitation';
import NewRecurrentSolicitation from '../../pages/RecurrentSolicitation/RecurrentSolicitation';
import Solicitations from '../../pages/Solicitations/Solicitations';
import Template from './Template';

function Routes() {
  return (
    <>
      <Template>
        <Route path="/login">
          <Login />
        </Route>
        <Route exact path="/">
          <HomeWrapper />
        </Route>
        <Route path="/trips">
          <Historic />
        </Route>
        <Route path="/control-panel">
          <ControlPanel />
        </Route>
        <Route path="/cost-centers">
          <CostCenters />
        </Route>
        <Route path="/new-solicitation">
          <NewSolicitation />
        </Route>
        <Route path="/recurrent-solicitations">
          <NewRecurrentSolicitation />
        </Route>
        <Route path="/solicitations">
          <Solicitations />
        </Route>
        <Route exact path="/cooperatives">
          <Cooperatives />
        </Route>
        <Route path="/cooperatives/:id">
          <EditCooperative />
        </Route>
        <Route path="/configuration">
          <Configuration />
        </Route>
      </Template>
    </>
  );
}

export default Routes;
