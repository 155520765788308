import gql from 'graphql-tag';
import { useState } from 'react';
import { useQuery, useSubscription } from 'urql';

const USERS_QUERY = gql`
  query {
    user {
      id
      email
      passenger {
        name
        registration
        cost_center {
          description
          id
          number
        }
      }
      user_permission {
        billing
        cruds
        cooperative
        free_time
        is_solicitant
      }
      user_cost_centers {
        cost_center_id
        cost_center {
          description
          id
        }
      }
    }
  }
`;

const PASSENGERS_SUBSCRIPTION = gql`
  subscription($search: String!) {
    passenger(
      where: { _or: [{ name: { _ilike: $search } }, { registration: { _ilike: $search } }], deleted_at: { _is_null: true } }
      order_by: [{ name: asc }]
    ) {
      id
      name
      user_id
      registration
      is_solicitant
      address {
        description
        id
        latitude
        longitude
      }
      cost_center {
        description
        number
        id
      }
      user {
        user_permission {
          billing
          cooperative
          cruds
          free_time
          is_admin
        }
        user_cost_centers {
          id
        }
      }
      locality {
        name
        id
      }
    }
  }
`;

const USERS_SUBSCRIPTION = gql`
  subscription($id: uuid!) {
    user(where: { id: { _eq: $id } }) {
      id
      email
      user_cost_centers {
        cost_center_id
        cost_center {
          description
          number
        }
      }
      user_permission {
        billing
        cooperative
        cruds
        free_time
        is_admin
        allow_visitant
        dashboard_access
      }
    }
  }
`;

const handleUsersSub = (users = [], response) => {
  return response.user;
};

export const useUsersSubscription = (user_id) => {
  const [res] = useSubscription({ query: USERS_SUBSCRIPTION, variables: { id: user_id } }, handleUsersSub);

  if (!res.data) return { user: [] };

  return res.data[0];
};

const handlePassengersSub = (passengers = [], response) => {
  return response.passenger;
};

export const usePassengersSubscription = () => {
  const [passengerName, setPassengerName] = useState('');
  const [res] = useSubscription(
    { query: PASSENGERS_SUBSCRIPTION, variables: { search: `%${passengerName}%` } },
    handlePassengersSub,
  );

  const passengers =
    res.data?.sort((a, b) => {
      const c = a.locality && 1;
      const d = b.locality && 1;

      return c - d;
    }) ?? [];

  return { passengers, setPassengerName };
};

export const useUsers = () => {
  const [result] = useQuery({
    query: USERS_QUERY,
    requestPolicy: 'cache-and-network',
  });

  if (result.fetching) return;

  if (result.error) return;

  const { user } = result.data;

  return user;
};
