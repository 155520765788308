import { Checkbox, Tooltip, Row, Col, message } from 'antd';
import React from 'react';
import { FaPen } from 'react-icons/fa';
import EditableInput from '../../components/EditableInput/EditableInput';
import { EXTREMO_COST, VIA_COST } from '../../constants';
import styles from './EditTripModal.module.css';
import { useMutation } from 'urql';
import gql from 'graphql-tag';

const COST_UPDATE = gql`
  mutation($id: uuid!, $value: numeric) {
    update_cost(where: { id: { _eq: $id } }, _set: { value: $value }) {
      affected_rows
    }
  }
`;

export default function EditCosts({
  extremDisable,
  viaDisable,
  isChecked,
  via_cost,
  extrem_cost,
  hour_cost,
  parking_cost,
  disapprove_mutation,
  amount,
  parkingCost,
  baseCost,
  costs,
}) {

  var isCheckedExtrem = isChecked(costs, EXTREMO_COST)

  const [, update_cost] = useMutation(COST_UPDATE);

  const handle_update = (value) => {
    const id = baseCost?.id;

    update_cost({ id, value }).then((e) => {
      console.log(e);
      if (e.data) {
        message.success('Custo atualizado com sucesso');
        disapprove_mutation();

        if(isCheckedExtrem){
          extrem_cost(isCheckedExtrem, 'EXTREMO', true, value)
        }
      } else {
        message.error('Erro ao atualizar o custo');
      }
    });
  };

  return (
    <>
      <div className={styles.baseCostContainer}>
        <h4 className={styles.subtitle}>Custo-base</h4>
        <div className={styles.inputContainer}>
          <label className={styles.labelInput}>Valor</label>
          <EditableInput
            isNumber={true}
            original={
              <p className={styles.value}>
                <span>R$ {baseCost?.value}</span>
              </p>
            }
            value={baseCost?.value}
            handleUpdate={handle_update}
            editIcon={<FaPen className={styles.editIcon} />}
          />
        </div>
      </div>
      <Row>
        <Col span={12} className={styles.extraCostsContainer}>
          <h4 className={styles.subtitle}>Custos extras em porcentagem (%)</h4>
          <div>
            <Tooltip title={extremDisable() ? 'Viagem com várias localidades não recebe custo de EXTREMO' : 'Adicionar custo de EXTREMO'}>
              <Checkbox disabled={extremDisable()} checked={isChecked(costs, EXTREMO_COST)} onChange={(e) => {
                extrem_cost(e.target.checked, 'EXTREMO')
                isCheckedExtrem = e.target.checked
                }}>
                Extremo
              </Checkbox>
            </Tooltip>
            <Tooltip title={viaDisable() ? 'Viagem com localidade única não recebe custo de VIA' : 'Adicionar custo de VIA'}>
              <Checkbox disabled={viaDisable()} checked={isChecked(costs, VIA_COST)} onChange={(e) => via_cost(e.target.checked)}>
                Via
              </Checkbox>
            </Tooltip>
          </div>
        </Col>
        <Col span={12} className={styles.extraCostsContainer}>
          <h4 className={styles.subtitle}>Custos extras</h4>
          <div className={styles.inputContainer}>
            <label className={styles.labelInput}>Estacionamento</label>
            <EditableInput
              isNumber={true}
              original={
                <p className={styles.value}>
                  <span>R$ {parkingCost}</span>
                </p>
              }
              value={parkingCost}
              handleUpdate={parking_cost}
              editIcon={<FaPen className={styles.editIcon} />}
            />
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelInput}>Horas à disposição</label>
            <EditableInput
              isNumber={true}
              original={
                <p className={styles.value}>
                  <span>{`${amount || 0} h`}</span>
                </p>
              }
              value={amount}
              handleUpdate={hour_cost}
              editIcon={<FaPen className={styles.editIcon} />}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
