import { geocodeByAddress } from 'react-google-places-autocomplete';

export const getCity = async (address) => {
  let city;

  const completeAddress = await geocodeByAddress(address);

  completeAddress[0].address_components.forEach((el) => {
    const types = el.types;
    if (types.find((t) => t === 'administrative_area_level_2')) {
      city = el.long_name;
    }
  });

  return city;
};

export const inferLocality = async (address, localities) => {
  const city = await getCity(address);

  const locality = localities.find((l) => l.name?.toLowerCase().trim() === city?.toLowerCase());

  return locality;
};
