import { Button, DatePicker, notification, Popconfirm, Table, Tooltip } from 'antd';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactExport from 'react-export-excel';
import { FaBan, FaClock, FaEdit, FaFileExcel, FaRedoAlt } from 'react-icons/fa';
import { useMutation } from 'urql';
import { ConfigurationContext } from '../../../contexts/ConfigurationContext';
import { UserContext } from '../../../contexts/UserContext';
import GroupSolicitations from '../Router/GroupSolicitations';
import { routerize } from '../Router/helpers/routerize';
import { TripsContext } from '../Router/TripsContextProvider.jsx';
import usePendentSolicitations from '../usePendentSolicitations';
import ExpandedRow from './ExpandedRow';
import { generateExcelSolicitations } from './generateExcelSolicitations';
import styles from './PendentSolicitations.module.css';
import styled from 'styled-components';
import { useSolicitationsContext } from "../../../providers/SolicitationsProvider";
import { focus } from "../Router/helpers/elements";
import TimePicker from "../../NewSolicitation/components/TimePicker/TimePicker";
import { useFormikContext } from "formik";
import { PassengerSelect } from "../../../components/PassengerSelect/PassengerSelect";
import { getDifferenceInHours } from "../../../helpers/date";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { RangePicker } = DatePicker;

const Refresh = styled.button`
  display: flex;
  align-items: center;
  column-gap: var(--small-space);
  color: #4e99c5;
  background-color: white;
  border: 1px solid white;
  font-size: 14px;
  cursor: pointer;
  margin: 0;
`;

const Amount = styled.span`
  margin-left: 1rem;
  font-weight: bold;
  color: gray;
`;

const DELETE_SOLICITATION_MUTATION = gql`
  mutation($id: uuid!, $userWhoDeletedId: uuid!) {
    update_solicitation(where: { id: { _eq: $id } }, _set: { user_who_deleted_id: $userWhoDeletedId, deleted_at: "now()" }) {
      affected_rows
      returning {
        id
        deleted_at
      }
    }
  }
`;

export default function PendentSolicitations() {
  const { setEditSolicitation, setShowEditSolicitationModal } = useSolicitationsContext();
  const { setTrips, setLoadingRouter } = useContext(TripsContext);
  const { configuration } = useContext(ConfigurationContext);
  const { user } = useContext(UserContext);
  const formik = useFormikContext();
  const [isDeletingSelectedSolicitations, setIsDeletingSelectedSolicitations] = useState(false);
  const deleteSelectedSolicitationsPopupId = 'deleteSelectedSolicitationsPopup'
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [rangeDate, setRangeDate] = useState([moment().startOf('day'), moment().endOf('day').add(3, 'days')]);
  const { time, passenger } = formik.values;

  const cooperative = user?.user?.user_permission?.cooperative;
  const userId = user?.user_id;

  const { error, data, fetching, refresh } = usePendentSolicitations(rangeDate, time, passenger, cooperative, userId);
  const solicitations = data?.solicitation || [];
  const [, delete_solicitation] = useMutation(DELETE_SOLICITATION_MUTATION);

  const limitPassengers = useMemo(() => {
    return Number(configuration.filter((config) => config.name === 'limit_passengers')[0]?.value);
  }, [configuration]);

  const isOverOrEqualThanOneHour = (tripDate, time) => getDifferenceInHours(new Date(`${tripDate}T${time}`)) >= 1

  const selectedSolicitationsToCancel = useMemo(() => selected.filter(({ trip_date, time }) => isOverOrEqualThanOneHour(trip_date, time)), [selected]);

  const exclude_solicitation = (id) => {
    delete_solicitation({ id, userWhoDeletedId: userId })
      .then((e) => {
        console.log(e);
        if (e.data) {
          notification['success']({
            message: 'Solicitação cancelada',
            description: 'Essa solicitação foi cancelada com sucesso',
          });
        }
      })
      .catch((e) => console.error(e));
  };

  const deleteSelectedSolicitations = async () => {
    setIsDeletingSelectedSolicitations(true)

    const deletePromises = selectedSolicitationsToCancel.map(({ id }) => delete_solicitation({ id, userWhoDeletedId: userId }));
    const results = await Promise.allSettled(deletePromises)

    results.forEach(({ value }) => {
      const hasError = !!value.error?.name
      const { id } = value.operation.variables
      const { number } = selectedSolicitationsToCancel.find(solicitation => solicitation.id === id)

      if(hasError) notification.error({ message: `Ocorreu um problema ao cancelar a solicitação de número ${number}.` })
      else notification.success({ message: `Sucesso ao cancelar solicitação de número ${number}.` })
    })

    setIsDeletingSelectedSolicitations(false)
  }

  const openEditSolicitationModal = solicitation => {
    setEditSolicitation({
      id: solicitation.id,
      number: solicitation.number,
      originLocalityId: solicitation.origin_locality.id,
      destinationLocalityId: solicitation.destination_locality.id,
    });
    setShowEditSolicitationModal(true);
  }

  const columns = [
    {
      title: 'Número',
      dataIndex: 'number',
      width: 100,
      render: (text, { recurrent_solicitation_id }) => (
        <span>
          {recurrent_solicitation_id && (
            <Tooltip title="Esta solicitação pertence a um registro de recorrência">
              <FaClock style={{ color: 'gray', marginBottom: '-0.1rem' }} />{' '}
            </Tooltip>
          )}
          {text}
        </span>
      ),
    },
    {
      title: 'Data da viagem',
      render: (text, { trip_date, time }) => `${moment(trip_date).format('DD/MM/YY')} às ${time}`,
    },
    {
      title: 'Passageiro ou material',
      render: (solicitation, { passenger_name, entity_name }) => passenger_name || entity_name,
    },
    {
      title: 'Origem',
      render: (text, { origin_locality }) => origin_locality?.name || '--',
    },
    {
      title: 'Destino',
      render: (text, { destination_locality }) => destination_locality?.name || '--',
    },
    {
      title: 'Matrícula',
      dataIndex: 'passenger.registration',
      render: (text, solicitation) => {
        const registration = solicitation?.passenger?.registration || 'Visitante';

        return !!solicitation?.entity_name ? 'Sem matrícula' : registration;
      },
    },
    {
      title: 'Ações',
      render: (text, record) => {
        const { number } = record;
        const editText = `Editar solicitação: ${number}`;
        const cancelSolicitationText = `Cancelar solicitação: ${number}`

        return (
            <>
              <Tooltip placement="top" title={editText}>
                <Button type="text"
                        className={styles.editButton}
                        aria-label={editText}
                        onClick={() => openEditSolicitationModal(record)}>
                  <FaEdit className={styles.editIcon} aria-hidden={true} />
                </Button>
              </Tooltip>

              {isOverOrEqualThanOneHour(record.trip_date, record.time) && <Popconfirm
                  title="Você tem certeza que deseja cancelar esta solicitação?"
                  okText="Sim"
                  cancelText="Não"
                  onConfirm={() => exclude_solicitation(record.id)}
              >
                <Tooltip placement={'top'} title={cancelSolicitationText}>
                  <Button type={'text'}
                          className={styles.actionButton}
                          aria-label={cancelSolicitationText}>
                    <FaBan className={styles.actionIcon} aria-hidden={true}/>
                  </Button>
                </Tooltip>
              </Popconfirm>}
            </>
        )
      },
    },
  ];

  const deleteSelectedSolicitationsButton = useMemo(() => {
    const size = selectedSolicitationsToCancel?.length;

    if(!size) return null;

    return (
      <Popconfirm
          id={deleteSelectedSolicitationsPopupId}
          title="Você tem certeza que deseja cancelar as solicitações selecionadas?"
          okText="Sim"
          cancelText="Não"
          onConfirm={() => deleteSelectedSolicitations()}
          disabled={isDeletingSelectedSolicitations}
      >
        <Button type="primary"
                onClick={() => focus(`#${deleteSelectedSolicitationsPopupId} .ant-btn.ant-btn-primary.ant-btn-sm`, 200)}
                className={styles.button}
                htmlType={'button'}
                loading={isDeletingSelectedSolicitations}
                disabled={isDeletingSelectedSolicitations}>
          {isDeletingSelectedSolicitations ? null : <FaBan aria-hidden={true}/>} Cancelar solicitações selecionadas ({size})
        </Button>
      </Popconfirm>
    )
  }, [isDeletingSelectedSolicitations, selectedSolicitationsToCancel])

  const excelSolicitations = generateExcelSolicitations(solicitations);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelected(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    if(fetching) return;

    setSelected(selectedSolicitations => {
      const nextSelectedSolicitations = selectedSolicitations.filter(selectedSolicitation => solicitations.some(({ id }) => selectedSolicitation.id === id));

      setSelectedRowKeys(nextSelectedSolicitations.map(({ id }) => id));

      return nextSelectedSolicitations
    });
  }, [fetching, solicitations])

  const solicitationsAmount = (data) => {
    if (data === undefined) return 'Nenhuma solicitação';

    if (data.length === 0) return 'Nenhuma solicitação';

    if (data.length === 1) return '1 solicitação';

    if (data.length > 1) return `${data.length} solicitações`;

    return 'Nenhuma solicitação';
  };

  return (
    <>
      <div>
        <p className={styles.instruction}>Selecione as solicitações que você gostaria de roteirizar para enviar às cooperativas</p>

        <div className={styles.fieldsContainer}>
          <RangePicker className={styles.rangePicker}
                       defaultValue={[moment(), moment().add(3, 'days')]}
                       allowClear={false}
                       value={rangeDate}
                       onChange={(values) => setRangeDate(values)}
                       format="DD/MM/YYYY" />

          <div className={styles.timePicker}>
            <TimePicker name="time" label="Horário" setFieldValue={formik.setFieldValue} value={time} />
          </div>

          <div className={styles.passengerSelect}>
            <PassengerSelect name={'passenger'} value={passenger} setFieldValue={formik.setFieldValue} />
          </div>
        </div>

        <Amount>{solicitationsAmount(solicitations)}</Amount>

        {error && <p>Erro listar solicitações. Contate o suporte</p>}

        <div className={styles.actions}>
          <Refresh onClick={() => refresh()}>
            <FaRedoAlt /> Atualizar
          </Refresh>

          {deleteSelectedSolicitationsButton}

          <p>Você só pode cancelar solicitações que tem até 1 hora de antecedência. Se tiver menos, então ela será debitada do seu centro de custo.</p>
        </div>

        <div className={styles.exportContainer}>
          <ExcelFile
            fileExtension="xlsx"
            filename={`Solicitações em andamento`}
            element={
              <Tooltip title="O arquivo exportado conterá as solicitações da tabela abaixo">
                <button className={styles.exportButton}>
                  <FaFileExcel style={{ marginRight: '0.25rem' }} />
                  Exportar
                </button>
              </Tooltip>
            }
          >
            <ExcelSheet data={excelSolicitations} name="Solicitações em andamento">
              <ExcelColumn label="Número da Solicitação" value="solicitation_number" />
              <ExcelColumn label="Nome do solicitante" value="solicitant_name" />
              <ExcelColumn label="Matrícula do solicitante" value="solicitant_registration" />
              <ExcelColumn label="Nome do passageiro" value="passenger_name" />
              <ExcelColumn label="Matrícula do passageiro" value="passenger_registration" />
              <ExcelColumn label="Nome do material" value="entity_name" />
              <ExcelColumn label="Nº do Centro de Custo" value="cost_center_number" />
              <ExcelColumn label="Nome do Centro de Custo" value="cost_center_description" />
              <ExcelColumn label="Data da viagem" value="trip_date" />
              <ExcelColumn label="Horário da viagem" value="trip_time" />
              <ExcelColumn label="Origem" value="origin" />
              <ExcelColumn label="Destino" value="destination" />
              <ExcelColumn label="Localidade de Origem" value="origin_locality" />
              <ExcelColumn label="Localidade de Destino" value="destination_locality" />
              <ExcelColumn label="Telefone" value="phone" />
              <ExcelColumn label="Motivo" value="reason" />
              <ExcelColumn label="Observações" value="observation" />
            </ExcelSheet>
          </ExcelFile>
        </div>
        {cooperative ? (
          <Table
            rowKey="id"
            loading={fetching}
            pagination={false}
            scroll={{ y: 400 }}
            columns={columns}
            rowSelection={rowSelection}
            dataSource={solicitations}
            expandedRowRender={(record) => <ExpandedRow solicitation={record} />}
          />
        ) : (
          <Table
            rowKey="id"
            loading={fetching}
            pagination={false}
            columns={columns}
            scroll={{ y: 400 }}
            dataSource={solicitations}
            expandedRowRender={(record) => <ExpandedRow solicitation={record} />}
          />
        )}
        <div className={styles.buttonContainer}>
          {cooperative && (
            <button
              className={styles.primaryButton}
              onClick={async () => {
                setModal(true);
                setLoadingRouter(true);
                const trips = await routerize({ limit_passengers: limitPassengers || 4, solicitations: selected });
                console.log(trips);
                setTrips(trips);
                setLoadingRouter(false);
              }}
            >
              Roteirizar
            </button>
          )}
        </div>
      </div>
      {<GroupSolicitations visible={modal} setVisible={setModal} refreshSolicitations={refresh} />}
    </>
  );
}
