export const colors = [
  '#FFA500',
  '#5A8BA8',
  '#AAAAAA',
  '#7C6767',
  '#34700a',
  '#B8336A',
  '#7B7554',
  '#5949EE',
  '#C6464D',
  '#F29CC2',
  '#E9A8DE',
  '#E54848',
  '#C92E9C',
  '#5ED4CE',
  '#2E89CB',
  '#3080C7',
  '#9C1E3D',
  '#1FE708',
  '#128AF4',
  '#2EFF36',
  '#53B9C1',
  '#553DFC',
  '#413F65',
  '#B5C543',
  '#B2FD0A',
  '#C3F8E6',
  '#ED43DB',
  '#03B2FB',
  '#2A56DE',
  '#BE5914',
  '#DF2F25',
  '#BE9FA0',
  '#36C660',
  '#7360EA',
  '#5B63CE',
  '#016EAF',
  '#0982B0',
  '#00EBBE',
  '#A5DAC6',
  '#62D50F',
  '#436FD6',
  '#F1DD3A',
  '#1F1D13',
  '#E0177C',
  '#5204B0',
  '#D83325',
  '#60C5B5',
  '#434828',
  '#91FE6B',
  '#907A01',
  '#815999',
  '#7F74E9',
  '#AB13E8',
  '#256A0A',
  '#F573D8',
  '#1C743A',
  '#286C60',
  '#8EB013',
  '#78A9AF',
  '#D6FB4B',
  '#3334D7',
  '#11CCDA',
  '#D4FC51',
  '#B384FC',
  '#250198',
  '#513148',
  '#BA8676',
  '#92DA9E',
  '#396209',
  '#9CD1DD',
  '#EC6791',
  '#565E17',
  '#1A37A1',
  '#BC1E2A',
  '#AE36A8',
  '#2361E2',
  '#2F586A',
  '#C48CA1',
  '#D57D8D',
  '#4B4C7D',
  '#B6472C',
  '#29DC5A',
  '#B5E0C7',
  '#CEF761',
  '#F5A896',
  '#5F7A36',
  '#D899C0',
  '#9DDDC8',
  '#8D591F',
  '#C60A61',
  '#529310',
  '#25E80A',
  '#FDF5A5',
  '#2A00B1',
  '#52B28A',
  '#9A3957',
  '#308DBA',
  '#2849D1',
  '#37BDB5',
  '#C00EBC',
  '#5A3B27',
  '#7E0D4E',
  '#C8F725',
  '#187264',
  '#532C5A',
  '#99C08E',
  '#40799E',
  '#6B3169',
  '#8F5C13',
  '#866951',
  '#354E37',
  '#3892A2',
  '#FC07FC',
  '#0EE038',
  '#B11E99',
  '#B7B7F6',
  '#2A174C',
  '#8978CB',
  '#8033ED',
  '#D57EB3',
  '#7DE2FD',
  '#E17AE5',
  '#AA9D37',
  '#9E42B2',
  '#DBC6D0',
  '#415AD4',
  '#600CE7',
  '#EDD9BD',
  '#5904B5',
  '#3C6CF4',
  '#002E6A',
  '#A42F6F',
  '#22954C',
  '#6C2F71',
  '#90C81C',
  '#70F003',
  '#E8A59C',
  '#41181D',
  '#0335E3',
  '#DEC7A0',
  '#20F656',
  '#F294B4',
  '#ED7D34',
  '#BE94F7',
  '#702AA2',
  '#BCD7F0',
  '#B232F7',
  '#D4FC86',
  '#9F64F0',
  '#DFBCA8',
  '#A08D11',
  '#CA43C8',
  '#918CCE',
  '#22F56B',
  '#8C8328',
  '#434A2F',
  '#92A425',
  '#ECB9F5',
  '#943440',
  '#894C06',
  '#24DD53',
  '#63F9FD',
  '#C05B0D',
  '#8D9E6C',
  '#4E63FE',
  '#186290',
  '#5DC1D7',
  '#1EA95E',
  '#1DD9E7',
];
