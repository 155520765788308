import styled from 'styled-components'

export const StyledBackdrop = styled.div`;
    display: ${({ show }) => show ? 'flex' : 'none'};
    flex-direction: column;
    row-gap: var(--small-space);
    align-items: center;
    justify-content: center;
    text-align: center;
`

export const StyledStrong = styled.strong`
    font-size: 32px;
    font-weight: 600;
`

export const StyledText = styled.p`
    font-size: 24px;
    font-weight: 400;
`
