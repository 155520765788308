import { Modal, Select } from 'antd';
import { intersectionBy } from 'lodash';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { CooperativesContext } from '../../../../contexts/CooperativesContext';
import { useDashboardContext } from '../../contexts/DashboardContext';

const { Option } = Select;

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
`;

const Instruction = styled.h4`
  text-align: center;
  color: gray;
  margin-bottom: 1rem;
`;

const FieldContainer = styled.div`
  margin-top: 1rem;
  width: 285px;
`;

const Label = styled.label`
  font-weight: bold;
  text-align: left;
`;

const CustomSelect = styled(Select)`
  margin-top: 0.5rem;
  display: block;
`;

const ModalFilters = (props: Props) => {
  const { cooperatives } = useContext(CooperativesContext);

  const { costCentersList, setSelectedCooperative, setSelectedCostCenters } = useDashboardContext();

  return (
    <Modal
      visible={props.visible}
      title="Aplicar filtros"
      footer={null}
      onCancel={() => {
        props.setVisible(false);
      }}
    >
      <Container>
        <Instruction>Selecione nos campos abaixo os filtros que deseja aplicar no Dashboard</Instruction>
        <FieldContainer>
          <Label>Cooperativas</Label>
          <CustomSelect
            defaultValue="all"
            onChange={(value, option) => {
              if (value === 'all') {
                setSelectedCooperative(null);
                return;
              }

              //@ts-ignore
              setSelectedCooperative(option.cooperative);
            }}
            placeholder="Selecionar cooperativa"
          >
            <Option key="all" value="all">
              Todas
            </Option>
            {cooperatives.map((cooperative: any) => (
              <Option key={cooperative.id} value={cooperative.id} cooperative={cooperative}>
                {cooperative.name}
              </Option>
            ))}
          </CustomSelect>
        </FieldContainer>
        <FieldContainer>
          <Label>Centros de Custo</Label>
          <CustomSelect
            defaultValue={'Todos'}
            onChange={(values: any) => {
              console.log(values);
              const hasAll = !!values.find((value: any) => value === 'Todos');

              if (hasAll) {
                setSelectedCostCenters(costCentersList);
              } else {
                const valuesId = values.map((id: string) => ({ id }));
                const filteredCostCenters = intersectionBy(costCentersList, valuesId, 'id');

                setSelectedCostCenters(filteredCostCenters);
              }
            }}
            placeholder="Selecionar centros de custo"
            mode="multiple"
            maxTagCount={1}
            maxTagTextLength={8}
          >
            <>
              <Option value={'Todos'}>Todos</Option>
              {costCentersList.map((cost_center: any, index: number) => (
                <Option key={index} value={cost_center?.id}>
                  {cost_center?.number} - {cost_center?.description}
                </Option>
              ))}
            </>
          </CustomSelect>
        </FieldContainer>
      </Container>
    </Modal>
  );
};

export default ModalFilters;
