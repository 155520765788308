import { Select, Empty } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FaDollarSign } from 'react-icons/fa';
import { useDashboardContext } from '../../contexts/DashboardContext';
import { usePassengers } from './usePassengers';
import { groupBy } from 'lodash';

const { Option } = Select;

const Container = styled.div`
  padding: 1rem;
`;

const SearchTitle = styled.h3`
  margin: 0;
  font-weight: bold;
`;

const Label = styled.label`
  display: inline-block;
  font-weight: bold;
  font-size: 12px;
`;

const PassengerInfo = styled.p`
  margin-top: 0.1rem;
  font-size: 14px;
  font-weight: 400;
`;

const DetailContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: start;
`;

const InfoContainer = styled.div`
  margin-right: 1.5rem;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(FaDollarSign)`
  color: gray;
`;

const DataLabel = styled.p`
  color: gray;
  font-size: 12px;
`;

const DataValue = styled.p`
  font-weight: bold;
  font-size: 16px;
`;

interface Props {}

interface IPassenger {
  name: string;
  registration: string;
  [key: string]: any;
}

const SearchPassenger = (props: Props) => {
  const { selectedCostCenters, formatTrips } = useDashboardContext();

  const [selectedPassenger, setSelectedPassenger] = useState<IPassenger | null>(null);

  const passengers = usePassengers(selectedCostCenters) ?? [];

  const passengerSolicitations =
    groupBy(
      formatTrips.filter((solicitation) => solicitation.passenger_name === selectedPassenger?.name),
      'passenger_name',
    )[selectedPassenger?.name ?? 0] ?? [];

  const totalCost = passengerSolicitations.reduce((sum, solicitation) => sum + solicitation.cost, 0);

  const lastSolicitationDate = passengerSolicitations[passengerSolicitations.length - 1]?.trip_date ?? '--/--/----';

  return (
    <Container>
      <SearchTitle>Busca por colaborador</SearchTitle>
      <Select
        style={{ width: '100%', margin: '1rem 0' }}
        size="large"
        placeholder="Pesquise por nome ou matrícula"
        value={selectedPassenger?.name}
        suffixIcon={<SearchOutlined type="search" />}
        showSearch
        onChange={(_, option) => {
          // @ts-ignore
          setSelectedPassenger(option.passenger);
        }}
      >
        {passengers.map((p: any) => (
          <Option key={p.id} value={`${p.name} ${p.registration}`} passenger={p}>
            {p.name} - <span style={{ fontWeight: 'bold' }}>Matrícula: </span>
            {p.registration}
          </Option>
        ))}
      </Select>
      {selectedPassenger ? (
        <>
          <Label>Nome</Label>
          <PassengerInfo>{selectedPassenger.name}</PassengerInfo>
          <DetailContainer>
            <InfoContainer>
              <Label>Matrícula</Label>
              <PassengerInfo>{selectedPassenger.registration}</PassengerInfo>
            </InfoContainer>
            <InfoContainer>
              <Label>Centro de Custo</Label>
              <PassengerInfo>
                {selectedPassenger.cost_center.number} - {selectedPassenger.cost_center.description}
              </PassengerInfo>
            </InfoContainer>
          </DetailContainer>
          <DetailContainer>
            <InfoContainer>
              <LabelContainer>
                <Icon />
                <DataLabel>Custo total</DataLabel>
              </LabelContainer>
              <DataValue>R$ {totalCost.toFixed(2)}</DataValue>
            </InfoContainer>
            <InfoContainer>
              <LabelContainer>
                <DataLabel>Solicitações</DataLabel>
              </LabelContainer>
              <DataValue>{passengerSolicitations.length}</DataValue>
            </InfoContainer>
            <InfoContainer>
              <LabelContainer>
                <DataLabel>Última solicitação</DataLabel>
              </LabelContainer>
              <DataValue>{lastSolicitationDate}</DataValue>
            </InfoContainer>
          </DetailContainer>
        </>
      ) : (
        <Empty description="Pesquise por um passageiro" />
      )}
    </Container>
  );
};

export default SearchPassenger;
