export const removeHigherCost = (costs) => {
  const newCosts = [...costs]

  if (newCosts.length === 1) return newCosts;

  const maxCost = Math.max(...costs);

  newCosts.splice(newCosts.indexOf(maxCost), 1)

  return newCosts;
};
