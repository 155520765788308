import React, { ReactElement } from 'react';
import DashboardProvider from './contexts/DashboardContext';
import Home from './Home';

export default function HomeWrapper(): ReactElement {
  return (
    <DashboardProvider>
      <Home />
    </DashboardProvider>
  );
}
