import { ConfigProvider } from 'antd';
import pt_BR from 'antd/es/locale/pt_BR';
import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Routes from './components/AuthenticatedTemplate/Routes';
import ConfigurationProvider from './contexts/ConfigurationContext';
import CooperativesProvider from './contexts/CooperativesContext';
import CostCenterProvider from './contexts/CostCenterContext';
import LocalitiesProvider from './contexts/LocalitiesContext';
import ReasonsProvider from './contexts/ReasonsContext';
import RoutesProvider from './contexts/RoutesContext';
import TimesProvider from './contexts/TimesContext';
import UserProvider from './contexts/UserContext';
import './index.css'

function App() {
  return (
    <div>
      <UserProvider>
        <TimesProvider>
          <ReasonsProvider>
            <ConfigurationProvider>
              <RoutesProvider>
                <LocalitiesProvider>
                  <CostCenterProvider>
                    <CooperativesProvider>
                      <ConfigProvider locale={pt_BR}>
                        <Router>
                          <Switch>
                            <Routes />
                          </Switch>
                        </Router>
                      </ConfigProvider>
                    </CooperativesProvider>
                  </CostCenterProvider>
                </LocalitiesProvider>
              </RoutesProvider>
            </ConfigurationProvider>
          </ReasonsProvider>
        </TimesProvider>
      </UserProvider>
    </div>
  );
}

export default App;
