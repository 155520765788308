import React, { useMemo } from 'react';
import { useDashboardContext } from '../../../contexts/DashboardContext';
import Card from '../Card';

interface Props {}

const MeanPassengersCard = (props: Props) => {
  const { formatTrips, trips } = useDashboardContext();

  const solicitationsAmount = useMemo(() => {
    return formatTrips.length;
  }, [formatTrips]);

  const tripsAmount = trips.length === 0 ? 1 : trips.length;

  const mean = solicitationsAmount / tripsAmount;

  return <Card title="Méd. de Passageiros" value={mean.toFixed(1)} />;
};

export default MeanPassengersCard;
