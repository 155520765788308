import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import styles from './DatePicker.module.css';

export default function DatePickerComponent(props) {
  const { user } = useContext(UserContext);

  const disabledDate = (current) => {
    if (!user?.user?.user_permission?.is_admin) {
      return (current && current < moment().endOf('day').subtract(1, 'day')) || current > moment().endOf('day').add(30, 'day');
    }

    return null;
  };

  const onChange = (date) => {
    const { name, setFieldValue } = props;

    setFieldValue(name, date.utc(-3));
    setFieldValue('return_date', date.utc(-3));
  };

  return (
    <>
      <label className={styles.label}>{props.label} *</label>
      <div className={styles.inputContainer}>
        <DatePicker {...props} onChange={onChange} className={styles.dateInput} disabledDate={disabledDate} format="DD[/]MM[/]YY" allowClear={false} />
      </div>
    </>
  );
}
